import React from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";

import { Autocomplete } from "@material-ui/lab";
import { defaultTo } from "lodash";
import { connect } from "react-redux";
import { getRequest, postRequest } from "../../../logic/requests";
import { URLs } from "../../../logic/constants";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { alerts, useAlert } from "../../../context/AlertContext";
import { setTeamMembers } from "../../../redux/actions";
import PermissionsCrossTable from "./PermissionsCrossTable";
import TournamentMemberList from "./TournamentMemberList";
import { resizeImage } from "../../../logic/utils";

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box p={3}>
        <Typography>{children}</Typography>
      </Box>
    )}
  </div>
);

const pageActionBasedOnIndex = (index) => {
  switch (index) {
    case 0:
      return "Send Invitation";
    case 1:
      return "Move Tournament";
    case 2:
      return "Update";
    case 3:
      return "Update";
    default:
      break;
  }
};

const numToString = (num) => {
  const numBinaryString = num.toString(2);
  return numBinaryString.padStart(12, "0");
};

const statePermPrepare = (permissions) => {
  console.log(permissions);
  const perms = numToString(permissions);
  console.log(perms);
  const preState = {
    group: {
      create: perms[8] === "1" ? true : false,
      read: perms[9] === "1" ? true : false,
      update: perms[10] === "1" ? true : false,
      delete: perms[11] === "1" ? true : false,
    },
    tournament: {
      create: perms[4] === "1" ? true : false,
      read: perms[5] === "1" ? true : false,
      update: perms[6] === "1" ? true : false,
      delete: perms[7] === "1" ? true : false,
    },
    match: {
      create: perms[0] === "1" ? true : false,
      read: perms[1] === "1" ? true : false,
      update: perms[1] === "1" ? true : false,
      delete: perms[2] === "1" ? true : false,
    },
  };

  console.log("sarp", preState);

  return preState;
};

const permissionsStateToInteger = (permissions) => {
  let result = 0b000000000000;
  if (permissions.match.create) result = result | 0b000100000000;
  if (permissions.match.read) result = result | 0b001000000000;
  if (permissions.match.update) result = result | 0b010000000000;
  if (permissions.match.delete) result = result | 0b100000000000;
  if (permissions.tournament.create) result = result | 0b000000010000;
  if (permissions.tournament.read) result = result | 0b000000100000;
  if (permissions.tournament.update) result = result | 0b000001000000;
  if (permissions.tournament.delete) result = result | 0b000010000000;
  if (permissions.group.create) result = result | 0b000000000001;
  if (permissions.group.read) result = result | 0b000000000010;
  if (permissions.group.update) result = result | 0b000000000100;
  if (permissions.group.delete) result = result | 0b000000001000;

  console.log(result);

  return result;
};

const ModalManageGroup = ({ id, open, setOpen, group }) => {
  const showAlert = useAlert();
  const [value, setValue] = React.useState(0);
  const [inviteCompleteList, setInviteCompleteList] = React.useState([]);
  const [groupMembersList, setGroupMembersList] = React.useState([]);
  const [selectedTournamentForAdd, setSelectedTournamentForAdd] =
    React.useState("");
  const [selectedMember, setSelectedMember] = React.useState({});
  const [selectedForInvitation, setSelectedForInvitation] = React.useState({});
  const [nameValue, set_nameValue] = React.useState("");
  const [uploadedFileName, set_uploadedFileName] = React.useState("");
  const [avatarUri, set_avatarUri] = React.useState("");
  const [movableTournaments, setMovableTournaments] = React.useState([]);
  const [movableTournamentsAutoCorrect, setMovableTournamentsAutoCorrect] =
    React.useState([]);
  const [permissionsForInvitation, setPermissionsForInvitation] =
    React.useState({
      match: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      tournament: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      group: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
    });
  const [permissions, setPermissions] = React.useState({
    match: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    tournament: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    group: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
  });

  // UPLOAD BUTTON
  const useUploadButtonStyles = makeStyles((theme) => ({
    root: {
      textAlign: "center",
      "& > *": {
        margin: theme.spacing(1, 0),
      },
    },
    input: {
      display: "none",
    },
    upload: {
      textAlign: "center",
    },
    previewText: {
      display: "inline-block",
      margin: theme.spacing(1),
    },
    previewImage: {
      maxHeight: "64px",
      maxWidth: "64px",
    },
  }));
  const classes = useUploadButtonStyles();

  const UploadButton = () => {
    const handleOnClick = (event) => {
      const file = event.target.files[0];

      if (file) {
        resizeImage({
          file: file,
          maxSize: 48,
        })
          .then((resizedImage) => {
            console.log("Resized image: ", resizedImage);
            set_uploadedFileName(file.name);
            set_avatarUri(resizedImage);
          })
          .catch((err) => {
            showAlert(err.data.error, alerts.error.severity);
            console.error(err);
          });
      }
    };
    return (
      <div className={classes.root}>
        <input
          accept="image/*"
          className={classes.input}
          id="ModalCreateTeam-contained-button-file"
          multiple
          type="file"
          onChange={(e) => handleOnClick(e)}
        />
        <label htmlFor="ModalCreateTeam-contained-button-file">
          <Button
            variant="contained"
            color="primary"
            component="span"
            startIcon={<CloudUploadIcon />}
          >
            Upload Avatar
          </Button>
        </label>
      </div>
    );
  };

  const buttonActionBasedOnIndex = (index) => {
    switch (index) {
      case 0:
        return handlers.sendInvitation;
      case 1:
        return () =>
          handlers.addTournamentToGroup(selectedTournamentForAdd, group.id);
      case 2:
        return handlers.updatePermissions;
      case 3:
        return handlers.updateGroupInfo;
      default:
        break;
    }
  };

  React.useEffect(() => {
    const handler = async () => {
      if (group.name !== "") {
        try {
          const movableTournaments = await getRequest(
            URLs.api.group.movableTournaments,
            { groupId: group.id },
          );
          setMovableTournaments(movableTournaments.data);
          setMovableTournamentsAutoCorrect(movableTournaments.data);
          const membersOfGroup = await getRequest(URLs.api.group.getMembersOf, {
            groupId: group.id,
          });
          const data = [];
          for (let i = 0; i < membersOfGroup.data.length; i++)
            for (let j = 0; j < group.userList.length; j++)
              if (membersOfGroup.data[i]._id == group.userList[j].userId) {
                data.push({
                  userId: group.userList[j].userId,
                  username: membersOfGroup.data[i].username,
                  permission: group.userList[j].permission,
                });
                break;
              }
          console.log(data);
          setGroupMembersList(data);
        } catch (error) {
          console.log(error);
        }
      }
    };
    handler();
  }, [group]);

  const handlers = {
    addTournamentToGroup: (tournamentId, groupId) => {
      postRequest(URLs.api.group.move, { tournamentId, groupId }).then((res) =>
        console.log("Succ"),
      );
    },
    updateGroupInfo: () => {
      postRequest(URLs.api.group.update, {
        groupId: group.id,
        groupAvatar: avatarUri,
        groupName: nameValue,
      }).then((res) => {
        handlers.close();
      });
    },
    deleteGroup: () => {
      postRequest(URLs.api.group.delete, {
        groupId: group.id,
      }).then((res) => {
        handlers.close();
      });
    },
    removeMember: () => {
      postRequest(URLs.api.group.kickMember, {
        userId: selectedMember.userId,
        groupId: group.id,
      }).then((res) => {
        setSelectedMember({});
        setPermissions(statePermPrepare(0));
        handlers.close();
      });
    },
    changeTabs: (event, tab) => setValue(tab),
    clickOpen: () => setOpen(true),
    close: () => setOpen(false),
    invitationAutocompleteHandler: (value, reason) => {
      if (reason === "input")
        getRequest(URLs.api.user.search, { username: value }).then((res) =>
          setInviteCompleteList(res.data),
        );
      else setSelectedForInvitation(value);
    },
    memberAutocompleteHandler: (value, reason) => {
      if (reason === "select-option") {
        setSelectedMember(value);
        console.log(value);
        setPermissions(statePermPrepare(value.permission));
      }
    },

    updatePermissions: () => {
      const params = {
        userId: selectedMember.userId,
        groupId: group.id,
        permissions: permissionsStateToInteger(permissions),
      };

      postRequest(URLs.api.group.changePerm, params).then((res) =>
        console.log("success"),
      );
    },

    sendInvitation: () => {
      const params = {
        groupId: group.id,
        userId: selectedForInvitation._id,
        permissions: permissionsStateToInteger(permissionsForInvitation),
      };
      postRequest(URLs.api.group.invite, params)
        .then((res) => {
          if (res.status === 200)
            showAlert(alerts.success.message, alerts.success.severity);
          else console.error(res);
        })
        .catch((err) => {
          switch (err.status) {
            case 408:
              showAlert(
                "This user has been already invited!",
                alerts.warning.severity,
              );
              break;
            default:
              showAlert(err.data.error, alerts.error.severity);
          }
        });
    },
  };

  return (
    <>
      <Dialog
        fullWidth
        open={open}
        onClose={handlers.close}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {defaultTo(group.groupName, "Group")} Management
        </DialogTitle>

        <DialogContent>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            scrollButtons="on"
            variant="scrollable"
            onChange={handlers.changeTabs}
            aria-label="disabled tabs example"
          >
            <Tab label="Invite" />
            <Tab label="Tournaments" />
            <Tab label="Moderate" />
            <Tab label="Edit" />
          </Tabs>

          <TabPanel value={value} index={0}>
            <Autocomplete
              id="combo-box-demo"
              options={inviteCompleteList}
              getOptionLabel={(option) => option.username}
              onChange={(a, b, c) =>
                handlers.invitationAutocompleteHandler(b, c)
              }
              onInputChange={(a, text, reason) =>
                text.length > 1
                  ? handlers.invitationAutocompleteHandler(text, reason)
                  : null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Invite User to Group"
                  variant="outlined"
                />
              )}
            />
            <br />
            <PermissionsCrossTable
              permissions={permissionsForInvitation}
              setState={setPermissionsForInvitation}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Autocomplete
              id="combo-box-demo"
              closeIcon={null}
              options={movableTournamentsAutoCorrect}
              getOptionLabel={(option) => option.name}
              // onChange={(a, b, c) =>
              //   handlers.getMovableTournaments(b, c)
              // }
              onInputChange={(a, text, reason) => {
                let res = [];
                movableTournaments.forEach((tournament) => {
                  if (tournament.name.indexOf(text) !== -1)
                    res.push(tournament);
                });
                setMovableTournamentsAutoCorrect(res);
                console.log(movableTournamentsAutoCorrect);
                if (reason === "reset")
                  setSelectedTournamentForAdd(
                    movableTournamentsAutoCorrect.find(
                      (element) => element.name === text,
                    )._id,
                  );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Add Tournament To Group"
                  variant="outlined"
                />
              )}
            />
            <TournamentMemberList team={group} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Autocomplete
              id="combo-box-demo"
              closeIcon={null}
              options={groupMembersList}
              getOptionLabel={(option) => option.username}
              onChange={(a, b, c) => handlers.memberAutocompleteHandler(b, c)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Member"
                  variant="outlined"
                />
              )}
            />

            <br />
            {selectedMember.userId && (
              <PermissionsCrossTable
                permissions={permissions}
                setState={setPermissions}
              />
            )}
            {selectedMember.userId && selectedMember.userId !== id && (
              <Button onClick={handlers.removeMember} color="secondary">
                Remove User?
              </Button>
            )}
          </TabPanel>

          <TabPanel value={value} index={3}>
            <Grid
              container
              spacing={2}
              alignItems="flex-end"
              // className={classes.groupNameContainer}
            >
              <Grid item>
                <Avatar
                  variant="rounded"
                  // className={classes.previewImage}
                  src={avatarUri}
                >
                  {nameValue[0] ? nameValue[0].toUpperCase() : " "}
                </Avatar>
              </Grid>
              <Grid item xs>
                <TextField
                  required
                  fullWidth
                  id="name"
                  label="Group Name"
                  type="text"
                  onChange={(a) => set_nameValue(a.nativeEvent.target.value)}
                />
              </Grid>
              <Grid item />
            </Grid>
            <br />
            <div className={classes.upload}>
              <UploadButton />
              <p className={classes.previewText}>{uploadedFileName}</p>
            </div>
            <Button onClick={handlers.deleteGroup} color="secondary">
              Delete Group?
            </Button>
          </TabPanel>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlers.close} color="primary">
            Cancel
          </Button>
          <Button
            onClick={buttonActionBasedOnIndex(value)}
            color="primary"
            variant="contained"
          >
            {pageActionBasedOnIndex(value)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = (store) => ({
  id: store.profile._id,
  groupTableRows: store.groups.groups,
});

const mapDispatchToProps = {
  redux_setMembers: setTeamMembers,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalManageGroup);
