import * as PAGES from "./index";

export { default as HIDDEN_ROUTER } from "./HIDDEN_ROUTER";
export { default as HOMEPAGE } from "./HOMEPAGE";
export { default as THEME_PAGE } from "./pages/THEME_PAGE";
export { default as USER_SEARCH } from "./pages/USER_SEARCH";
export { default as LANGUAGE_SELECTOR } from "./pages/LANGUAGE_SELECTOR";
export { default as LIST_KEY_TEST } from "./pages/LIST_KEY_TEST";

export default PAGES;
