const redirectSwitch = ({ type, name, id }) => {
  switch (type) {
    case "twitch":
      return "https://twitch.tv/" + name;
    case "youtube":
      return "https://youtube.com/user/" + name;
    case "battlenet":
      return "https://battle.net";
    case "steam":
      return "https://steamcommunity.com/profiles/" + id;
    case "reddit":
      return "https://reddit.com/user/" + name;
    case "facebook":
      return "https://facebook.com/" + name;
    case "twitter":
      return "https://twitter.com/" + name;
    case "spotify":
      return "https://open.spotify.com/user/" + id;
    case "xbox":
      return "https://www.xbox.com/";
    case "github":
      return "https://github.com/" + name;
    default:
      return "";
  }
};

export default redirectSwitch;
