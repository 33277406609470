import { Paper, withStyles } from "@material-ui/core";

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
    margin: theme.spacing(1, 0),
  },
});

const PAPER = withStyles(styles)(Paper);

export default PAPER;
