import React from "react";
import { connect } from "react-redux";

import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import ContentContainer from "./ContentContainer";
import { useSidebarIndex } from "../../context/SidebarIndexContext";
import { Hidden, useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    //background: "gray",
    height: "100vh",
    margin: 0,
  },
  mobile: {
    display: "block",
  },
  contentSpacing: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: 15,
  },
}));

const PageBase = ({ children, userId_redux }) => {
  const classes = useStyles();

  const [sidebarIndex] = useSidebarIndex();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  /*
  const childrenWithProps = React.Children.map(children, (child) => {
    // checking isValidElement is the safe way and avoids a typescript error too
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { showAlert: showAlert });
    }
    return child;
  });
*/

  return (
    <div
      className={clsx(classes.root, {
        [classes.mobile]: isMobile,
      })}
    >
      <Navbar />

      {userId_redux && (
        <Hidden xsDown>
          <Sidebar selectedIndex={sidebarIndex} />
        </Hidden>
      )}

      <ContentContainer>{children}</ContentContainer>
    </div>
  );
};

const mapDispatchToProps = {
  //
};

const mapStateToProps = (store) => ({
  userId_redux: store.profile._id,
});

export default connect(mapStateToProps, mapDispatchToProps)(PageBase);
