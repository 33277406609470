import {
  FaBattleNet,
  FaFacebook,
  FaGithub,
  FaReddit,
  FaSpotify,
  FaSteam,
  FaTwitch,
  FaTwitter,
  FaXbox,
  FaYoutube,
} from "react-icons/fa";

const RenderIcon = (param) => {
  const iconStyles = { padding: "2px" }; // if needed in future
  switch (param) {
    case "twitch":
      return <FaTwitch size={"25px"} color={"#6441a5"} style={iconStyles} />;
    case "youtube":
      return <FaYoutube size={"25px"} color={"#FF0000"} style={iconStyles} />;
    case "battlenet":
      return <FaBattleNet size={"25px"} color={"#0566b0"} style={iconStyles} />;
    case "steam":
      return <FaSteam size={"25px"} color={"#171a21"} style={iconStyles} />;
    case "reddit":
      return <FaReddit size={"25px"} color={"#FF5700"} style={iconStyles} />;
    case "facebook":
      return <FaFacebook size={"25px"} color={"#4267B2"} style={iconStyles} />;
    case "twitter":
      return <FaTwitter size={"25px"} color={"#1DA1F2"} style={iconStyles} />;
    case "spotify":
      return <FaSpotify size={"25px"} color={"#1DB954"} style={iconStyles} />;
    case "xbox":
      return <FaXbox size={"25px"} color={"#107C10"} style={iconStyles} />;
    case "github":
      return <FaGithub size={"25px"} color={"#24292e"} style={iconStyles} />;
    default:
      return null;
  }
};

export default RenderIcon;
