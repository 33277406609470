import React from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import AddBoxIcon from "@material-ui/icons/AddBox";

import { postRequest } from "../../../logic/requests";
import { URLs } from "../../../logic/constants";
import { addOneMyTeam } from "../../../redux/actions";
import { resizeImage } from "../../../logic/utils";
import { useAlert, alerts } from "../../../context/AlertContext";
import { connect } from "react-redux";
import { fetchCreateTeam } from "../../../redux/teamsSlice";

const useStyle = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1, 0),
    },
  },
  teamNameContainer: {
    margin: theme.spacing(1, 0),
  },
  upload: {
    textAlign: "center",
  },
  input: {
    display: "none",
  },
  previewText: {
    display: "inline-block",
    margin: theme.spacing(1),
  },
  previewImage: {
    maxHeight: "64px",
    maxWidth: "64px",
  },
}));

const useUploadButtonStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    "& > *": {
      margin: theme.spacing(1, 0),
    },
  },
  input: {
    display: "none",
  },
}));

const ModalCreateTeam = ({ dispatchFetchCreateTeam }) => {
  const classes = useStyle();

  const showAlert = useAlert();

  const [open, set_open] = React.useState(false);
  const [nameValue, set_nameValue] = React.useState("");
  const [uploadedFileName, set_uploadedFileName] = React.useState("");
  const [avatarUri, set_avatarUri] = React.useState("");

  const handleClickOpen = () => set_open(true);
  const handleClose = () => {
    set_open(false);
    set_nameValue("");
    set_uploadedFileName("");
    set_avatarUri("");
  };

  const handleCreateTeam = () => {
    dispatchFetchCreateTeam({ teamName: nameValue, teamAvatar: avatarUri })
      .then(({ payload }) => {
        handleClose();
        showAlert(
          `Team '${payload.name}' has been created!`,
          alerts.success.severity,
        );
      })
      .catch(({ error }) => {
        showAlert(error, alerts.error.severity);
      });
  };

  const UploadButton = () => {
    const classes = useUploadButtonStyles();

    const handleOnClick = (event) => {
      const file = event.target.files[0];

      if (file) {
        resizeImage({
          file: file,
          maxSize: 48,
        })
          .then((resizedImage) => {
            console.log("Resized image: ", resizedImage);
            set_uploadedFileName(file.name);
            set_avatarUri(resizedImage);
          })
          .catch((err) => {
            showAlert(err.data.error, alerts.error.severity);
            console.error(err);
          });
      }
    };

    return (
      <div className={classes.root}>
        <input
          accept="image/*"
          className={classes.input}
          id="ModalCreateTeam-contained-button-file"
          multiple
          type="file"
          onChange={(e) => handleOnClick(e)}
        />
        <label htmlFor="ModalCreateTeam-contained-button-file">
          <Button
            variant="contained"
            color="primary"
            component="span"
            startIcon={<CloudUploadIcon />}
          >
            Upload Avatar
          </Button>
        </label>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <Button
        variant="contained"
        color="primary"
        size="large"
        startIcon={<AddBoxIcon />}
        onClick={handleClickOpen}
      >
        Create A Team
      </Button>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Create A Team</DialogTitle>
        <DialogContent className={classes.dialog}>
          <Grid
            container
            spacing={2}
            alignItems="flex-end"
            className={classes.teamNameContainer}
          >
            <Grid item>
              <Avatar
                variant="rounded"
                className={classes.previewImage}
                src={avatarUri}
              >
                {nameValue[0] ? nameValue[0].toUpperCase() : " "}
              </Avatar>
            </Grid>
            <Grid item xs>
              <TextField
                required
                fullWidth
                id="name"
                label="Team Name"
                type="text"
                onChange={(a) => set_nameValue(a.nativeEvent.target.value)}
              />
            </Grid>
            <Grid item />
          </Grid>
          <div className={classes.upload}>
            <UploadButton />
            <p className={classes.previewText}>{uploadedFileName}</p>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleCreateTeam}
            variant="contained"
            color="primary"
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapDispatchToProps = {
  dispatchFetchCreateTeam: fetchCreateTeam,
};

export default connect(null, mapDispatchToProps)(ModalCreateTeam);
