import {
  Avatar,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
} from "@material-ui/core";
import React from "react";
import { connect, useSelector } from "react-redux";

import {
  addOneMyTeam,
  teamInvitationsSelectors,
  removeOneMyTeamInvitation,
} from "../../../redux/actions";
import { URLs } from "../../../logic/constants";
import { useAlert, alerts } from "../../../context/AlertContext";

import { getRequest, postRequest } from "../../../logic/requests";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { isEmpty } from "lodash";

const useStyles = makeStyles((theme) => ({
  actionsContainer: {
    "& > *": {
      margin: theme.spacing(0),
    },
  },
}));

const ViewInvitations = ({
  dispatchAddOneMyTeam,
  dispatchRemoveOneMyTeamInvitation,
}) => {
  const classes = useStyles();

  const showAlert = useAlert();
  const invitorTeams = useSelector(teamInvitationsSelectors.selectAll);

  const handleInvitationAccept = (teamId) => {
    //console.log("Accepted.");
    postRequest(URLs.api.team.acceptInvitation, { teamId: teamId })
      .then((res) => {
        dispatchRemoveOneMyTeamInvitation(teamId);

        getRequest(URLs.api.team.get, { teamId: teamId }).then((r) => {
          dispatchAddOneMyTeam(r.data);
        });

        showAlert(alerts.success.message, alerts.success.severity);
      })
      .catch((err) => {
        switch (err.status) {
          default:
            showAlert(err.data.error, alerts.error.severity);
        }
      });
  };

  const handleInvitationCancel = (teamId) => {
    postRequest(URLs.api.team.rejectInvitation, { teamId: teamId })
      .then((res) => {
        dispatchRemoveOneMyTeamInvitation(teamId);
        showAlert(alerts.success.message, alerts.success.severity);
      })
      .catch((err) => {
        console.log(err.message);
        switch (err.status) {
          default:
            showAlert(err.data.error, alerts.error.severity);
        }
      });
  };

  return (
    <Paper variant="outlined">
      <List>
        {isEmpty(invitorTeams)
          ? "No invitations received."
          : invitorTeams.map((inv, i) => {
              return (
                <>
                  {i !== 0 && <Divider variant="middle" />}
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar variant="rounded" src={inv.avatar}>
                        {inv.name[0].toUpperCase()}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={inv.name}
                      secondary={"has invited you to join their team!"}
                    />
                    <ListItemSecondaryAction
                      className={classes.actionsContainer}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        edge="end"
                        aria-label="accept"
                        onClick={() => handleInvitationAccept(inv._id)}
                      >
                        <CheckIcon />
                      </Button>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleInvitationCancel(inv._id)}
                      >
                        <ClearIcon color="secondary" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </>
              );
            })}
      </List>
    </Paper>
  );
};

const mapDispatchToProps = {
  dispatchAddOneMyTeam: addOneMyTeam,
  dispatchRemoveOneMyTeamInvitation: removeOneMyTeamInvitation,
};

const mapStateToProps = (state) => ({
  redux_invitorTeams: state.teams.invitorTeams,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewInvitations);
