// Lobium-Organize / publicTeams - Initialized at Monday, July 05, 2021 at 14:53. (Lobium Snippets Prefix: ll)
//
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { getRequest } from "../../logic/requests";
import { URLs } from "../../logic/constants";

export const fetchPublicTeams = createAsyncThunk(
  "publicTeams/fetchPublicTeams",
  async (teamId, { rejectWithValue }) =>
    await getRequest(URLs.api.team.get, { teamId }, { withCredentials: true })
      .then((response) => {
        return response.data;
      })
      .catch(({ data }) => {
        return rejectWithValue(data);
      }),
);

const publicTeamsAdapter = createEntityAdapter({
  selectId: (object) => object._id,
});

const publicTeamsSlice = createSlice({
  name: "publicTeams",
  initialState: publicTeamsAdapter.getInitialState({
    loading: false,
    error: null,
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPublicTeams.fulfilled, (state, action) => {
        publicTeamsAdapter.upsertOne(state, action.payload);
      })
      .addMatcher(
        (action) => action.type.startsWith("publicTeams/"),
        (state, action) => {
          if (action.type.endsWith("/fulfilled")) {
            state.loading = false;
            state.error = null;
          } else if (action.type.endsWith("/pending")) {
            state.loading = true;
            state.error = null;
          } else if (action.type.endsWith("/rejected")) {
            state.loading = false;
            state.error = action.payload;
            throw action.payload;
          }
        },
      );
  },
});

export const {} = publicTeamsSlice.actions;

export const publicTeamsSelectors = publicTeamsAdapter.getSelectors(
  (state) => state.publicTeams,
);

export default publicTeamsSlice.reducer;
