// Lobium-Organize - Initialized at Thursday, October 14, 2021 at 14:51. (Lobium Snippets Prefix: ll)
//INFO about the issue: https://stackoverflow.com/questions/28329382/understanding-unique-keys-for-array-children-in-react-js/43892905#43892905
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { formatDateString } from "../../../logic/utils";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const LIST_KEY_TEST = ({ children }) => {
  const classes = useStyles();
  const cars = [
    "Saab",
    "Volvo",
    "BMW",
    "Volvo",
    "BMW",
    "Volvo",
    "BMW",
    "Volvo",
    "BMW",
    "Volvo",
    "BMW",
  ];

  let i = 0;
  return (
    <div className={classes.root}>
      {cars.map((n, i) => (
        <ListItem key={n + i} button>
          <ListItemIcon key={n + i}>hi</ListItemIcon>
          <ListItemText
            key={n + i}
            secondaryTypographyProps={{ variant: "caption" }}
            secondary={formatDateString(n.createdAt)}
          ></ListItemText>
        </ListItem>
      ))}
    </div>
  );
};

export default LIST_KEY_TEST;
