// Lobium-Organize - Initialized at Wednesday, July 14, 2021 at 16:15. (Lobium Snippets Prefix: ll)
import {
  Card,
  CardContent,
  CardMedia,
  Divider,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { withStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import React from "react";

export const styles = (theme) => {
  return {
    root: {
      display: "flex",
      height: "100%",
      margin: theme.spacing(4, 0, 2, 0),
    },
    mobileSearch: {
      display: "block",
      height: "100%",
      margin: theme.spacing(2, 0, 2, 0),
    },
    divider: {
      padding: theme.spacing(0.25),
      margin: theme.spacing(2),
    },
    content: {
      position: "relative",
      textAlign: "left",
    },
    title: {
      position: "relative",
      marginTop: 16,
    },
    cover: {
      width: 128,
      height: 128,
    },
    children: {
      background: "#f000",
      margin: theme.spacing(2),
      marginTop: 32,
      display: "block",
      flex: 1,
    },
  };
};

const Wrapper = React.Fragment;

const ProfileHeader = React.forwardRef((props, ref) => {
  const {
    classes,
    className,
    component: CardComponent = Card,
    children,
    profile,
    titleVariant = "h4",
    TypographyProps,
    ...other
  } = props;

  const title = profile.username;
  const subtitle = profile.discriminator;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const image =
    profile.discordId && profile.avatar
      ? `https://cdn.discordapp.com/avatars/${profile.discordId}/${profile.avatar}`
      : null;

  return (
    <Wrapper ref={ref} {...other}>
      <CardComponent className={clsx(classes.root, className)}>
        <CardMedia className={classes.cover} image={image} title={title} />

        <CardContent className={classes.content}>
          <Typography
            component="h1"
            variant={titleVariant}
            className={classes.title}
          >
            {title}
          </Typography>
          <Typography variant="subtitle2" color="textSecondary">
            {`#${subtitle}`}
          </Typography>
        </CardContent>
        {children && !isMobile && (
          <>
            <Divider
              orientation="vertical"
              flexItem
              className={classes.divider}
            />
            <div className={classes.children}>{children}</div>
          </>
        )}
      </CardComponent>

      {children && isMobile && (
        <CardComponent className={classes.mobileSearch}>
          {children}
        </CardComponent>
      )}
    </Wrapper>
  );
});

export default withStyles(styles, { name: "LobiumProfileHeader" })(
  ProfileHeader,
);
