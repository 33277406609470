//Pages exporter
//
// First import then export, rather than just exporting
// since it makes importing from this file easier
//
//This
import pages from "./index.js";
//
import PostLogin from "./PostLogin";
//
import CreateTournament from "./Tournaments/CreateTournament";
import ManageGroup from "./Groups/ManageGroup";
import CreateGroup from "./Groups/CreateGroup";
import EditTournament from "./Tournaments/EditTournament";
import EditTournamentDetails from "./Tournaments/EditTournamentDetails";
import Homepage from "./Homepage";
import Profile from "./Users/Profile";
import Tournaments from "./Tournaments/UserTournamentList";
import ViewTournament from "./Tournaments/ViewTournament";
import UserTeamList from "./Teams/UserTeamList";
import UserGroupList from "./Groups/UserGroupList";
import CreateStage from "./Stages/CreateStage";
import StageViewer from "./Stages/ViewStage";
import PublicTournamentList from "./Tournaments/PublicTournamentList";
import Loading from "./Loading";
import PublicProfile from "./Users/PublicProfile";
import Notification from "./Users/Notifications";

//

let _lastIndex = -1;

export const indexes = {
  none: _lastIndex++,
  home: _lastIndex++,
  play: _lastIndex++,
  teams: _lastIndex++,
  tournaments: _lastIndex++,
  groups: _lastIndex++,
  profile: _lastIndex++,
  _: process.env.REACT_APP_DEVELOPMENT_MODE === "true" ? _lastIndex++ : -1,
  //settings: _lastIndex++,
};

// Exports
//
// contents
export { CreateTournament }; //
export { CreateGroup }; //
export { ManageGroup }; //
export { EditTournament }; //
export { EditTournamentDetails }; //
export { Homepage }; //
export { Profile }; //
export { Tournaments }; //
export { ViewTournament }; //
export { UserTeamList }; //
export { UserGroupList }; //
export { CreateStage as StageCreator }; //
export { StageViewer }; //
export { PublicTournamentList }; //
export { Loading }; //
export { PublicProfile }; //
export { Notification };
// other
export { PostLogin }; // Page shown after login, not used
//
export default pages;
