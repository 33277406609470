import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  ListItem,
  makeStyles,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { postRequest } from "../../../logic/requests";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { URLs } from "../../../logic/constants";
import { useForm, Controller } from "react-hook-form";
import { useAlert, alerts } from "../../../context/AlertContext";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { defaultTo } from "lodash";
const useStyles = makeStyles((theme) => ({
  root: {},
  modalHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  form: {},
  button: {},
}));

export const ModalMigrateTournament = ({ open = false, onClose }) => {
  const history = useHistory();
  const showAlert = useAlert();
  const redirectTo = (pre, path, post) => {
    return history.push(pre + path + post);
  };

  const onCloseModal = () => {
    onClose();
  };
  const { register, handleSubmit, control, errors } = useForm();
  const onSubmit = (data) => {
    handleSaveButton(data);
  };
  const handleSaveButton = ({ tournamentId }) => {
    postRequest(URLs.api.migrate.migrate, {
      tournamentId,
    })
      .then((res) => {
        showAlert(alerts.success.message, alerts.success.severity);
        redirectTo("/t/", res.data._id, "/edit");
      })
      .catch((err) => {
        showAlert(err.data.error, alerts.error.severity);
      });
  };
  const classes = useStyles();
  const InputListItem = ({ label, ...props }) => {
    return (
      <ListItem>
        <TextField fullWidth variant="outlined" label={label} {...props} />
      </ListItem>
    );
  };
  return (
    <Dialog fullWidth open={open} onClose={onCloseModal}>
      <FormControl
        fullWidth
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        className={classes.form}
      >
        <DialogTitle className={classes.modalHeader}>
          <Typography
            variant="h5"
            color="inherit"
            className={classes.modalTitle}
          >
            Migrate a Tournament
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography color="inherit">
            Ex: https://challonge.com/ *fumgp43*
          </Typography>
          <InputListItem
            required
            name="tournamentId"
            label="URL identifier of the Challonge tournament."
            inputRef={register}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={onCloseModal}
            className={classes.button}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.button}
            endIcon={<AddBoxIcon />}
          >
            Create
          </Button>
        </DialogActions>
      </FormControl>
    </Dialog>
  );
};

export default connect(null, null)(ModalMigrateTournament);
