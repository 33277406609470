import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import { URLs } from "../logic/constants";
import { getRequest, postRequest } from "../logic/requests";

export const fetchMyNotifications = createAsyncThunk(
  "notifications/fetchMyNotifications",
  async () =>
    await getRequest(URLs.api.notifications.get, { withCredentials: true })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.error("My Notifications " + err);
        return {};
      }),
);

export const fetchMarkNotificationsRead = createAsyncThunk(
  "notifications/fetchMarkNotificationsRead",
  async () =>
    await postRequest(URLs.api.notifications.readAllNotifications, {
      withCredentials: true,
    })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.error("Mark as read " + err);
        return {};
      }),
);

const notificationsAdapter = createEntityAdapter({
  selectId: (notification) => notification._id,
  sortComparer: (a, b) => a.createdAt < b.createdAt,
});

const notificationsSlice = createSlice({
  name: "notifications",
  initialState: notificationsAdapter.getInitialState({
    loading: false,
    error: null,
  }),
  reducers: {
    upsertOneNotification: notificationsAdapter.upsertOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyNotifications.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMyNotifications.fulfilled, (state, action) => {
        notificationsAdapter.upsertMany(state, action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchMyNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = `Couldn't get notifications!`;
        throw new Error(state.error);
      })
      .addCase(fetchMarkNotificationsRead.fulfilled, (state, action) => {
        state.ids.map((id) => (state.entities[id].read = true));
      });
  },
});

export const { upsertOneNotification } = notificationsSlice.actions;

const defaultSelectors = notificationsAdapter.getSelectors(
  (state) => state.notifications,
);

export const notificationsSelectors = {
  ...defaultSelectors,
  selectRead: createSelector(defaultSelectors.selectAll, (notifications) =>
    notifications.filter((n) => n.read),
  ),
  selectUnread: createSelector(defaultSelectors.selectAll, (notifications) =>
    notifications.filter((n) => !n.read),
  ),
};

export default notificationsSlice.reducer;
