import { Container, Divider, Link } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { indexes } from "../";
import UserSearch from "../../components/dependent/UserSearch";
import PageHeader from "../../components/independent/PageHeader";
import PaperCard from "../../components/independent/PaperCard";
import RenderIcon from "../../components/independent/RenderIcon";
import { useSidebarIndex } from "../../context/SidebarIndexContext";
import { fetchMyGroups, fetchMyTeams } from "../../redux/actions";
import GroupsTable from "./components/GroupsTable";
import ProfileHeader from "./components/ProfileHeader";
import redirectSwitch from "./components/redirectSwitch";
import TeamsTable from "./components/TeamsTable";

const useStyles = makeStyles((theme) => ({
  root: {},
  listItem: {
    textAlign: "left-align",
  },
  listItemIcon: {
    marginRight: "-20px",
  },
  iconPaper: {
    padding: theme.spacing(0.3),
    marginRight: theme.spacing(3.5),
    alignItems: "center",
    display: "flex",
    backgroundColor: "#FFFAFA",
    border: "1px solid " + theme.palette.primary.main,
  },
}));

export const ProfileContent = ({
  profile_redux,
  isTeamsLoading_redux,
  isGroupsLoading_redux,
  dispatchFetchMyTeams,
  dispatchFetchMyGroups,
}) => {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [sidebarIndex, set_sidebarIndex] = useSidebarIndex();
  const history = useHistory();

  const redirectTo = React.useCallback(
    (path = "") => history.push(path),
    [history]
  );

  set_sidebarIndex(indexes.profile);

  React.useEffect(() => {
    dispatchFetchMyTeams();
    dispatchFetchMyGroups();
  }, []);

  const onSearchSelectedHandler = (selection) => {
    redirectTo("/profile/" + selection._id);
  };

  return (
    <section className={classes.root}>
      <PageHeader title="Profile" />
      <Container disableGutters>
        <ProfileHeader profile={profile_redux}>
          <UserSearch onSelected={onSearchSelectedHandler} />
        </ProfileHeader>
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={12} lg={6}>
            <PaperCard title="Teams">
              <TeamsTable size="small" myTeams loading={isTeamsLoading_redux} />
            </PaperCard>
          </Grid>

          <Grid item xs={12} lg={6}>
            <PaperCard title="Groups">
              <GroupsTable
                size="small"
                userId={profile_redux._id}
                myGroups
                loading={isGroupsLoading_redux}
              />
            </PaperCard>
          </Grid>

          <Grid item xs lg={6}>
            <PaperCard title="Connections">
              <div>
                <List>
                  {profile_redux.connections &&
                  profile_redux.connections.length > 0 ? (
                    profile_redux.connections.map(
                      (conn, i) =>
                        !!conn.visibility && (
                          <Link
                            key={conn + i}
                            target="_blank"
                            href={redirectSwitch(conn)}
                          >
                            <ListItem key={conn + i} button>
                              <ListItemIcon
                                key={conn + i}
                                className={classes.listItemIcon}
                              >
                                <Paper
                                  key={conn + i}
                                  className={classes.iconPaper}
                                  elevation={3}
                                >
                                  {RenderIcon(conn.type)}
                                </Paper>
                              </ListItemIcon>
                              <ListItemText
                                key={conn + i}
                                className={classes.listItem}
                              >
                                {conn.name}
                                <Divider key={conn + i} light />
                              </ListItemText>
                            </ListItem>
                          </Link>
                        )
                    )
                  ) : (
                    <Typography>There aren't any connections.</Typography>
                  )}
                </List>
              </div>
            </PaperCard>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

const mapStateToProps = (state) => ({
  profile_redux: state.profile,
  isTeamsLoading_redux: state.teams.loading,
  isGroupsLoading_redux: state.groups.loading,
});

const mapDispatchToProps = {
  dispatchFetchMyTeams: fetchMyTeams, //TODO my teams degilde, profilin sahibinin IDsi ile onun takimlarini fetch thunk
  dispatchFetchMyGroups: fetchMyGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContent);
