// Lobium-Organize - Initialized at Tuesday, July 06, 2021 at 14:23. (Lobium Snippets Prefix: ll)
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import DateFnsAdapter from "@date-io/date-fns";

const dateFns = new DateFnsAdapter();

const useStyles = makeStyles((theme) => ({
  root: {},
  testingMode: {
    position: "fixed",
    display: "block",
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    zIndex: "10000",
    backgroundColor: "rgba(0,0,0,0.1)",
    pointerEvents: "none",
  },
  text: {
    position: "absolute",
    top: "50%",
    left: "50%",
    opacity: 0.75,
    height: "100vh",
    width: "100vw",
    transform: "translate(-50%,-50%)",
  },
}));

const getViewportSize = () => {
  return `${Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0,
  )}x${Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0,
  )}`;
};

const TestingOverlay = ({ children }) => {
  const classes = useStyles();
  const [currentDate, set_currentDate] = React.useState(new Date());

  React.useEffect(() => {
    const interval = setInterval(() => set_currentDate(new Date()), 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div
      className={process.env.REACT_APP_TESTING_MODE && classes.testingMode}
      onClick="off()"
    >
      <svg className={classes.text} xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern id="Text" viewBox="0,0,100,100" width="0.1" height="0.075">
            <text y="40" fill="white" fontSize="5.5">
              © Lobium - Frontend Testing - R{React.version}
            </text>
            <text y="50" fill="white" fontSize="5.5">
              {`${dateFns.format(currentDate, "PPpp")} ${
                navigator.language
              } ${getViewportSize()}`}
            </text>
            <text y="60" fill="white" fontSize="5.5">
              {`${
                Intl.DateTimeFormat().resolvedOptions().timeZone
              } (${dateFns.format(currentDate, "z")}) ${navigator.platform}`}
            </text>
          </pattern>
          <pattern
            id="Pattern"
            viewBox="0,0,10,10"
            width="0.0005"
            height="0.0005"
          >
            <polygon points="0,0 0,5 5,5 5,0" />
          </pattern>
        </defs>

        <rect
          fill="url(#Pattern)"
          fill-opacity="50%"
          width="3000"
          height="3000"
        />
        <rect
          fill="url(#Text)"
          transform="translate(1220, -1220) rotate(45)"
          width="3000"
          height="3000"
        />
      </svg>
    </div>
  );
};

export default TestingOverlay;
