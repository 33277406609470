import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useAlert, alerts } from "../../../context/AlertContext";
import { URLs } from "../../../logic/constants";
import { getRequest } from "../../../logic/requests";

const TournamentMemberList = ({ team }) => {
  const classes = {};
  const showAlert = useAlert();
  const [members, set_members] = React.useState([]);

  React.useEffect(() => {
    getRequest(URLs.api.group.getTournamentsInGroup, {
      groupId: team._id,
    })
      .then((res) => {
        if (Array.isArray(res.data)) {
          set_members(res.data);
        }
      })
      .catch((err) =>
        showAlert("Error retrieving members!", alerts.error.severity),
      );
  }, []);

  return !members.length ? null : (
    <List dense className={classes.root}>
      <Typography variant="h6" gutterBottom style={{ textAlign: "center" }}>
        Tournaments
      </Typography>
      {
        members.map((m, index) => (
          <ListItem key={m}>
            <ListItemAvatar>
              <Avatar
                variant="square"
                className={classes.avatar}
                alt={`Avatar n°${index + 1}`}
                src={m.avatar}
              />
            </ListItemAvatar>
            <ListItemText
              primary={m.name}
              primaryTypographyProps={{
                display: "inline",
              }}
              // secondary={` #${m.discriminator}`}
              secondary={m.game}
              secondaryTypographyProps={{
                // display: "inline",
                variant: "caption",
                style: { color: "#aaa" },
              }}
            />
          </ListItem>
        ))
        // : times(defaultTo(team.members.length, 3), () => (
        //     <Skeleton height={50}></Skeleton>
        //   ))
      }
    </List>
  );
};

export default TournamentMemberList;
