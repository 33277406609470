import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { blue } from "@material-ui/core/colors";
import {
  Button,
  DialogActions,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { postRequest } from "../../../logic/requests";
import { URLs } from "../../../logic/constants";
import { useParams } from "react-router";
import AddBoxIcon from "@material-ui/icons/AddBox";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

const SimpleDialog = (props) => {
  const [stageType, setStageType] = React.useState("single");
  const [name, setName] = React.useState("");
  const classes = useStyles();
  const { onClose, open } = props;
  const params = useParams();
  const handleClose = () => {
    onClose();
  };

  const createStage = () => {
    if (stageType !== "null")
      postRequest(URLs.api.stage.create, {
        type: stageType,
        tournamentId: props.tournamentId,
        name: name,
      }).then(
        (res) =>
          (window.location.href =
            "http://localhost:3001/t/" +
            params.id +
            "/edit/stage/" +
            res.data.id),
      );
  };

  const handleChange = (value) => {
    setStageType(value);
  };
  return (
    <Dialog
      fullWidth
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Create New Stage</DialogTitle>
      <FormControl
        variant="outlined"
        className={classes.formControl}
        style={{ margin: 16 }}
      >
        <TextField
          id="outlined-basic"
          label="Name"
          variant="outlined"
          autoComplete={false}
          onChange={(event) => setName(event.target.value)}
        />
        <br />

        <Select
          id="demo-simple-select-outlined"
          value={stageType}
          onChange={(event) => handleChange(event.target.value)}
        >
          <MenuItem value={"single"}>Single Elimination</MenuItem>
          <MenuItem value={"double"} disabled>
            Double Elimination
          </MenuItem>
          <MenuItem value={"league"} disabled>
            League
          </MenuItem>
        </Select>
        <br />
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            endIcon={<AddBoxIcon />}
            onClick={createStage}
          >
            Create
          </Button>
        </DialogActions>
      </FormControl>
    </Dialog>
  );
};

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default SimpleDialog;
