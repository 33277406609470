// Lobium-Organize - Initialized at Sunday, July 11, 2021 at 14:14. (Lobium Snippets Prefix: ll)
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { HashRouter, Route, Switch } from "react-router-dom";
import PAGES from "./index.js";
import LobiumLink from "../../components/independent/LobiumLink.jsx";
import { useSidebarIndex } from "../../context/SidebarIndexContext.jsx";
import { indexes } from "../index.js";
import { ROUTE_LIST_PROVIDER } from "./contexts/ROUTE_LIST_CONTEXT.jsx";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const HIDDEN_ROUTER = (props) => {
  const classes = useStyles();

  // eslint-disable-next-line no-unused-vars
  const [sidebarIndex, set_sidebarIndex] = useSidebarIndex();

  set_sidebarIndex(indexes._);

  return (
    <HashRouter hashType="noslash" className={classes.root}>
      <ROUTE_LIST_PROVIDER>
        <Switch>
          <Route exact path="/" component={PAGES.HOMEPAGE} />
          <Route exact path="/theme" component={PAGES.THEME_PAGE} />
          <Route
            exact
            path="/components/dependent/userSearch"
            component={PAGES.USER_SEARCH}
          />
          <Route
            exact
            path="/components/dependent/languageSelector"
            component={PAGES.LANGUAGE_SELECTOR}
          />
          <Route
            exact
            path="/components/dependent/listKeyTest"
            component={PAGES.LIST_KEY_TEST}
          />
          <Route>
            _ route 404 not found. Go to the{" "}
            <LobiumLink to="/">_ Homepage</LobiumLink>.
          </Route>
        </Switch>
      </ROUTE_LIST_PROVIDER>
    </HashRouter>
  );
};

export default HIDDEN_ROUTER;
