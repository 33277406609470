import {
  Avatar,
  Button,
  Container,
  Divider,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import PageHeader from "../../components/independent/PageHeader";
import { makeStyles } from "@material-ui/core/styles";
import TooltipIcon from "../../components/independent/TooltipIcon";
import { Controller, useForm } from "react-hook-form";
import { alerts, alertSeverity, useAlert } from "../../context/AlertContext";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { resizeImage } from "../../logic/utils";
import { connect } from "react-redux";
import { createNewGroup } from "../../redux/actions";
import { useHistory } from "react-router-dom";
import AddBoxIcon from "@material-ui/icons/AddBox";

const useStyles = makeStyles((theme) => ({
  root: { userSelect: "none" },

  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  description: {
    margin: theme.spacing(1, 0, 0, 0),
    textAlign: "left",
  },
  divider: {
    margin: theme.spacing(0, 0, 2, 2),
    padding: theme.spacing(0.2),
  },
  swipeableContainer: {
    minHeight: "50vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  upload: {
    textAlign: "center",
    "& > *": {
      margin: theme.spacing(1, 0),
      display: "inline-block",
    },
  },
  previewText: {
    margin: theme.spacing(1),
  },
  previewImage: {
    width: "128px",
    height: "128px",
  },
  input: {
    display: "none",
  },
  loadingWrapper: {
    position: "relative",
  },
}));

const CreateGroup = ({ dispatchCreateNewGroup }) => {
  const classes = useStyles();
  const showAlert = useAlert();
  const history = useHistory();
  const redirectTo = React.useCallback(
    (path = "") => history.push(path),
    [history],
  );
  const { register, handleSubmit, control, errors } = useForm({});
  const [uploadedFileName, set_uploadedFileName] = React.useState("");
  const [avatarUri, set_avatarUri] = React.useState("");
  const [nameValue, set_nameValue] = React.useState("");

  const onSubmit = () => {
    const params = {
      groupName: nameValue,
      groupAvatar: avatarUri,
    };
    dispatchCreateNewGroup(params)
      .then(({ payload }) => {
        showAlert(
          `Group "${payload.groupName}" has been created.`,
          alertSeverity.success,
        );
        redirectTo("/groups");
      })
      .catch(({ error }) => showAlert(error, alertSeverity.error));
  };

  const UploadButton = () => {
    const handleOnClick = (event) => {
      const file = event.target.files[0];

      if (file) {
        resizeImage({
          file: file,
          maxSize: 128,
        })
          .then((resizedImage) => {
            console.log("Resized image: ", resizedImage);
            set_uploadedFileName(file.name);
            set_avatarUri(resizedImage);
            showAlert(
              "Avatar has been uploaded successfully.",
              alertSeverity.success,
            );
          })
          .catch((err) => {
            showAlert(err.data.error, alerts.error.severity);
            console.error(err);
          });
      }
    };
    return (
      <div className={classes.root}>
        <input
          accept="image/*"
          className={classes.input}
          id="CreateGroup-contained-button-file"
          multiple
          type="file"
          onChange={(e) => handleOnClick(e)}
        />
        <label htmlFor="CreateGroup-contained-button-file">
          <Button
            variant="contained"
            color="primary"
            size="small"
            component="span"
            startIcon={<CloudUploadIcon />}
          >
            Upload Avatar
          </Button>
        </label>
      </div>
    );
  };
  const textFieldProps = {
    fullWidth: true,
    variant: "outlined",
  };
  return (
    <section className={classes.root}>
      <PageHeader title="Create A Group" />
      <Container disableGutters className={classes.swipeableContainer}>
        <Container>
          <Paper variant="outlined" className={classes.paper}>
            <div>
              <p className={classes.previewText}>{uploadedFileName}</p>
              <Avatar
                variant="rounded"
                src={avatarUri}
                className={classes.previewImage}
                style={{ margin: "auto" }}
              >
                {nameValue[0] ? nameValue[0].toUpperCase() : "?"}
              </Avatar>
            </div>

            <div className={classes.upload}>
              <UploadButton />
            </div>
            <Typography
              styles={{ backgroundColor: "red" }}
              variant="h6"
              className={classes.description}
            >
              Name*
              <TooltipIcon title="The name of the group that will be shown." />
            </Typography>
            <Divider className={classes.divider} variant="inset" />
            <TextField
              {...textFieldProps}
              required
              autoFocus
              name="name"
              label="Enter the group's name."
              inputRef={register({
                required: "Please enter the group's name.",
              })}
              onChange={(a) => set_nameValue(a.nativeEvent.target.value)}
            />
            <Container
              maxWidth="xs"
              disableGutters
              className={classes.buttonContainer}
            >
              <div>
                <br />
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                  endIcon={<AddBoxIcon />}
                >
                  Create
                </Button>
              </div>
            </Container>
          </Paper>
        </Container>
      </Container>
    </section>
  );
};
const mapDispatchToProps = {
  dispatchCreateNewGroup: createNewGroup,
};

export default connect(null, mapDispatchToProps)(CreateGroup);
