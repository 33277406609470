import {
  Avatar,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import ClassicTable from "../../components/independent/ClassicTable";
import React from "react";
import PageHeader from "../../components/independent/PageHeader";
import { formatDateString } from "../../logic/utils";
import { makeStyles } from "@material-ui/core/styles";
import { alerts, alertSeverity, useAlert } from "../../context/AlertContext";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { resizeImage } from "../../logic/utils";
import { useForm } from "react-hook-form";

import { Autocomplete } from "@material-ui/lab";
import { connect, useSelector } from "react-redux";

import { fetchOneGroup, groupsSelectors } from "../../redux/actions";
import { useHistory, useParams } from "react-router-dom";
import { URLs } from "../../logic/constants";
import { getRequest, postRequest } from "../../logic/requests";
import PermissionsCrossTable from "./components/PermissionsCrossTable";
import PopoverDialogButton from "../../components/independent/PopoverDialogButton";

const useStyles = makeStyles((theme) => ({
  root: { userSelect: "none" },

  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  description: {
    margin: theme.spacing(1, 0, 0, 0),
    textAlign: "left",
  },
  divider: {
    margin: theme.spacing(0, 0, 2, 2),
    padding: theme.spacing(0.2),
  },
  swipeableContainer: {
    minHeight: "50vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  upload: {
    textAlign: "center",
    "& > *": {
      margin: theme.spacing(1, 0),
      display: "inline-block",
    },
  },
  previewText: {
    margin: theme.spacing(1),
  },
  previewImage: {
    width: "128px",
    height: "128px",
  },
  input: {
    display: "none",
  },
  loadingWrapper: {
    position: "relative",
  },
  button: {
    margin: theme.spacing(1, 0),
  },
}));

const columns = [
  { id: "game", label: "Game", maxWidth: 150 },
  { id: "name", label: "Name", align: "center", minWidth: 300 },
  {
    id: "id",
    label: "Id",
    maxWidth: 25,
  },
  {
    id: "startDate",
    label: "Start\u00a0Date",
    minWidth: 100,
    //align: "right",
    format: (value) => formatDateString(value),
  },
];

const createData = (game, name, id, startDate) => {
  return { game, name, id, startDate };
};
const permissionsStateToInteger = (permissions) => {
  let result = 0b000000000000;
  if (permissions.match.create) result = result | 0b000100000000;
  if (permissions.match.read) result = result | 0b001000000000;
  if (permissions.match.update) result = result | 0b010000000000;
  if (permissions.match.delete) result = result | 0b100000000000;
  if (permissions.tournament.create) result = result | 0b000000010000;
  if (permissions.tournament.read) result = result | 0b000000100000;
  if (permissions.tournament.update) result = result | 0b000001000000;
  if (permissions.tournament.delete) result = result | 0b000010000000;
  if (permissions.group.create) result = result | 0b000000000001;
  if (permissions.group.read) result = result | 0b000000000010;
  if (permissions.group.update) result = result | 0b000000000100;
  if (permissions.group.delete) result = result | 0b000000001000;

  console.log(result);

  return result;
};

const numToString = (num) => {
  const numBinaryString = num.toString(2);
  return numBinaryString.padStart(12, "0");
};

const statePermPrepare = (permissions) => {
  console.log(permissions);
  const perms = numToString(permissions);
  console.log(perms);
  const preState = {
    group: {
      create: perms[8] === "1" ? true : false,
      read: perms[9] === "1" ? true : false,
      update: perms[10] === "1" ? true : false,
      delete: perms[11] === "1" ? true : false,
    },
    tournament: {
      create: perms[4] === "1" ? true : false,
      read: perms[5] === "1" ? true : false,
      update: perms[6] === "1" ? true : false,
      delete: perms[7] === "1" ? true : false,
    },
    match: {
      create: perms[0] === "1" ? true : false,
      read: perms[1] === "1" ? true : false,
      update: perms[1] === "1" ? true : false,
      delete: perms[2] === "1" ? true : false,
    },
  };

  return preState;
};
const ManageGroup = ({ dispatchFetchOneGroup }) => {
  const classes = useStyles();
  const showAlert = useAlert();
  const history = useHistory();
  const params = useParams();
  const { register, handleSubmit, control, errors } = useForm({});
  const redirectTo = React.useCallback(
    (path = "") => history.push(path),
    [history]
  );
  const [selectedTournamentForAdd, setSelectedTournamentForAdd] =
    React.useState("");
  const [uploadedFileName, set_uploadedFileName] = React.useState("");
  const [avatarUri, set_avatarUri] = React.useState("");
  const [nameValue, set_nameValue] = React.useState("");

  const [members, set_members] = React.useState([]);
  const group = useSelector((state) =>
    groupsSelectors.selectById(state, params.id)
  );

  const [selectedMember, setSelectedMember] = React.useState({});
  const [movableTournaments, setMovableTournaments] = React.useState([]);
  const [movableTournamentsAutoCorrect, setMovableTournamentsAutoCorrect] =
    React.useState([]);
  const [selectedForInvitation, setSelectedForInvitation] = React.useState({});
  const [inviteCompleteList, setInviteCompleteList] = React.useState([]);
  const [groupMembersList, setGroupMembersList] = React.useState([]);

  const [permissionsForInvitation, setPermissionsForInvitation] =
    React.useState({
      match: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      tournament: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      group: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
    });
  const [permissions, setPermissions] = React.useState({
    match: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    tournament: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
    group: {
      create: false,
      read: false,
      update: false,
      delete: false,
    },
  });

  const UploadButton = () => {
    const handleOnClick = (event) => {
      const file = event.target.files[0];

      if (file) {
        resizeImage({
          file: file,
          maxSize: 128,
        })
          .then((resizedImage) => {
            console.log("Resized image: ", resizedImage);
            set_uploadedFileName(file.name);
            set_avatarUri(resizedImage);
          })
          .catch((err) => {
            showAlert(err.data.error, alerts.error.severity);
            console.error(err);
          });
      }
    };
    return (
      <div className={classes.button}>
        <input
          accept="image/*"
          className={classes.input}
          id="ManageGroup-contained-button-file"
          name="ManageGroup-contained-button-file"
          multiple
          type="file"
          onChange={(e) => handleOnClick(e)}
        />
        <label htmlFor="ManageGroup-contained-button-file">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            component="span"
            startIcon={<CloudUploadIcon />}
          >
            Upload Avatar
          </Button>
        </label>
      </div>
    );
  };
  const handlers = {
    invitationAutocompleteHandler: (value, reason) => {
      if (reason === "input")
        getRequest(URLs.api.user.search, { username: value }).then((res) =>
          setInviteCompleteList(res.data)
        );
      else setSelectedForInvitation(value);
    },
    leaveGroup: () => {
      postRequest(URLs.api.group.leave, { groupId: group._id })
        .then((r) => {
          showAlert(
            "You have successfully left the group",
            alerts.success.severity
          );
          redirectTo("/groups");
        })
        .catch((err) => showAlert(err.data.error, alerts.error.severity));
    },
    addTournamentToGroup: () => {
      postRequest(URLs.api.group.move, {
        tournamentId: selectedTournamentForAdd,
        groupId: group._id,
      })
        .then((res) => {
          getRequest(URLs.api.tournament.get, {
            tournamentId: selectedTournamentForAdd,
          })
            .then((res) => {
              members.push(
                createData(
                  res.data.game,
                  res.data.name,
                  res.data._id,
                  res.data.startDate
                )
              );
              set_members(members);
              showAlert(
                "Successfully added the tournament to the group!",
                alerts.success.severity
              );
            })
            .catch((err) => showAlert(err.data.error, alerts.error.severity));
        })
        .catch((err) => showAlert(err.data.error, alerts.error.severity));
    },
    deleteGroup: () => {
      postRequest(URLs.api.group.delete, {
        groupId: group._id,
      })
        .then((res) => {
          redirectTo("/groups");
          showAlert(
            "You have successfully deleted group " + group.groupName,
            alerts.success.severity
          );
        })
        .catch((err) => showAlert(err.data.error, alerts.error.severity));
    },
    updateGroupInfo: () => {
      postRequest(URLs.api.group.update, {
        groupId: group._id,
        groupAvatar: avatarUri,
        groupName: nameValue,
      })
        .then((res) => {
          showAlert("Successfully updated the group!", alerts.success.severity);
        })
        .catch((err) => {
          showAlert(err.data.error, alerts.error.severity);
        });
    },
    removeMember: () => {
      console.log("REMOVE MEMBER => " + selectedMember.userId, group._id);
      postRequest(URLs.api.group.kickMember, {
        userId: selectedMember.userId,
        groupId: group._id,
      })
        .then((res) => {
          setSelectedMember({});
          setPermissions(statePermPrepare(0));
          showAlert(
            "Successfully removed from the group.",
            alerts.success.severity
          );
        })
        .catch((err) => {
          showAlert(err.data.error, alertSeverity.error);
        });
    },
    sendInvitation: () => {
      const params = {
        groupId: group._id,
        userId: selectedForInvitation._id,
        permissions: permissionsStateToInteger(permissionsForInvitation),
      };
      postRequest(URLs.api.group.invite, params)
        .then((res) => {
          if (res.status === 200)
            showAlert(alerts.success.message, alerts.success.severity);
          else console.error(res);
        })
        .catch((err) => {
          switch (err.status) {
            case 408:
              showAlert(
                "This user has been already invited!",
                alerts.warning.severity
              );
              break;
            default:
              showAlert(err.data.error, alerts.error.severity);
          }
        });
    },
    memberAutocompleteHandler: (value, reason) => {
      if (reason === "select-option") {
        setSelectedMember(value);
        console.log(value);
        setPermissions(statePermPrepare(value.permission));
      }
    },

    updatePermissions: () => {
      const params = {
        userId: selectedMember.userId,
        groupId: group._id,
        permissions: permissionsStateToInteger(permissions),
      };

      postRequest(URLs.api.group.changePerm, params)
        .then((res) =>
          showAlert(
            "Successfully updated the permissions.",
            alerts.success.severity
          )
        )
        .catch((err) => {
          showAlert(err.data.error, alerts.error.severity);
        });
    },
  };
  React.useEffect(() => {
    dispatchFetchOneGroup(params.id).then((res) => console.log("ten", res));

    getRequest(URLs.api.group.getTournamentsInGroup, {
      groupId: params.id,
    })
      .then((res) => {
        if (Array.isArray(res.data)) {
          //console.log(res.data);
          set_members(
            res.data.map((t) => createData(t.game, t.name, t._id, t.startDate))
          );
        }
      })
      .catch((err) =>
        showAlert("Error retrieving members!", alerts.error.severity)
      );
  }, []);

  React.useEffect(() => {
    const handler = async () => {
      try {
        const movableTournaments = await getRequest(
          URLs.api.group.movableTournaments,
          { groupId: params.id }
        );
        setMovableTournaments(movableTournaments.data);
        setMovableTournamentsAutoCorrect(movableTournaments.data);
        const membersOfGroup = await getRequest(URLs.api.group.getMembersOf, {
          groupId: params.id,
        });
        const data = [];
        for (let i = 0; i < membersOfGroup.data.length; i++)
          for (let j = 0; j < group.userList.length; j++)
            if (membersOfGroup.data[i]._id == group.userList[j].userId) {
              data.push({
                userId: group.userList[j].userId,
                username: membersOfGroup.data[i].username,
                permission: group.userList[j].permission,
              });
              break;
            }
        //console.log("GROUP MEMBER LIST => " + data);
        setGroupMembersList(data);
      } catch (error) {
        console.log(error);
      }
    };
    handler();
  }, [group]);

  return (
    <section className={classes.root}>
      <PageHeader title={group && "Manage Group " + group.groupName} />
      <Container disableGutters>
        <Paper variant="outlined" className={classes.paper}>
          <Typography variant="h6" className={classes.description}>
            Tournaments in Group
          </Typography>
          <Divider className={classes.divider} variant="inset" />
          <ClassicTable
            rows={members}
            columns={columns}
            rowsOnClick={(params) => redirectTo("/t/" + params.id + "/edit")}
          ></ClassicTable>
        </Paper>
        <Paper variant="outlined" className={classes.paper}>
          <Typography variant="h6" className={classes.description}>
            Move Tournaments
          </Typography>
          <Divider className={classes.divider} variant="inset" />
          <Autocomplete
            id="combo-box-demo"
            closeIcon={null}
            options={movableTournamentsAutoCorrect}
            getOptionLabel={(option) => option.name}
            // onChange={(a, b, c) =>
            //   handlers.getMovableTournaments(b, c)
            // }
            onInputChange={(a, text, reason) => {
              let res = [];
              movableTournaments.forEach((tournament) => {
                if (tournament.name.indexOf(text) !== -1) res.push(tournament);
              });
              setMovableTournamentsAutoCorrect(res);
              console.log(movableTournamentsAutoCorrect);
              if (reason === "reset")
                setSelectedTournamentForAdd(
                  movableTournamentsAutoCorrect.find(
                    (element) => element.name === text
                  )._id
                );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Add Tournament To Group"
                variant="outlined"
              />
            )}
          />
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={handlers.addTournamentToGroup}
          >
            Add Tournament
          </Button>
        </Paper>
        <Paper variant="outlined" className={classes.paper}>
          <Typography variant="h6" className={classes.description}>
            Invite
          </Typography>
          <Divider className={classes.divider} variant="inset" />
          <Autocomplete
            id="combo-box-demo"
            options={inviteCompleteList}
            getOptionLabel={(option) => option.username}
            onChange={(a, b, c) => handlers.invitationAutocompleteHandler(b, c)}
            onInputChange={(a, text, reason) =>
              text.length > 1
                ? handlers.invitationAutocompleteHandler(text, reason)
                : null
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Invite User to Group"
                variant="outlined"
              />
            )}
          />
          <br />
          <PermissionsCrossTable
            permissions={permissionsForInvitation}
            setState={setPermissionsForInvitation}
          />
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={handlers.sendInvitation}
          >
            Invite
          </Button>
        </Paper>
        <Paper variant="outlined" className={classes.paper}>
          <Typography variant="h6" className={classes.description}>
            Change Permissions
          </Typography>
          <Divider className={classes.divider} variant="inset" />
          <Autocomplete
            id="combo-box-demo"
            closeIcon={null}
            options={groupMembersList}
            getOptionLabel={(option) => option.username}
            onChange={(a, b, c) => handlers.memberAutocompleteHandler(b, c)}
            onInputChange={(a, text, reason) =>
              text.length > 1
                ? handlers.memberAutocompleteHandler(text, reason)
                : null
            }
            renderInput={(params) => (
              <TextField {...params} label="Select Member" variant="outlined" />
            )}
          />

          <br />
          {selectedMember.userId && (
            <PermissionsCrossTable
              permissions={permissions}
              setState={setPermissions}
            />
          )}
          {selectedMember.userId && (
            <Grid container spacing={1}>
              <Grid item xs />
              <Grid item xs={3}>
                <PopoverDialogButton
                  fullWidth
                  className={classes.button}
                  onClick={handlers.removeMember}
                  color="secondary"
                  variant="contained"
                >
                  Kick
                </PopoverDialogButton>
              </Grid>
              <Grid item xs={3}>
                <Button
                  fullWidth
                  className={classes.button}
                  onClick={handlers.updatePermissions}
                  color="primary"
                  variant="contained"
                >
                  Update
                </Button>
              </Grid>
              <Grid item xs />
            </Grid>
          )}
        </Paper>
        <Paper variant="outlined" className={classes.paper}>
          <Typography variant="h6" className={classes.description}>
            Edit Group
          </Typography>
          <Divider className={classes.divider} variant="inset" />
          <Grid
            container
            spacing={2}
            alignItems="flex-end"
            // className={classes.groupNameContainer}
          >
            <Grid item>
              <Avatar
                variant="rounded"
                // className={classes.previewImage}
                src={avatarUri}
              >
                {nameValue[0] ? nameValue[0].toUpperCase() : " "}
              </Avatar>
            </Grid>
            <Grid item xs>
              <TextField
                required
                fullWidth
                id="name"
                label="Group Name"
                type="text"
                onChange={(a) => set_nameValue(a.nativeEvent.target.value)}
              />
            </Grid>
            <Grid item />
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <UploadButton />
            </Grid>
            <Grid item xs>
              <p className={classes.previewText}>{uploadedFileName}</p>
            </Grid>
            <Grid item xs={2}>
              <PopoverDialogButton
                fullWidth
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={handlers.leaveGroup}
              >
                Leave
              </PopoverDialogButton>
            </Grid>
            <Grid item xs={2}>
              <PopoverDialogButton
                fullWidth
                className={classes.button}
                variant="contained"
                color="secondary"
                onClick={handlers.deleteGroup}
              >
                Delete Group
              </PopoverDialogButton>
            </Grid>
            <Grid item xs={3}>
              <Button
                fullWidth
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={handleSubmit(handlers.updateGroupInfo)}
              >
                Edit
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </section>
  );
};

const mapDispatchToProps = {
  dispatchFetchOneGroup: fetchOneGroup,
};

export default connect(null, mapDispatchToProps)(ManageGroup);
