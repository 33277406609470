import React, { useEffect } from "react";
import {
  Avatar,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";

import { Skeleton } from "@material-ui/lab";
import { defaultTo, times } from "lodash";
import { useAlert, alerts } from "../../context/AlertContext";
import { connect, useSelector } from "react-redux";
import { removeOneMyTeam, setTeams } from "../../redux/actions";
import { leaveTeam } from "../../redux/teamsSlice";

const useStyles = makeStyles((theme) => ({
  actionsContainer: {
    "& > *": {
      margin: theme.spacing(0, 1),
    },
  },
  teamAvatar: {
    display: "flex",
  },
  modalHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  modalTitle: {
    display: "flex",
  },
  panelButton: {
    textAlign: "right",
    margin: theme.spacing(1, 0),
  },
  selectedMainTab: {
    background: "#0f0 !important",
  },
}));

const KickMemberCheckboxList = ({ team, selectedMembers = [] }) => {
  const classes = useStyles();
  const showAlert = useAlert();
  const [checked, setChecked] = React.useState([]);
  const [members, set_members] = React.useState(team);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);

    newChecked.map((index) => {
      selectedMembers[index] = members[index];
    });
  };

  return (
    <List dense className={classes.root}>
      {members[0]
        ? members.map((m, index) => {
            const labelId = `checkbox-list-secondary-label-${index}`;
            return (
              <ListItem
                button
                key={`${m.id}-${index}`}
                onClick={handleToggle(index)}
              >
                <ListItemAvatar key={`${m.id}-${index}`}>
                  <Avatar
                    key={`${m.id}-${index}`}
                    variant="square"
                    className={classes.avatar}
                    alt={`Avatar n°${index + 1}`}
                    src={m.avatar}
                  />
                </ListItemAvatar>
                <ListItemText
                  key={`${m.id}-${index}`}
                  id={labelId}
                  primary={m.name}
                  primaryTypographyProps={{
                    display: "inline",
                  }}
                  // secondary={` #${m.discriminator}`}
                  secondaryTypographyProps={{
                    display: "inline",
                    variant: "caption",
                    style: { color: "#aaa" },
                  }}
                />
                <Checkbox
                  key={`${m.id}-${index}`}
                  edge="end"
                  checked={checked.indexOf(index) !== -1}
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItem>
            );
          })
        : times(defaultTo(team.length, 3), () => (
            <Skeleton height={50}></Skeleton>
          ))}
    </List>
  );
};

const ModalViewTeam = ({
  open = false,
  onClose,
  participants,
  participantsSelectedHandler,
  selectedParticipants,
}) => {
  const classes = useStyles();

  const onCloseModal = () => {
    onClose();
  };

  const selectedHandler = () => {
    const newArr = [];
    selectedMembers.forEach((element) => {
      newArr.push(element);
    });
    participantsSelectedHandler(newArr);
    onClose();
  };
  let selectedMembers = [];

  return (
    <Dialog fullWidth open={open} onClose={onCloseModal}>
      <DialogTitle>Participants</DialogTitle>
      <DialogContent>
        {/* Start MANAGE Team*/}
        {/* Start Kick Members */}
        {participants?.length > 0 ? (
          <KickMemberCheckboxList
            team={participants}
            selectedMembers={selectedMembers}
          />
        ) : (
          <Typography variant="subtitle2">There are no participants</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseModal} color="primary">
          Close
        </Button>
        <Button
          onClick={selectedHandler}
          color="primary"
          variant="contained"
          disabled={participants?.length < 1}
        >
          Add To Stage
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = {
  dispatchSetTeams: setTeams,
  dispatchRemoveOneMyTeam: removeOneMyTeam,
  dispatchLeaveTeam: leaveTeam,
};

const mapStateToProps = (state) => ({
  userId_redux: state.profile._id,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalViewTeam);
