import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en-US",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      "en-US": {
        translation: {
          tournaments: {
            publicTournamentList: {
              title: "Play",
              description:
                "We work on technologies to improve competitive gaming. We are in the pre-alpha stage.",
              upcoming: "Upcoming",
              upcomingEmptyText: "There aren't any upcoming tournaments.",
              past: "Past",
              pastEmptyText: "There aren't any past tournaments.",
            },
            userTournamentList: {
              title: "Tournaments",
            },
          },
          teams: {
            userTeamList: {
              title: "Teams",
            },
          },
          groups: {
            userGroupList: {
              title: "Groups",
            },
          },
          users: {
            profile: {
              title: "Profile",
              connections: "Connections",
              teams: "Teams",
              groups: "Groups",
            },
          },
          homepage: {
            welcome: "Do you know where you are?",
            login: "Login",
          },
          components: {
            footer: {
              upcoming: "Upcoming",
              profile: "Profile",
              explore: "Explore",
              home: "Home",
              assets: "Assets & Branding",
              tournaments: "Tournaments",
              part1: "We are in the pre-alpha stage.",
              part2:
                "This means that we are still deciding on what features our system should have at this status.",
              contact: "For All Inquiries: ",
              contactEmail: "contact@lobium.com",
              part3:
                "As Lobium Alpha team, we provide tournaments across selective competitive games and work on technologies to improve competitive gaming.",
              copyright: "Lobium © 2020.",
            },
            navbar: {
              login: "Login",
            },
          },
        },
      },
      "tr-TR": {
        translation: {
          tournaments: {
            publicTournamentList: {
              title: "Oyna",
              description:
                "Rekabetçi oyunlar için teknolojiler geliştiriyoruz. Pre-alpha aşamasındayız.",
              upcoming: "Yaklaşan",
              upcomingEmptyText: "Yaklaşan herhangi bir turnuva yok.",
              past: "Geçmiş",
              pastEmptyText: "Geçmiş Turnuva yok.",
            },
            userTournamentList: {
              title: "Turnuvalar",
            },
          },
          teams: {
            userTeamList: {
              title: "Takımlar",
            },
          },
          groups: {
            userGroupList: {
              title: "Gruplar",
            },
          },
          users: {
            profile: {
              title: "Profil",
              connections: "Bağlantılar",
              teams: "Takımlar",
              groups: "Gruplar",
            },
          },
          homepage: {
            welcome: "Nerede olduğunu biliyor musun?",
            login: "Giriş",
          },
          components: {
            footer: {
              upcoming: "Yaklaşan",
              profile: "Profil",
              explore: "Keşfet",
              home: "Ana Sayfa",
              assets: "Markalaşma",
              tournaments: "Turnuvalar",
              part1: "Pre-alpha aşamasındayız.",
              part2:
                "Bu, sistemimizin hangi özelliklere sahip olması gerektiğine hala karar verdiğimiz anlamına geliyor.",
              contact: "İletişim için: ",
              contactEmail: "contact@lobium.com",
              part3:
                "Lobium Alpha ekibi olarak, seçkin rekabetçi oyunlarda turnuvalar düzenliyoruz ve rekabetçi oyunları geliştirmek için teknolojiler üzerinde çalışıyoruz.",
              copyright: "Lobium © 2020.",
            },
            navbar: {
              login: "Giriş",
            },
          },
        },
      },
    },
  });

export default i18n;
