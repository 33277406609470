import React from "react";

import { connect, useSelector } from "react-redux";
import { fetchPublicTournaments } from "../../redux/actions";
import ClassicTable from "../../components/independent/ClassicTable";

import { useHistory } from "react-router-dom";
import { indexes } from "..";
import { useSidebarIndex } from "../../context/SidebarIndexContext";
import { publicTournamentsSelectors } from "../../redux/actions";
import { useAlert, alertSeverity } from "../../context/AlertContext";
import { Container, makeStyles, Paper, Typography } from "@material-ui/core";
import PageHeader from "../../components/independent/PageHeader";
import DateFnsAdapter from "@date-io/date-fns";
import PersonIcon from "@material-ui/icons/Person";
import GroupIcon from "@material-ui/icons/Group";
import { useTranslation } from "react-i18next";

const dateFns = new DateFnsAdapter();

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    margin: theme.spacing(2),
  },
  paper: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(0.75, 0),
    padding: theme.spacing(0.5, 0),
  },
  heroImage: {
    backgroundImage: "url(/background.gif)",
    display: "block",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    margin: "auto",
  },
  heroCard: {
    backgroundColor: "#2a3439",
    color: "#f8f4ff",
    height: "200px",
    width: "200px",
    margin: "auto",
    border: "3px solid #7df9ff",
    "&:hover": {
      cursor: "pointer",
      border: "3px solid #ffe302",
    },
  },
  heroBot: {
    height: "100px",
  },
  heroTitle: {
    fontSize: "48px",
  },
  stadiumImage: {
    height: "410px",
  },
}));

const columns = [
  {
    id: "isTeamTournament",
    label: "",
    maxWidth: 100,
    format: (value) =>
      value ? (
        <GroupIcon
          color="action"
          fontSize="small"
          style={{ verticalAlign: "middle" }}
        />
      ) : (
        <PersonIcon
          color="action"
          fontSize="small"
          style={{ verticalAlign: "middle" }}
        />
      ),
  },
  {
    id: "game",
    label: "Game",
    minWidth: 150,
    format: (value) => <b>{value}</b>,
  },
  {
    id: "name",
    label: "Name",
    align: "center",
    minWidth: 300,
    format: (value) => <b>{value}</b>,
  },
  {
    id: "startDate",
    label: "Start\u00a0Date",
    minWidth: 200,
    //align: "right",
    format: (value) => `${dateFns.format(new Date(value), "PPPPp (z)")}`,
  },
];

const PublicTournamentList = ({
  isTournamentsLoading_redux,
  tournamentUpcomingCount_redux,
  tournamentPastCount_redux,
  dispatchFetchPublicTournaments,
  tournamentCount_redux,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const showAlert = useAlert();
  const [sidebarIndex, set_sidebarIndex] = useSidebarIndex();
  const [isUpcomingLoading, set_isUpcomingLoading] = React.useState(true);
  const [isPastLoading, set_isPastLoading] = React.useState(true);
  set_sidebarIndex(indexes.play);

  const pastTournaments = useSelector(publicTournamentsSelectors.selectPasts);
  const upcomingTournaments = useSelector(
    publicTournamentsSelectors.selectUpcoming,
  );

  const redirectTo = React.useCallback(
    (path = "") => history.push(path),
    [history],
  );

  const fetchTournaments = (upcoming, pageIndex, count) => {
    if (upcoming === 1) set_isUpcomingLoading(true);
    else set_isPastLoading(true);

    return dispatchFetchPublicTournaments({
      upcoming,
      count,
      skip: pageIndex * count,
      sort: upcoming ? 1 : -1,
    })
      .then(() => {
        if (upcoming === 1) set_isUpcomingLoading(false);
        else set_isPastLoading(false);
      })
      .catch((err) => {
        showAlert(err.message, alertSeverity.error);
      });
  };

  React.useEffect(() => {
    fetchTournaments(1, 0, 10);
    fetchTournaments(0, 0, 10);
  }, []);

  const { t } = useTranslation();

  return (
    <section>
      <PageHeader title={t("tournaments.publicTournamentList.title")} />
      <Container disableGutters>
        <div className={classes.heroContainer}>
          <div className={classes.heroImage}>
            <img
              src={"/images/LA-TwoLine-Light.svg"}
              alt="Lobium"
              height="175px"
              style={{ marginBottom: "25px", marginTop: "60px" }}
            ></img>
            <Typography variant="body1" style={{ margin: "0px 20px" }}>
              {t("tournaments.publicTournamentList.description")}
            </Typography>
            <div className={classes.heroBot}></div>
          </div>
        </div>
        <Paper variant="outlined" className={classes.paper}>
          <Typography variant="h5" className={classes.title}>
            {t("tournaments.publicTournamentList.upcoming")}
          </Typography>
          <ClassicTable
            rows={[
              ...upcomingTournaments,
              ...new Array(
                tournamentUpcomingCount_redux - upcomingTournaments.length,
              ),
            ]}
            loading={isUpcomingLoading}
            columns={columns}
            rowsOnClick={(params) => redirectTo("/t/" + params._id)}
            emptyText={t("tournaments.publicTournamentList.upcomingEmptyText")}
            onPageChange={(page, count) => fetchTournaments(1, page, count)}
          />
        </Paper>
        <Paper variant="outlined" className={classes.paper}>
          <Typography variant="h5" className={classes.title}>
            {t("tournaments.publicTournamentList.past")}
          </Typography>
          <ClassicTable
            rows={[
              ...pastTournaments,
              ...new Array(tournamentPastCount_redux - pastTournaments.length),
            ]}
            loading={isPastLoading}
            columns={columns}
            rowsOnClick={(params) => redirectTo("/t/" + params._id)}
            emptyText={t("tournaments.publicTournamentList.pastEmptyText")}
            onPageChange={(page, count) => fetchTournaments(0, page, count)}
          />
        </Paper>
      </Container>
    </section>
  );
};

const mapStateToProps = (state) => ({
  tournamentCount_redux:
    state.publicTournaments.upcomingCount + state.publicTournaments.pastCount,
  tournamentUpcomingCount_redux: state.publicTournaments.upcomingCount,
  tournamentPastCount_redux: state.publicTournaments.pastCount,
  isTournamentsLoading_redux: state.publicTournaments.loading,
});

const mapDispatchToProps = {
  dispatchFetchPublicTournaments: fetchPublicTournaments,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PublicTournamentList);
