import React from "react";
import { Avatar, Button, Grid, makeStyles, TextField } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import EditIcon from "@material-ui/icons/Edit";

import { postRequest } from "../../../logic/requests";
import { URLs } from "../../../logic/constants";
import {
  teamsSelectors,
  removeOneMyTeam,
  upsertOneMyTeam,
} from "../../../redux/actions";
import { resizeImage } from "../../../logic/utils";

import { useAlert, alerts } from "../../../context/AlertContext";
import { connect, useSelector } from "react-redux";
import PopoverDialogButton from "../../../components/independent/PopoverDialogButton";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyle = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1, 0),
    },
  },
  teamNameContainer: {
    margin: theme.spacing(1, 0),
  },
  uploadContainer: {
    textAlign: "left",
    "& > *": {
      display: "inline-block",
    },
  },
  actionsContainer: {
    textAlign: "left",
    "& > *": {
      margin: theme.spacing(1, 0),
    },
  },
  input: {
    display: "none",
  },
  previewText: {
    display: "inline-block",
    fontSize: 11,
    margin: theme.spacing(1),
  },
  previewImage: {
    maxHeight: "64px",
    maxWidth: "64px",
  },
}));

const useUploadButtonStyles = makeStyles((theme) => ({
  root: {
    textAlign: "right",
    "& > *": {
      margin: theme.spacing(1, 0),
    },
  },
  input: {
    display: "none",
  },
}));

const UpdateTeam = ({
  id,
  onDeleteCallback = () => {},
  dispatchRemoveOneMyTeam,
  dispatchUpsertOneMyTeam,
}) => {
  const classes = useStyle();
  const showAlert = useAlert();

  const team = useSelector((state) => teamsSelectors.selectById(state, id));

  const [nameValue, set_nameValue] = React.useState(team.name);
  const [uploadedFileName, set_uploadedFileName] = React.useState("");
  const [avatarValue, set_avatarValue] = React.useState(team.avatar);

  const handleUpdateTeam = () => {
    postRequest(URLs.api.team.update, {
      teamId: team._id,
      teamName: nameValue,
      teamAvatar: avatarValue,
    })
      .then((res) => {
        if (res.status === 200) {
          dispatchUpsertOneMyTeam(res.data);
          showAlert(alerts.success.message, alerts.success.severity);
        } else console.error(res);
      })
      .catch((err) => {
        switch (err.status) {
          default:
            showAlert(err.data.error, alerts.error.severity);
        }
      });
  };

  const handleDeleteTeam = () => {
    postRequest(URLs.api.team.delete, {
      teamId: team._id,
    })
      .then((res) => {
        //console.log(res);
        if (res.status === 200) {
          showAlert(alerts.success.message, alerts.success.severity);
          dispatchRemoveOneMyTeam(team._id);
          onDeleteCallback();
        } else console.error(res);
      })
      .catch((err) => {
        switch (err.status) {
          default:
            showAlert(err.data.error, alerts.error.severity);
        }
      });
  };

  const UploadButton = () => {
    const classes = useUploadButtonStyles();

    const handleOnClick = (event) => {
      const file = event.target.files[0];

      if (file) {
        resizeImage({
          file: file,
          maxSize: 48,
        })
          .then((resizedImage) => {
            console.log("Resized image: ", resizedImage);
            set_uploadedFileName(file.name);
            set_avatarValue(resizedImage);
          })
          .catch((err) => {
            showAlert(err.data.error, alerts.error.severity);
            console.error(err);
          });
      }
    };

    return (
      <div className={classes.root}>
        <input
          accept="image/*"
          className={classes.input}
          id="ModalCreateTeam-contained-button-file"
          multiple
          type="file"
          onChange={(e) => handleOnClick(e)}
        />
        <label htmlFor="ModalCreateTeam-contained-button-file">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            component="span"
            startIcon={<CloudUploadIcon />}
          >
            Upload Avatar
          </Button>
        </label>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        spacing={2}
        alignItems="flex-end"
        className={classes.teamNameContainer}
      >
        <Grid item>
          <Avatar
            variant="rounded"
            className={classes.previewImage}
            src={avatarValue}
          >
            {nameValue[0] ? nameValue[0].toUpperCase() : " "}
          </Avatar>
        </Grid>
        <Grid item xs>
          <TextField
            required
            fullWidth
            id="name"
            label="Team Name"
            type="text"
            value={nameValue}
            onChange={(a) => set_nameValue(a.nativeEvent.target.value)}
          />
        </Grid>
        <Grid item />
      </Grid>

      <Grid container className={classes.actionsContainer}>
        <Grid item xs={4}>
          <UploadButton />
        </Grid>

        <Grid item xs={1}></Grid>
        <Grid item xs={7}>
          <p className={classes.previewText}>{uploadedFileName}</p>
        </Grid>

        <Grid item xs={4}>
          <PopoverDialogButton
            startIcon={<DeleteIcon />}
            colors="secondary"
            fullWidth
            onClick={handleDeleteTeam}
          >
            Delete Team
          </PopoverDialogButton>
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={7}>
          <Button
            onClick={handleUpdateTeam}
            variant="contained"
            color="primary"
            fullWidth
            startIcon={<EditIcon />}
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

const mapDispatchToProps = {
  dispatchRemoveOneMyTeam: removeOneMyTeam,
  dispatchUpsertOneMyTeam: upsertOneMyTeam,
};

const mapStateToProps = (state) => ({
  //
});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateTeam);
