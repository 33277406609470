import React from "react";
import clsx from "clsx";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Drawer, useTheme, useMediaQuery } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ImageMediaCard from "../independent/ImageMediaCard";
import { isEmpty } from "lodash";
import NavMenuList from "./NavMenuList";

const drawerWidth = 190;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7) + 1,
    },
  },
  contentSpacing: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    paddingRight: 4,
    minHeight: 51, // necessary for content to be below app bar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

let userWantsDrawerOpen = true;

const Sidebar = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isScreenSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
    userWantsDrawerOpen = true;
  };

  const handleDrawerClose = () => {
    setOpen(false);
    userWantsDrawerOpen = false;
  };

  React.useEffect(() => {
    if (isScreenSmall) {
      setOpen(false);
    } else if (userWantsDrawerOpen) {
      setOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScreenSmall]);

  const profileImage = !(
    isEmpty(props.profile.discordId) && isEmpty(props.profile.avatar)
  )
    ? "https://cdn.discordapp.com/avatars/" +
      props.profile.discordId +
      "/" +
      props.profile.avatar
    : null;

  return (
    <section className={classes.root}>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <Divider className={classes.divider} />
        <div className={classes.contentSpacing} />
        {/* For avoiding overlapping with navbar */}
        <div className={classes.contentSpacing}>
          {/* Close / Open Sidebar Button */}
          <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <ImageMediaCard
          square
          title={props.profile.username}
          alt={props.profile.discriminator && "#" + props.profile.discriminator}
          image={profileImage}
          hideCardContent={!open}
        />
        <NavMenuList disableTooltips={open} />
      </Drawer>
    </section>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps)(Sidebar);
