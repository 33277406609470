import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import GradeIcon from "@material-ui/icons/Grade";
import { Skeleton } from "@material-ui/lab";
import { defaultTo, times } from "lodash";
import React from "react";
import { connect } from "react-redux";
import UserSearch from "../../../components/dependent/UserSearch";
import PopoverDialogButton from "../../../components/independent/PopoverDialogButton";
import { alerts, useAlert } from "../../../context/AlertContext";
import { URLs } from "../../../logic/constants";
import { getRequest, postRequest } from "../../../logic/requests";
import { removeOneMyTeam, setTeams } from "../../../redux/actions";
import { leaveTeam } from "../../../redux/teamsSlice";
import UpdateTeam from "./UpdateTeam";

const useStyles = makeStyles((theme) => ({
  actionsContainer: {
    "& > *": {
      margin: theme.spacing(0, 1),
    },
  },
  teamAvatar: {
    display: "flex",
  },
  modalHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  modalTitle: {
    display: "flex",
  },
  panelButton: {
    textAlign: "right",
    margin: theme.spacing(1, 0),
  },
  selectedMainTab: {
    background: "#0f0 !important",
  },
}));

const TabPanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};
const MemberList = ({ team }) => {
  const classes = useStyles();
  const showAlert = useAlert();
  const [members, set_members] = React.useState([]);

  React.useEffect(() => {
    getRequest(URLs.api.team.getMembers, {
      teamId: team._id,
    })
      .then((res) => {
        if (Array.isArray(res.data)) {
          //Mark the unkickable ones
          res.data.map((m) => (m.isKickable = team.captain !== m.id));
          set_members(res.data);
        }
      })
      .catch((err) =>
        showAlert("Error retrieving members!", alerts.error.severity),
      );
  }, []);

  return (
    <List dense className={classes.root}>
      <Typography variant="h6" gutterBottom style={{ textAlign: "center" }}>
        Members
      </Typography>
      {members[0]
        ? members.map((m, index) => {
            return (
              <ListItem key={m} selected={!m.isKickable}>
                <ListItemAvatar>
                  <Avatar
                    variant="square"
                    className={classes.avatar}
                    alt={`Avatar n°${index + 1}`}
                    src={m.avatar}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={m.username}
                  primaryTypographyProps={{
                    display: "inline",
                  }}
                  secondary={` #${m.discriminator}`}
                  secondaryTypographyProps={{
                    display: "inline",
                    variant: "caption",
                    style: { color: "#aaa" },
                  }}
                />

                {!m.isKickable && (
                  <GradeIcon style={{ verticalAlign: "middle" }} />
                )}
              </ListItem>
            );
          })
        : times(defaultTo(team.members.length, 3), () => (
            <Skeleton height={50}></Skeleton>
          ))}
    </List>
  );
};

const KickMemberCheckboxList = ({ team, selectedMembers = [] }) => {
  const classes = useStyles();
  const showAlert = useAlert();
  const [checked, setChecked] = React.useState([]);
  const [members, set_members] = React.useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);

    newChecked.map((index) => {
      selectedMembers[index] = members[index].id;
    });

    //console.log("selected memb,", selectedMembers);
    //console.log("checked", newChecked);
  };

  React.useEffect(() => {
    getRequest(URLs.api.team.getMembers, {
      teamId: team._id,
    })
      .then((res) => {
        if (Array.isArray(res.data)) {
          //Mark the unkickable ones
          res.data.map((m) => (m.isKickable = team.captain !== m.id));
          set_members(res.data);
        }
      })
      .catch((err) =>
        showAlert("Error retrieving members!", alerts.error.severity),
      );
  }, []);

  return (
    <List dense className={classes.root}>
      {members[0]
        ? members.map((m, index) => {
            const labelId = `checkbox-list-secondary-label-${index}`;
            return (
              <ListItem
                button
                key={m}
                onClick={handleToggle(index)}
                disabled={!m.isKickable}
              >
                <ListItemAvatar>
                  <Avatar
                    variant="square"
                    className={classes.avatar}
                    alt={`Avatar n°${index + 1}`}
                    src={m.avatar}
                  />
                </ListItemAvatar>
                <ListItemText
                  id={labelId}
                  primary={m.username}
                  primaryTypographyProps={{
                    display: "inline",
                  }}
                  secondary={` #${m.discriminator}`}
                  secondaryTypographyProps={{
                    display: "inline",
                    variant: "caption",
                    style: { color: "#aaa" },
                  }}
                />
                <Checkbox
                  edge="end"
                  checked={checked.indexOf(index) !== -1}
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItem>
            );
          })
        : times(defaultTo(team.members.length, 3), () => (
            <Skeleton height={50}></Skeleton>
          ))}
    </List>
  );
};

const ModalViewTeam = ({
  open = false,
  onClose,
  team,
  dispatchRemoveOneMyTeam,
  userId_redux,
  dispatchLeaveTeam,
  redux_setMembers, //TODO use for kicking
}) => {
  const classes = useStyles();
  const showAlert = useAlert();
  const [inviteCompleteList, setInviteCompleteList] = React.useState([]);
  const [selectedForInvitation, setSelectedForInvitation] = React.useState({});
  const [tabModalIndex, set_tabModalIndex] = React.useState(0);
  const [tabKickIndex, set_tabKickIndex] = React.useState(0);
  const [tabAddIndex, set_tabAddIndex] = React.useState(0);
  const [isUserTeamCaptain, set_isUserTeamCaptain] = React.useState(false);
  const [isUserMember, set_isUserMember] = React.useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  React.useEffect(() => {
    if (team) {
      if (team.members.find((o) => o._id === userId_redux)) {
        set_isUserTeamCaptain(userId_redux === team.captain);
        set_isUserMember(true);
      } else {
        set_isUserMember(false);
      }
    }
  }, [team, userId_redux]);

  let selectedMembers = [];

  const onCloseModal = () => {
    selectedMembers = [];

    set_tabModalIndex(0);
    set_tabKickIndex(0);
    set_tabAddIndex(0);

    onClose();
  };

  const handleLeaveTeam = async () => {
    await dispatchLeaveTeam(team._id);
    onCloseModal();
  };

  const handlers = {
    sendInvitation: () => {
      const params = {
        teamId: team._id,
        username: selectedForInvitation.username,
      };
      //console.log(selectedForInvitation);
      postRequest(URLs.api.team.invite, params)
        .then((res) => {
          showAlert(alerts.success.message, alerts.success.severity);
        })
        .catch((err) => {
          showAlert(err.data.error, alerts.error.severity);
        });
    },
    onUserSearchSelected: (value) => {
      setSelectedForInvitation(value);
    },
    kickPlayers: (selectedMembers) => {
      const kick = (userId, teamId) => {
        const params = {
          teamId: teamId,
          userId: userId,
        };

        //console.log("tid", teamId);
        //console.log("uid", userId);

        postRequest(URLs.api.team.kickMember, params)
          .then((res) => {
            showAlert(alerts.success.message, alerts.success.severity);
          })
          .catch((err) => {
            showAlert(err.data.error, alerts.error.severity);
          });
      };

      selectedMembers.map((m) => kick(m, team._id));
    },
  };

  //Return if there isn't a team
  if (!team) return <></>;

  return (
    <Dialog fullWidth fullScreen={isMobile} open={open} onClose={onCloseModal}>
      {/* Start Header --- Team Management  */}
      <DialogTitle className={classes.modalHeader}>
        <Grid container spacing={1} alignItems="center">
          <Grid item className={classes.iconContainer}>
            <Avatar
              variant="rounded"
              alt={team.name}
              src={team.avatar}
              className={classes.teamAvatar}
            />
          </Grid>
          <Grid item>
            <Typography
              variant="h5"
              color="inherit"
              className={classes.modalTitle}
            >
              {team.name} Overview
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      {/* End Header --- Team Management  */}

      <DialogContent>
        <Tabs
          variant="fullWidth"
          value={tabModalIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={(event, tab) => set_tabModalIndex(tab)}
        >
          <Tab label={"View"} />
          {isUserTeamCaptain && (
            <Tab label={"Manage"} disabled={!isUserTeamCaptain} />
          )}
          {isUserTeamCaptain && (
            <Tab label={"Update"} disabled={!isUserTeamCaptain} />
          )}
        </Tabs>

        {/* Start VIEW Team */}
        <TabPanel
          value={tabModalIndex}
          index={0}
          style={{ textAlign: "center" }}
        >
          <MemberList team={team} />
          {!isUserTeamCaptain && isUserMember && (
            <PopoverDialogButton
              startIcon={<ExitToAppIcon />}
              colors="secondary"
              onClick={handleLeaveTeam}
              disabled={!isUserMember || isUserTeamCaptain}
            >
              Leave
            </PopoverDialogButton>
          )}
        </TabPanel>
        {/* End VIEW Team */}

        {/* Start MANAGE Team*/}
        <TabPanel value={tabModalIndex} index={1}>
          {/* Start Kick Members */}
          <Tabs
            value={tabKickIndex}
            indicatorColor="_"
            onChange={(event, tab) => set_tabKickIndex(tab)}
          >
            <Tab label={"Members"} />
          </Tabs>
          <TabPanel value={tabKickIndex} index={0}>
            <KickMemberCheckboxList
              team={team}
              selectedMembers={selectedMembers}
            />
            <div className={classes.panelButton}>
              <Button
                onClick={() => handlers.kickPlayers(selectedMembers)}
                color="secondary"
                variant="contained"
              >
                Kick
              </Button>
            </div>
          </TabPanel>
          {/* End Kick Members */}

          {/* Start Add Member */}
          <Tabs
            value={tabAddIndex}
            indicatorColor="_"
            onChange={(event, tab) => set_tabAddIndex(tab)}
          >
            <Tab label={"Invite"} />
          </Tabs>
          <TabPanel value={tabAddIndex} index={0}>
            <UserSearch onSelected={handlers.onUserSearchSelected} />
            <div className={classes.panelButton}>
              <Button
                onClick={handlers.sendInvitation}
                color="primary"
                variant="contained"
              >
                Invite
              </Button>
            </div>
          </TabPanel>
          {/* End Add Member */}
        </TabPanel>
        {/* End MANAGE Team*/}

        {/* Start UPDATE Team*/}
        <TabPanel value={tabModalIndex} index={2}>
          <UpdateTeam
            onDeleteCallback={onCloseModal}
            team={team}
            showAlert={showAlert}
          />
        </TabPanel>
        {/* End UPDATE Team*/}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseModal} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = {
  dispatchSetTeams: setTeams,
  dispatchRemoveOneMyTeam: removeOneMyTeam,
  dispatchLeaveTeam: leaveTeam,
};

const mapStateToProps = (state) => ({
  userId_redux: state.profile._id,
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalViewTeam);
