import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { getRequest, postRequest } from "../logic/requests";
import { URLs } from "../logic/constants";

export const fetchMyTournaments = createAsyncThunk(
  "tournaments/fetchMyTournaments",
  async (parameters, { rejectWithValue }) =>
    await getRequest(URLs.api.tournamentList.getMy, parameters, {
      withCredentials: true,
    })
      .then((response) => {
        return response.data;
      })
      .catch(({ data }) => {
        return rejectWithValue(data);
      }),
);

export const fetchCreateTournament = createAsyncThunk(
  "tournaments/fetchCreateTournament",
  async (parameters, { rejectWithValue }) =>
    await postRequest(URLs.api.tournament.create, parameters, {
      withCredentials: true,
    })
      .then(({ data }) => {
        return data;
      })
      .catch(({ data }) => {
        return rejectWithValue(data);
      }),
);

export const fetchUpdateTournament = createAsyncThunk(
  "tournaments/fetchUpdateTournament",
  async (parameters, { rejectWithValue }) =>
    await postRequest(URLs.api.tournament.update, parameters, {
      withCredentials: true,
    })
      .then(({ data }) => {
        return data;
      })
      .catch(({ data }) => {
        return rejectWithValue(data);
      }),
);

export const fetchOneTournament = createAsyncThunk(
  "tournaments/fetchOneTournament",
  async (tournamentId, { rejectWithValue }) =>
    await getRequest(
      URLs.api.tournament.get,
      { tournamentId },
      { withCredentials: true },
    )
      .then(({ data }) => {
        return data;
      })
      .catch(({ data }) => {
        return rejectWithValue(data);
      }),
);

const tournamentsAdapter = createEntityAdapter({
  selectId: (tournament) => tournament._id,
  sortComparer: (a, b) => b.startDate.localeCompare(a.startDate),
});

const tournamentsSlice = createSlice({
  name: "tournaments",
  initialState: tournamentsAdapter.getInitialState({
    loading: false,
    error: null,
    count: 0,
  }),
  reducers: {
    setMyTournaments: tournamentsAdapter.setAll,
    addMyTournaments: tournamentsAdapter.addMany,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyTournaments.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMyTournaments.fulfilled, (state, action) => {
        tournamentsAdapter.upsertMany(state, action.payload.tournaments);
        state.count = action.payload.count;
        state.loading = false;
        state.error = null;
        return state;
      })
      .addCase(fetchMyTournaments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        throw action.payload;
      })
      .addCase(fetchCreateTournament.fulfilled, (state, action) => {
        tournamentsAdapter.addOne(state, action.payload);
      })
      .addCase(fetchOneTournament.fulfilled, (state, action) => {
        tournamentsAdapter.upsertOne(state, action.payload);
      })
      .addCase(fetchUpdateTournament.fulfilled, (state, action) => {
        tournamentsAdapter.updateOne(state, action.payload);
      })
      .addMatcher(
        (action) =>
          action.type.startsWith("tournaments/") &&
          action.type.endsWith("/rejected"),
        (state, action) => {
          throw action.payload;
        },
      );
  },
});

export const { setMyTournaments, addMyTournaments } = tournamentsSlice.actions;

export const tournamentsSelectors = tournamentsAdapter.getSelectors(
  (state) => state.tournaments,
);

export default tournamentsSlice.reducer;
