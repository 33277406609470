import React from "react";
import ReactDOM from "react-dom";
import Main from "./Main";
import reportWebVitals from "./post/reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/_store";
import { AlertProvider } from "./context/AlertContext";
import { SidebarIndexProvider } from "./context/SidebarIndexContext";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import "./index.css";
import "./i18n";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SidebarIndexProvider>
          <AlertProvider>
            <CssBaseline />
            <Main />
          </AlertProvider>
        </SidebarIndexProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
