import React from "react";

const ROUTE_LIST_CONTEXT = React.createContext();

export const USE_ROUTE_LIST = () => React.useContext(ROUTE_LIST_CONTEXT);

export const ROUTE_LIST_PROVIDER = ({ children }) => {
  const [ROUTE_LIST, SET_ROUTE_LIST] = React.useState([]);

  React.useEffect(() => {
    React.Children.only(children);

    const routeObjects = React.Children.toArray(children.props.children);
    const routes = routeObjects.reduce((routes, c) => {
      if (c.type.name === "Route" && c.props.path?.startsWith("/"))
        routes.push(c.props.path);
      return routes;
    }, []);

    SET_ROUTE_LIST(routes);
  }, []);

  return (
    <ROUTE_LIST_CONTEXT.Provider value={[ROUTE_LIST, SET_ROUTE_LIST]}>
      {children}
    </ROUTE_LIST_CONTEXT.Provider>
  );
};
