// Lobium-Organize / publicGroups - Initialized at Sunday, July 11, 2021 at 12:26. (Lobium Snippets Prefix: ll)
//
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { getRequest } from "../../logic/requests";
import { URLs } from "../../logic/constants";

export const fetchPublicGroups = createAsyncThunk(
  "publicGroups/fetchPublicGroups",
  async (groupId, { rejectWithValue }) =>
    await getRequest(URLs.api.group.get, { groupId }, { withCredentials: true })
      .then(({ data }) => {
        return data;
      })
      .catch(({ data }) => {
        return rejectWithValue(data);
      }),
);

const publicGroupsAdapter = createEntityAdapter({
  selectId: (object) => object._id,
});

const publicGroupsSlice = createSlice({
  name: "publicGroups",
  initialState: publicGroupsAdapter.getInitialState({
    loading: false,
    error: null,
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPublicGroups.fulfilled, (state, action) => {
        publicGroupsAdapter.upsertOne(state, action.payload);
      })
      .addMatcher(
        (action) => action.type.startsWith("publicGroups/"),
        (state, action) => {
          if (action.type.endsWith("/fulfilled")) {
            state.loading = false;
            state.error = null;
          } else if (action.type.endsWith("/pending")) {
            state.loading = true;
            state.error = null;
          } else if (action.type.endsWith("/rejected")) {
            state.loading = false;
            state.error = action.payload;
            throw action.payload;
          }
        },
      );
  },
});

export const {} = publicGroupsSlice.actions;

export const publicGroupsSelectors = {
  ...publicGroupsAdapter.getSelectors((state) => state.publicGroups),
  selectMemberPermission: (userList, memberId) => {
    if (Array.isArray(userList))
      for (let m of userList) {
        if (m.userId === memberId) return m.permission;
      }
    return null;
  },
};

export default publicGroupsSlice.reducer;
