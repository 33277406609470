import React from "react";

import { Bracket, Seed, SeedItem, SeedTeam } from "react-brackets";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import ButtonIcon from "@material-ui/icons/MoreVert";
import AddIcon from "@material-ui/icons/AddCircle";
import {
  Badge,
  Button,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popover,
  Typography,
} from "@material-ui/core";
import { getRequest, postRequest } from "../../logic/requests";
import { URLs } from "../../logic/constants";
import DragAndDrop from "../../components/DragAndDrop";
import ParticipantsModal from "../../components/FIXME/ParticipantsModal";
import PageHeader from "../../components/independent/PageHeader";
import PopoverDialogButton from "../../components/independent/PopoverDialogButton";

const findClosestPowerOfTwo = (number) => {
  const result = Math.log2(number);
  const isPowerOfTwo = Number.isInteger(result);
  if (isPowerOfTwo) return 2 ** result;
  return 2 ** Math.ceil(result);
};

//TODO Show if only logged in
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
    fontFamily: theme.typography.h6.fontFamily,
    fontVariant: theme.typography.h6.fontVariant,
    fontSize: theme.typography.h6.fontSize,
  },
  seed: {},
  seedItem: {
    boxShadow: theme.shadows[5],
  },
  seedTeamHome: {
    color: theme.palette.text.primary,
    background: "#242D31",
  },
  seedTeamAway: {
    color: theme.palette.text.primary,
    background: "#1F262A",
  },
  seedWinner: {
    color: theme.palette.text.primary,
    background: "#3F4E55",
  },
  seedLoser: {
    color: theme.palette.text.secondary,
    background: "#1F262A",
  },
  matchNo: {
    textAlign: "left",
    width: "100%",
    margin: 0,
    padding: 0,
    lineHeight: theme.typography.caption.lineHeight,
  },
  form: {
    input: {
      padding: 15,
    },
  },
  container: {
    padding: 15,
  },
  paper: {
    padding: theme.spacing(1),
    minHeight: "5vh",
  },
  button: {
    margin: theme.spacing(4, 0, 0, 0),
  },
}));

const deleteStage = (stageId, tournamentId) => {
  postRequest(URLs.api.stage.delete, {
    stageId,
  }).then(
    (res) => (window.location.href = "http://localhost:3001/t/" + tournamentId), //FIXME sarpstacus, proper location
  );
};

export default function CreateStage() {
  //States
  const classes = useStyles();
  const params = useParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [participants, setParticipants] = React.useState([]);
  const [selectedParticipants, setSelectedParticipants] = React.useState([]);
  const [rounds, setRounds] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const orderChangedHandler = (participants) => {
    setSelectedParticipants(participants);
    console.log(participants);
    let realSize = participants.length;
    let sizeWithNulls = findClosestPowerOfTwo(realSize);
    const numberOfRounds = Math.log2(sizeWithNulls);
    const matches = [];

    for (let roundWeAreIn = 0; roundWeAreIn < numberOfRounds; roundWeAreIn++) {
      matches.push({ title: "Round " + (roundWeAreIn + 1), seeds: [] });
    }
    let roundWeAreIn;
    for (roundWeAreIn = 0; roundWeAreIn < numberOfRounds; roundWeAreIn++) {
      for (let i = 0; i < 2 ** (numberOfRounds - roundWeAreIn - 1); i++) {
        if (roundWeAreIn === 0)
          matches[roundWeAreIn].seeds.push({
            id: Math.round(Math.random() * 10000),
            date: `${roundWeAreIn + 1}.${i + 1}`,
            teams: [
              { name: `${participants[i].name}` },
              {
                name:
                  sizeWithNulls - 1 - i >= realSize
                    ? "Bye"
                    : `${participants[sizeWithNulls - i - 1].name}`,
              },
            ],
          });
        else
          matches[roundWeAreIn].seeds.push({
            id: Math.round(Math.random() * 10000),
            date: `${roundWeAreIn + 1}.${i + 1}`,
            teams: [
              { name: `Winner Of Match ${roundWeAreIn}.${i * 2 + 1}` },
              { name: `Winner Of Match ${roundWeAreIn}.${i * 2 + 2}` },
            ],
          });
      }
    }
    for (let i = 0; i < matches[0].seeds.length / 2; i++)
      if (i % 2 === 0)
        [
          matches[0].seeds[i],
          matches[0].seeds[matches[0].seeds.length - 2 - i],
        ] = [
          matches[0].seeds[matches[0].seeds.length - 2 - i],
          matches[0].seeds[i],
        ];

    for (let i = 0; i < matches[0].seeds.length; i++) {
      matches[0].seeds[i].date = "1." + (i + 1);
    }

    setRounds(matches);
  };

  React.useEffect(() => {
    getRequest(URLs.api.tournament.getParticipants, {
      tournamentId: params.id,
    }).then(({ data }) => {
      if (Array.isArray(data)) {
        if (data[0].username) {
          //temp determine if teams
          const p = [];

          for (const r of data) {
            p.push({
              ...r,
              name: r.username,
              avatar:
                "https://cdn.discordapp.com/avatars/" +
                r.discordId +
                "/" +
                r.avatar,
            });
          }

          setParticipants(p);
        } else {
          setParticipants(data);
        }
      }
    });
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  // Custom Seed Bracket component
  const CustomSeed = (seed, breakpoint, roundIndex) => {
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
      <Seed mobileBreakpoint={breakpoint} className={classes.seed}>
        <Typography className={classes.matchNo} variant="overline">
          {seed.date}
        </Typography>
        <SeedItem className={classes.seedItem}>
          <SeedTeam
            className={
              seed.teams[1]?.name === "Bye"
                ? classes.seedWinner
                : classes.seedTeamHome
            }
          >
            <Typography variant="caption">
              {seed.teams[0]?.name || "NO TEAM "}
            </Typography>
            <Typography variant="button" color={"textPrimary"}></Typography>
          </SeedTeam>
          <SeedTeam
            className={
              seed.teams[1]?.name === "Bye"
                ? classes.seedLoser
                : classes.seedTeamAway
            }
          >
            <Typography variant="caption">
              {seed.teams[1]?.name || "NO TEAM "}
            </Typography>
            <Typography variant="button" color={"textPrimary"}></Typography>
          </SeedTeam>
        </SeedItem>
        <Typography className={classes.matchNo} variant="overline">
          <br />
        </Typography>
      </Seed>
    );
  };
  const extractIds = (selectedParticipants) => {
    let res = [];

    selectedParticipants.forEach((p) => {
      res.push(p._id);
    });
    return res;
  };

  const saveHandler = () => {
    console.log("isItHere", extractIds(selectedParticipants));
    postRequest(URLs.api.stage.updateMatches, {
      participantIds: extractIds(selectedParticipants),
      stageId: params.stageId,
    }).then(
      (res) => (window.location.href = "http://localhost:3001/t/" + params.id),
    );
  };

  const participantsSelectedHandler = (teams) => {
    setSelectedParticipants(teams);
    orderChangedHandler(teams);
    setOpen(false);
  };

  return (
    <section className={classes.root}>
      <PageHeader title="Stage Creator"></PageHeader>
      <br />
      <br />
      <Container disableGutters>
        <Grid container spacing={3}>
          <Grid item xs={"3"}>
            <Paper variant="outlined" className={classes.paper}>
              <Grid container justify="space-between">
                <IconButton onClick={handleClick}>
                  <Badge color="primary">
                    <ButtonIcon />
                  </Badge>
                </IconButton>
                <IconButton onClick={() => setIsModalOpen(true)}>
                  <Badge color="primary">
                    <AddIcon />
                  </Badge>
                </IconButton>
                <Popover
                  open={open}
                  anchorEl={anchorEl}
                  onClose={() => setOpen(false)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <List style={{ maxHeight: "35vh", overflow: "auto" }}>
                    {/*                   
                    <ListItem button onClick={() => setIsModalOpen(true)}>
                      <ListItemText
                        secondaryTypographyProps={{ variant: "caption" }}
                      >
                        <Typography variant="body1">
                          Change Participants
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  */}
                    <ListItem>
                      <ListItemText
                        secondaryTypographyProps={{ variant: "caption" }}
                      >
                        <PopoverDialogButton
                          fullWidth
                          colors="secondary"
                          variant="outlined"
                          onClick={() => deleteStage(params.stageId, params.id)}
                        >
                          Delete Stage
                        </PopoverDialogButton>
                      </ListItemText>
                    </ListItem>
                  </List>
                </Popover>
              </Grid>
              <br />
              {participants && (
                <DragAndDrop
                  participants={selectedParticipants}
                  orderChangedHandler={orderChangedHandler}
                />
              )}
              <Button
                fullWidth
                onClick={saveHandler}
                variant="contained"
                color="primary"
                disabled={selectedParticipants?.length < 1}
                className={classes.button}
              >
                Initialize Stage
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={"9"}>
            <Paper variant="outlined" className={classes.paper}>
              <Bracket rounds={rounds} renderSeedComponent={CustomSeed} />
            </Paper>
          </Grid>
        </Grid>
      </Container>
      <br />
      <ParticipantsModal
        participantsSelectedHandler={participantsSelectedHandler}
        open={isModalOpen}
        participants={participants}
        selectedParticipants={selectedParticipants}
        onClose={() => setIsModalOpen(false)}
      />
    </section>
  );
}
