// Lobium-Organize - Initialized at Sunday, July 11, 2021 at 16:44. (Lobium Snippets Prefix: ll)
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link as ReactLink } from "react-router-dom";
import { Link as MUILink } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const LobiumLink = ({ children, to, ...restArgs }) => {
  const classes = useStyles();

  return (
    <ReactLink
      to={to}
      component={MUILink}
      className={classes.root}
      {...restArgs}
    >
      {children}
    </ReactLink>
  );
};

export default LobiumLink;
