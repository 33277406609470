// Lobium-Organize - Initialized at Sunday, July 11, 2021 at 16:34. (Lobium Snippets Prefix: ll)
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PageHeader from "../../components/independent/PageHeader";
import {
  Container,
  List,
  ListItem,
  ListItemText,
  Paper,
} from "@material-ui/core";
import LobiumLink from "../../components/independent/LobiumLink";
import { USE_ROUTE_LIST } from "./contexts/ROUTE_LIST_CONTEXT";

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(1),
  },
}));

function ListItemLink({ to, children, ...restArgs }) {
  return (
    <LobiumLink to={to} {...restArgs}>
      <ListItem button>
        <ListItemText>{children}</ListItemText>
      </ListItem>
    </LobiumLink>
  );
}

const HOMEPAGE = ({ children }) => {
  const classes = useStyles();
  const [routes] = USE_ROUTE_LIST();

  return (
    <section className={classes.root}>
      <PageHeader title="_" />
      <Container disableGutters>
        <Paper variant="outlined" className={classes.paper}>
          <List component="nav">
            {routes.map((r, i) => (
              <ListItemLink key={r + i} to={r}>
                {r}
              </ListItemLink>
            ))}
          </List>
        </Paper>
      </Container>
    </section>
  );
};

export default HOMEPAGE;
