// Lobium-Organize - Initialized at Sunday, July 11, 2021 at 16:34. (Lobium Snippets Prefix: ll)
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PageHeader from "../../../components/independent/PageHeader";
import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(1),
  },
}));

const PAGE_BASE = ({ children, title = window.location.hash }) => {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <PageHeader title={title} subtitle="_" />
      <Container disableGutters>{children}</Container>
    </section>
  );
};

export default PAGE_BASE;
