import React from "react";

const SidebarIndexContext = React.createContext();

export const useSidebarIndex = () => React.useContext(SidebarIndexContext);

export const SidebarIndexProvider = ({ children }) => {
  const [sidebarIndex, set_sidebarIndex] = React.useState(-1);

  return (
    <SidebarIndexContext.Provider value={[sidebarIndex, set_sidebarIndex]}>
      {children}
    </SidebarIndexContext.Provider>
  );
};
