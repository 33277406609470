import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { getRequest, postRequest } from "../logic/requests";
import { URLs } from "../logic/constants";
import { addOneMyGroup } from "./groupsSlice";

export const fetchMyGroupInvitations = createAsyncThunk(
  "groupInvitations/fetchMyGroupInvitations",
  async () =>
    await getRequest(URLs.api.group.getMyInvitations, { withCredentials: true })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.error("My Group Invitations " + err);
        return {};
      }),
);

export const acceptGroupInvitation = createAsyncThunk(
  "groupInvitations/acceptGroupInvitation",
  async (groupId, thunkApi) =>
  await postRequest(URLs.api.group.accept, { groupId })
  .then((res) => {
    thunkApi.dispatch(addOneMyGroup(res.data))
    return groupId;
  })
  .catch((err) => {
    return {}
  })
);

export const rejectGroupInvitation = createAsyncThunk(
  "groupInvitations/rejectGroupInvitation",
  async (groupId) =>
    await postRequest(URLs.api.group.reject, { groupId })
    .then((res) => {
      return groupId;
    })
    .catch((err) => {
      return {}
  })
);

const groupInvitationsAdapter = createEntityAdapter({
  selectId: (group) => group.id,
});

const groupInvitationsSlice = createSlice({
  name: "groupInvitations",
  initialState: groupInvitationsAdapter.getInitialState({
    loading: false,
    error: null,
  }),
  reducers: {
    setMyGroupInvitations: groupInvitationsAdapter.setAll,
    addOneMyGroupInvitation: groupInvitationsAdapter.addOne,
    removeOneMyGroupInvitation: groupInvitationsAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyGroupInvitations.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMyGroupInvitations.fulfilled, (state, action) => {
        groupInvitationsAdapter.setAll(state, action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchMyGroupInvitations.rejected, (state, action) => {
        state.loading = false;
        state.error = `Couldn't get my group invitations!`;
        console.error(state.error);
      }).addCase(acceptGroupInvitation.fulfilled, (state, action) => {
        groupInvitationsAdapter.removeOne(state, action.payload);
      }).addCase(acceptGroupInvitation.rejected, (state, action) => {
        throw new Error("Could not accept invitation!");
      }).addCase(rejectGroupInvitation.fulfilled, (state, action) => {
        groupInvitationsAdapter.removeOne(state, action.payload);
      }).addCase(rejectGroupInvitation.rejected, (state, action) => {
        throw new Error("Could not remove invitation!");
      });
  },
});

export const {
  setMyGroupInvitations,
  addOneMyGroupInvitation,
  removeOneMyGroupInvitation,
} = groupInvitationsSlice.actions;

export const groupInvitationsSelectors = groupInvitationsAdapter.getSelectors(
  (state) => state.groupInvitations,
);

export default groupInvitationsSlice.reducer;
