import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import ClassicTable from "../../components/independent/ClassicTable";
import { useHistory } from "react-router-dom";
import { Button, Container } from "@material-ui/core";
import { formatDateString } from "../../logic/utils";
import { useAlert, alertSeverity } from "../../context/AlertContext";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { connect, useSelector } from "react-redux";
import { fetchMyTournaments, fetchMyGroups } from "../../redux/actions";
import { tournamentsSelectors } from "../../redux/tournamentsSlice";
import { groupSelectors, groupsSelectors } from "../../redux/groupsSlice";
import { useSidebarIndex } from "../../context/SidebarIndexContext";
import { indexes } from "..";
import PageHeader from "../../components/independent/PageHeader";
import DateFnsAdapter from "@date-io/date-fns";
import PersonIcon from "@material-ui/icons/Person";
import GroupIcon from "@material-ui/icons/Group";
import { useTranslation } from "react-i18next";
import PaperCard from "../../components/independent/PaperCard";

const dateFns = new DateFnsAdapter();

//TODO Show if only logged in
const useStyles = makeStyles((theme) => ({
  root: {},
  createButtons: {
    margin: "5px",
  },
}));

const columns = [
  {
    id: "isTeamTournament",
    label: "",
    maxWidth: 100,
    format: (value) =>
      value ? (
        <GroupIcon
          color="action"
          fontSize="small"
          style={{ verticalAlign: "middle" }}
        />
      ) : (
        <PersonIcon
          color="action"
          fontSize="small"
          style={{ verticalAlign: "middle" }}
        />
      ),
  },
  {
    id: "game",
    label: "Game",
    minWidth: 150,
    format: (value) => <b>{value}</b>,
  },
  {
    id: "name",
    label: "Name",
    align: "center",
    minWidth: 300,
    format: (value) => <b>{value}</b>,
  },
  { id: "groupName", label: "Group", minWidth: 125 },
  {
    id: "startDate",
    label: "Start\u00a0Date",
    minWidth: 100,
    //align: "right",
    format: (value) => `${dateFns.format(new Date(value), "PPPp")}`,
  },
];

const createData = (game, name, id, groupName, startDate) => {
  return { game, name, id, groupName, startDate };
};

const UserTournamentList = ({
  isLoading_redux,
  count_redux,
  dispatchFetchMyTournaments,
  dispatchFetchMyGroups,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const showAlert = useAlert();

  const [sidebarIndex, set_sidebarIndex] = useSidebarIndex();
  set_sidebarIndex(indexes.tournaments);

  const allTournaments = useSelector(tournamentsSelectors.selectAll);
  const groups = useSelector(groupsSelectors.selectAll);
  const [rows, setRows] = React.useState([]);

  const redirectTo = React.useCallback(
    (path = "") => history.push(path),
    [history],
  );

  React.useEffect(() => {
    dispatchFetchMyTournaments({ count: 10 }).catch((err) => {
      showAlert("Error: " + err, alertSeverity.error);
    });
    dispatchFetchMyGroups().catch((err) => {
      showAlert("Error: " + err, alertSeverity.error);
    });
  }, [dispatchFetchMyTournaments, dispatchFetchMyGroups]);

  React.useEffect(() => {
    if (groups) {
      setRows(
        allTournaments.map((t) =>
          createData(
            t.game,
            t.name,
            t._id,
            groups.find((g) => g._id === t.group)?.groupName,
            t.startDate,
          ),
        ),
      );
    }
  }, [allTournaments, groups]);

  const { t, i18n } = useTranslation();

  /*
  TODO fetch according to pagination,
  ex: fetch 20 first then when the next page is shown or the
  row count is increased fetch one more page or fil the rest
  */
  return (
    <section className={classes.root}>
      <PageHeader title={t("tournaments.userTournamentList.title")} />

      <Container disableGutters>
        <div>
          <Button
            className={classes.createButtons}
            onClick={() => redirectTo("/tournaments/create")}
            variant="contained"
            color="primary"
            size="large"
            startIcon={<AddBoxIcon />}
          >
            Create a Tournament
          </Button>
        </div>
        <br />
        <PaperCard>
          <ClassicTable
            loading={isLoading_redux}
            rows={[...rows, ...new Array(count_redux - rows.length)]}
            columns={columns}
            rowsOnClick={(params) => redirectTo("/t/" + params.id + "/edit")}
            onPageChange={(page, count) =>
              dispatchFetchMyTournaments({ count, skip: page * count }).catch(
                (err) => {
                  showAlert("Error: " + err, alertSeverity.error);
                },
              )
            }
          />
        </PaperCard>
      </Container>
    </section>
  );
};

const mapStateToProps = (state) => ({
  isLoading_redux: state.tournaments.loading,
  count_redux: state.tournaments.count,
});

const mapDispatchToProps = {
  dispatchFetchMyTournaments: fetchMyTournaments,
  dispatchFetchMyGroups: fetchMyGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTournamentList);
