import React from "react";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@material-ui/core";

const PermissionsCrossTable = ({ permissions, setState }) => {
  const handleChange = (event) => {
    const box = event.target.name.split("-");
    setState({
      ...permissions,
      [box[0]]: { ...permissions[box[0]], [box[1]]: event.target.checked },
    });
  };

  return (
    <div>
      <FormControl component="fieldset">
        <FormLabel component="legend">Group</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={permissions.group.create}
                onChange={handleChange}
                name="group-create"
              />
            }
            label="Create"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={permissions.group.read}
                onChange={handleChange}
                name="group-read"
              />
            }
            label="Read"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={permissions.group.update}
                onChange={handleChange}
                name="group-update"
              />
            }
            label="Update"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={permissions.group.delete}
                onChange={handleChange}
                name="group-delete"
              />
            }
            label="Delete"
          />
        </FormGroup>
      </FormControl>
      <FormControl component="fieldset">
        <FormLabel component="legend">Tournament</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={permissions.tournament.create}
                onChange={handleChange}
                name="tournament-create"
              />
            }
            label="Create"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={permissions.tournament.read}
                onChange={handleChange}
                name="tournament-read"
              />
            }
            label="Read"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={permissions.tournament.update}
                onChange={handleChange}
                name="tournament-update"
              />
            }
            label="Update"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={permissions.tournament.delete}
                onChange={handleChange}
                name="tournament-delete"
              />
            }
            label="Delete"
          />
        </FormGroup>
      </FormControl>
      <FormControl component="fieldset">
        <FormLabel component="legend">Match</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={permissions.match.create}
                onChange={handleChange}
                name="match-create"
              />
            }
            label="Create"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={permissions.match.read}
                onChange={handleChange}
                name="match-read"
              />
            }
            label="Read"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={permissions.match.update}
                onChange={handleChange}
                name="match-update"
              />
            }
            label="Update"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={permissions.match.delete}
                onChange={handleChange}
                name="match-delete"
              />
            }
            label="Delete"
          />
        </FormGroup>
      </FormControl>
    </div>
  );
};

export default PermissionsCrossTable;
