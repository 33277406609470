import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ClassicTable from "../../../components/independent/ClassicTable";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useHistory } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { Avatar } from "@material-ui/core";
import { groupsSelectors } from "../../../redux/actions";
import { roleDecider } from "../../../logic/utils";
import { publicGroupsSelectors } from "../../../redux/public/publicGroupsSlice";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const columns = [
  { id: "avatar", label: "" },
  { id: "name", label: "Name", minWidth: 200 },
  { id: "role", label: "Role", align: "center" },
  { id: "size", label: "Members", align: "center" },
  // { id: "id", label: "Team ID" },
  // {
  //   id: "actions",
  //   label: "Actions",
  //   minWidth: 100,
  //   align: "right",
  // },
];

const actionsColumn = {
  id: "actions",
  label: "Actions",
  minWidth: 100,
  align: "right",
};

const createData = (avatar, name, role, size, actions, index, id, userList) => {
  return { avatar, name, role, size, actions, index, id, userList };
};

const GroupsTable = ({
  size = "medium",
  userId,
  groupIds = [],
  myGroups = false,
  loading = false,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const redirectTo = React.useCallback(
    (path = "") => history.push(path),
    [history],
  );
  const groups = useSelector(
    myGroups ? groupsSelectors.selectAll : publicGroupsSelectors.selectAll,
  );

  const [groupsRows, set_groupsRows] = React.useState([]);

  React.useEffect(() => {
    if (Array.isArray(groups)) {
      const filteredGroups = myGroups
        ? groups
        : groups.filter((g) => groupIds.includes(g._id));

      const rows = filteredGroups.map((group, index) => {
        return createData(
          <Avatar
            variant="rounded"
            alt={group.groupName}
            src={group.groupAvatar}
          />,
          group.groupName,
          roleDecider(
            group.userList.find((o) => o.userId === userId)?.permission,
          ),
          group.userList?.length,
          <ChevronRightIcon />,
          index,
          group._id,
          group.userList,
        );
      });

      set_groupsRows(rows);
    }
  }, [groups, userId]);

  return (
    <ClassicTable
      rows={groupsRows}
      loading={loading}
      columns={myGroups ? [...columns, actionsColumn] : columns}
      size={size}
      rowsOnClick={
        myGroups
          ? (params) => redirectTo("/groups/" + params.id + "/edit")
          : undefined
      }
    />
  );
};

const mapStateToProps = (state) => ({
  //
});

const mapDispatchToProps = {
  //
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupsTable);
