// Lobium-Organize - Initialized at Monday, June 28, 2021 at 18:53. (Lobium Snippets Prefix: ll)
import {
  Container,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { connect, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { indexes } from "..";
import UserSearch from "../../components/dependent/UserSearch";
import PageHeader from "../../components/independent/PageHeader";
import PaperCard from "../../components/independent/PaperCard";
import RenderIcon from "../../components/independent/RenderIcon";
import { alertSeverity, useAlert } from "../../context/AlertContext";
import { useSidebarIndex } from "../../context/SidebarIndexContext";
import {
  fetchPublicGroups,
  fetchPublicProfile,
  fetchPublicTeams,
  publicProfilesSelectors,
} from "../../redux/actions";
import GroupsTable from "./components/GroupsTable";
import ProfileHeader from "./components/ProfileHeader";
import redirectSwitch from "./components/redirectSwitch";
import TeamsTable from "./components/TeamsTable";

const useStyles = makeStyles((theme) => ({
  root: {},
  listItem: {
    textAlign: "left-align",
  },
  listItemIcon: {
    marginRight: "-20px",
  },
  iconPaper: {
    padding: theme.spacing(0.25),
    alignItems: "center",
    display: "flex",
    backgroundColor: "#FFFAFA",
  },
}));

const PublicProfile = ({
  isTeamsLoading_redux,
  isGroupsLoading_redux,
  dispatchFetchPublicProfile,
  dispatchFetchPublicTeams,
  dispatchFetchPublicGroups,
}) => {
  const classes = useStyles();
  const { id } = useParams();
  const showAlert = useAlert();
  const profile = useSelector((state) =>
    publicProfilesSelectors.selectById(state, id),
  );

  // eslint-disable-next-line no-unused-vars
  const [sidebarIndex, set_sidebarIndex] = useSidebarIndex();

  const history = useHistory();

  const redirectTo = React.useCallback(
    (path = "") => history.push(path),
    [history],
  );
  set_sidebarIndex(indexes.profile);

  const pageError = React.useRef(null);

  React.useEffect(() => {
    dispatchFetchPublicProfile(id)
      .then(({ payload }) => {
        pageError.current = false;

        for (let i = 0; i < payload.teams?.length; i++)
          dispatchFetchPublicTeams(payload.teams[i]);

        for (let i = 0; i < payload.groups?.length; i++)
          dispatchFetchPublicGroups(payload.groups[i]);
      })
      .catch(({ error }) => {
        pageError.current = error;
        showAlert(error, alertSeverity.error);
      });
  }, [id]);

  const onSearchSelectedHandler = (selection) => {
    redirectTo("/profile/" + selection._id);
  };

  if (pageError.current)
    return (
      <React.Fragment className={classes.root}>
        <PageHeader title={"Profile"} subtitle={"Not found"} />
      </React.Fragment>
    );

  if (profile)
    return (
      <React.Fragment className={classes.root}>
        <PageHeader title="Profile" />
        <Container disableGutters>
          <ProfileHeader profile={profile}>
            <UserSearch onSelected={onSearchSelectedHandler} />
          </ProfileHeader>
          <Grid container spacing={2} alignItems="stretch">
            <Grid item xs={12} lg={6}>
              <PaperCard title="Teams">
                <TeamsTable
                  size="small"
                  loading={isTeamsLoading_redux}
                  teamIds={profile.teams}
                  userId={profile._id}
                />
              </PaperCard>
            </Grid>

            <Grid item xs={12} lg={6}>
              <PaperCard title="Groups">
                <GroupsTable
                  size="small"
                  loading={isGroupsLoading_redux}
                  groupIds={profile.groups}
                  userId={profile._id}
                />
              </PaperCard>
            </Grid>

            <Grid item xs lg={6}>
              <PaperCard title="Connections">
                <div>
                  <List>
                    {profile.connections && profile.connections.length > 0 ? (
                      profile.connections.map(
                        (conn, i) =>
                          !!conn.visibility && (
                            <Link target="_blank" href={redirectSwitch(conn)}>
                              <ListItem button>
                                <ListItemIcon className={classes.listItemIcon}>
                                  <Paper
                                    className={classes.iconPaper}
                                    elevation={3}
                                  >
                                    {RenderIcon(conn.type)}
                                  </Paper>
                                </ListItemIcon>
                                <ListItemText className={classes.listItem}>
                                  {conn.name}
                                  <Divider light />
                                </ListItemText>
                              </ListItem>
                            </Link>
                          ),
                      )
                    ) : (
                      <Typography>There aren't any connections.</Typography>
                    )}
                  </List>
                </div>
              </PaperCard>
            </Grid>
          </Grid>
        </Container>
      </React.Fragment>
    );

  return (
    <React.Fragment className={classes.root}>
      <PageHeader title={"Profile"} />
      <Container disableGutters>
        <LinearProgress />
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  //
  isTeamsLoading_redux: state.publicTeams.loading,
  isGroupsLoading_redux: state.publicGroups.loading,
});

const mapDispatchToProps = {
  dispatchFetchPublicProfile: fetchPublicProfile,
  dispatchFetchPublicTeams: fetchPublicTeams,
  dispatchFetchPublicGroups: fetchPublicGroups,
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicProfile);
