import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AddBoxIcon from "@material-ui/icons/AddBox";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MarkdownIt from "markdown-it";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import MdEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import "./components/md-editor.css";
import { connect, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import { indexes } from "..";
import PageHeader from "../../components/independent/PageHeader";
import PopoverDialogButton from "../../components/independent/PopoverDialogButton";
import { alertSeverity, useAlert } from "../../context/AlertContext";
import { useSidebarIndex } from "../../context/SidebarIndexContext";
import {
  fetchMyGroups,
  fetchOneTournament,
  fetchUpdateTournament,
  groupsSelectors,
  tournamentsSelectors,
} from "../../redux/actions";
import ModalMigrateTournament from "./components/ModalMigrateTournament";

const useStyles = makeStyles((theme) => ({
  root: { userSelect: "none" },

  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  form: {
    input: {
      padding: 15,
    },
  },
  description: {
    margin: theme.spacing(1, 0, 0, 0),
    textAlign: "left",
  },
  divider: {
    margin: theme.spacing(0, 0, 2, 2),
    padding: theme.spacing(0.2),
  },
  swipeable: {
    cursor: "grab",
    "&:active": {
      cursor: "grabbing",
    },
  },
  swipeableContainer: {
    minHeight: "50vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  stepper: {
    padding: theme.spacing(1),
  },
  step: {
    "&:hover": { cursor: "pointer" },
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  loadingWrapper: {
    position: "relative",
  },
  buttonContainer: {
    position: "sticky",
    bottom: "0px",
  },
}));

const getSteps = () => ["Details", "Description"];

const mdParser = new MarkdownIt(/* Markdown-it options */);

//https://date-fns.org/v2.21.1/docs/format
export const EditTournamentDetails = ({
  dispatchFetchOneTournament,
  dispatchFetchMyGroups,
  dispatchFetchUpdateTournament,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const showAlert = useAlert();
  const { id: tournamentId } = useParams();
  const { register, handleSubmit, control, errors } = useForm({});
  const [sidebarIndex, set_sidebarIndex] = useSidebarIndex();
  const groups = useSelector(groupsSelectors.selectAll);

  const [isTeamsEnabled, set_isTeamsEnabled] = React.useState(true);
  const [swipeableIndex, set_swipeableIndex] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [isMigrateModalOpen, set_isMigrateModalOpen] = React.useState(false);
  const [isLoading, set_isLoading] = React.useState(false);

  const steps = getSteps();
  const tournament = useSelector((state) =>
    tournamentsSelectors.selectById(state, tournamentId),
  );

  const redirectTo = React.useCallback(
    (path = "") => history.push(path),
    [history],
  );

  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);
  currentDate.setHours(19, 0, 0, 0);

  set_sidebarIndex(indexes.tournaments);

  React.useEffect(() => {
    dispatchFetchOneTournament(tournamentId).catch(({ error }) => {
      showAlert(error, alertSeverity.error);
      redirectTo("/tournaments");
    });
    dispatchFetchMyGroups();
  }, []);

  React.useEffect(() => {
    if (errors.name) {
      set_swipeableIndex(0);
    } else if (
      errors.game ||
      errors.minimumTeamSize ||
      errors.participantSizeLimit ||
      errors.group ||
      errors.startDate
    ) {
      set_swipeableIndex(1);
    }
  }, [errors]);

  const onSubmit = async (data) => {
    set_isLoading(() => true);
    const tournament = {
      ...data,
      startDate: new Date(data.startDate).toISOString(),
      tournamentId,
    };

    await dispatchFetchUpdateTournament(tournament)
      .then(({ payload }) => {
        showAlert(
          `Tournament "${payload.name}" has been updated.`,
          alertSeverity.success,
        );

        redirectTo(`/t/${payload._id}/edit`);
      })
      .catch(({ error }) => {
        showAlert(error, alertSeverity.error);
      });

    set_isLoading(() => false);
  };

  const handleIsTeamSwitch = (_event, value) => {
    set_isTeamsEnabled(() => value);
  };

  const isStepOptional = (step) => {
    return step === 2;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const textFieldProps = {
    fullWidth: true,
    variant: "outlined",
  };

  if (!tournament) return <LinearProgress />;

  return (
    <section className={classes.root}>
      <PageHeader
        title="Edit Tournament"
        subtitle={tournament.name + " (" + tournament.game + ")"}
      />
      <Container disableGutters className={classes.swipeableContainer}>
        <Paper variant="outlined" className={classes.paper}>
          <Stepper activeStep={swipeableIndex} className={classes.stepper}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                );
              }
              stepProps.completed = false;
              return (
                <Step
                  key={label}
                  {...stepProps}
                  onClick={() => set_swipeableIndex(index)}
                >
                  <StepLabel className={classes.step} {...labelProps}>
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Paper>
        <SwipeableViews
          //className={classes.swipeable}
          resistance
          hysteresis={0.4}
          index={swipeableIndex}
          onChangeIndex={set_swipeableIndex}
        >
          <Container disableGutters className={classes.swipeableContainer}>
            <Paper variant="outlined" className={classes.paper}>
              <Typography variant="h6" className={classes.description}>
                Name*
              </Typography>
              <Divider className={classes.divider} variant="inset" />
              <TextField
                {...textFieldProps}
                required
                name="name"
                defaultValue={tournament.name}
                label="Enter the tournament's name."
                inputRef={register({
                  required: "Please enter the tournaments name.",
                })}
              />
            </Paper>
            <Paper variant="outlined" className={classes.paper}>
              <Grid container spacing={2}>
                {/* GAME */}
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" className={classes.description}>
                    Game*
                  </Typography>
                  <Divider className={classes.divider} />
                  <TextField
                    {...textFieldProps}
                    required
                    name="game"
                    defaultValue={tournament.game}
                    label="Enter the game."
                    inputRef={register({ required: "Please enter the game." })}
                  />
                </Grid>
                {/* PLAYERS PER TEAM */}
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" className={classes.description}>
                    Players Per Team*
                  </Typography>
                  <Divider className={classes.divider} />
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <TextField
                        {...textFieldProps}
                        required={isTeamsEnabled}
                        disabled={!isTeamsEnabled}
                        type="number"
                        name="minimumTeamSize"
                        label="Enter players per team"
                        defaultValue={tournament.minimumTeamSize}
                        inputRef={register({
                          required: "Please enter the game.",
                        })}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControlLabel
                        disabled
                        inputRef={register}
                        checked={isTeamsEnabled}
                        name="isTeamTournament"
                        value={tournament.isTeamTournament}
                        onChange={handleIsTeamSwitch}
                        control={<Switch color="primary" />}
                        label={<Typography variant="button">Teams</Typography>}
                        labelPlacement="top"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {/* MAXIMUM PARTICIPANTS*/}
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" className={classes.description}>
                    {"Maximum " +
                      (isTeamsEnabled ? "Teams" : "Players") +
                      " Limit*"}
                  </Typography>
                  <Divider className={classes.divider} />
                  <TextField
                    {...textFieldProps}
                    required
                    type="number"
                    name="participantSizeLimit"
                    defaultValue={tournament.participantSizeLimit}
                    label={
                      "Enter maximum " +
                      (isTeamsEnabled ? "teams" : "players") +
                      " amount"
                    }
                    inputRef={register({
                      required: "Please enter maximum participants.",
                    })}
                  />
                </Grid>
                {/* GROUP */}
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" className={classes.description}>
                    Group*
                  </Typography>
                  <Divider className={classes.divider} />
                  <Button
                    fullWidth
                    style={{ height: "48%" }}
                    variant="outlined"
                    onClick={() =>
                      redirectTo(`/groups/${tournament?.group}/edit`)
                    }
                  >
                    Change Group
                  </Button>
                </Grid>
                {/* STARTING DATE */}
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.description}>
                    Start Date*
                  </Typography>
                  <Divider className={classes.divider} />
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Controller
                      control={control}
                      name="startDate"
                      defaultValue={tournament.startDate}
                      as={
                        <DateTimePicker
                          style={{ width: "100%" }}
                          required
                          inputVariant="outlined"
                          variant="inline"
                          format="iiii, dd MMMM yyyy - hh:mm a (O)"
                          id="date-picker-inline"
                          label="Pick the starting date"
                          PopoverProps={{
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "center",
                            },
                            transformOrigin: {
                              vertical: "bottom",
                              horizontal: "center",
                            },
                          }}
                          KeyboardButtonProps={{
                            "aria-label": "change time",
                          }}
                        />
                      }
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
            </Paper>
          </Container>
          <Container disableGutters className={classes.swipeableContainer}>
            <Paper variant="outlined" className={classes.paper}>
              <Grid container spacing={2}>
                {/* DESCRIPTION */}
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.description}>
                    Description
                  </Typography>
                  <Divider className={classes.divider} />
                  <Controller
                    name="description"
                    defaultValue={tournament.description}
                    control={control}
                    render={({ onChange, ref }) => (
                      <MdEditor
                        //ref={ref}
                        onChange={({ text = tournament.description, html }) =>
                          onChange(text)
                        }
                        style={{
                          height: "30vh",
                          minHeight: "128px",
                        }}
                        defaultValue={tournament.description}
                        className={classes.mdEditor}
                        placeholder={`Enter markdown text here...`}
                        renderHTML={(text) => mdParser.render(text)}
                      />
                    )}
                  />
                </Grid>
                {/* RULES */}
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.description}>
                    Rules
                  </Typography>
                  <Divider className={classes.divider} />
                  <TextField
                    {...textFieldProps}
                    multiline
                    name="rules"
                    defaultValue={tournament.rules}
                    label="Enter the tournament's rules."
                    inputRef={register}
                    rows={6}
                  />
                </Grid>
                {/* PRIZE */}
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.description}>
                    Prize
                  </Typography>
                  <Divider className={classes.divider} />
                  <TextField
                    {...textFieldProps}
                    multiline
                    name="prize"
                    defaultValue={tournament.prize}
                    label="Enter the tournament's prizes."
                    inputRef={register}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </SwipeableViews>
        <Container
          maxWidth="xs"
          disableGutters
          className={classes.buttonContainer}
        >
          <Paper elevation={3} className={classes.paper}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                {swipeableIndex !== steps.length - 1 ? (
                  <Button
                    fullWidth
                    size="large"
                    color="primary"
                    endIcon={<NavigateNextIcon />}
                    onClick={() =>
                      swipeableIndex !== steps.length - 1 &&
                      set_swipeableIndex((s) => s + 1)
                    }
                  >
                    Next
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    size="large"
                    color="primary"
                    startIcon={<NavigateBeforeIcon />}
                    onClick={() =>
                      swipeableIndex !== 0 && set_swipeableIndex((s) => s - 1)
                    }
                  >
                    Back
                  </Button>
                )}
              </Grid>
              <Grid item xs={6}>
                <div className={classes.loadingWrapper}>
                  <PopoverDialogButton
                    fullWidth
                    size="large"
                    variant="contained"
                    color="primary"
                    endIcon={<AddBoxIcon />}
                    disabled={isLoading}
                    onClick={handleSubmit(onSubmit)}
                  >
                    Save
                  </PopoverDialogButton>

                  {isLoading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </Container>
      {/* MODALS */}

      <ModalMigrateTournament
        open={isMigrateModalOpen}
        onClose={() => set_isMigrateModalOpen(false)}
        showAlert={showAlert}
      />
    </section>
  );
};

const mapDispatchToProps = {
  dispatchFetchMyGroups: fetchMyGroups,
  dispatchFetchUpdateTournament: fetchUpdateTournament,
  dispatchFetchOneTournament: fetchOneTournament,
};

const mapStateToProps = (state) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditTournamentDetails);
