import React from "react";

import ModalCreateTeam from "./components/ModalCreateTeam";
import ViewInvitations from "./components/ViewInvitations";
import { connect } from "react-redux";
import { fetchMyTeamInvitations, fetchMyTeams } from "../../redux/actions";
import { useSidebarIndex } from "../../context/SidebarIndexContext";
import { indexes } from "..";
import PageHeader from "../../components/independent/PageHeader";
import { Container } from "@material-ui/core";
import ViewTeamList from "./components//ViewTeamList";
import { useTranslation } from "react-i18next";
import PaperCard from "../../components/independent/PaperCard";

const UserTeamList = ({
  dispatchFetchMyTeams,
  dispatchFetchMyTeamInvitations,
}) => {
  const [sidebarIndex, set_sidebarIndex] = useSidebarIndex();
  set_sidebarIndex(indexes.teams);

  React.useEffect(() => {
    dispatchFetchMyTeams().catch((err) => {
      console.error("Err: ", err);
    });
  }, [dispatchFetchMyTeams]);

  React.useEffect(() => {
    dispatchFetchMyTeamInvitations().catch((err) => {
      console.error("Err: ", err);
    });
  }, [dispatchFetchMyTeamInvitations]);

  const { t } = useTranslation();

  return (
    <section>
      <PageHeader title={t("teams.userTeamList.title")} />
      <Container disableGutters>
        <ViewInvitations />
        <br />
        <br />
        <ModalCreateTeam />
        <br />
        <br />
        <PaperCard>
          <ViewTeamList />
        </PaperCard>
      </Container>
    </section>
  );
};

const mapStateToProps = (state) => ({
  //
});

const mapDispatchToProps = {
  dispatchFetchMyTeams: fetchMyTeams,
  dispatchFetchMyTeamInvitations: fetchMyTeamInvitations,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTeamList);
