import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  ListItem,
  makeStyles,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";

import { getRequest, postRequest } from "../../../logic/requests";
import { URLs } from "../../../logic/constants";

import { useAlert, alerts } from "../../../context/AlertContext";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import ReactHookFormSelect from "../../../components/independent/ReactHookFormSelect";
import GroupIcon from "@material-ui/icons/Group";
import PersonIcon from "@material-ui/icons/Person";
import { setMyGroups } from "../../../redux/actions";
import EditIcon from "@material-ui/icons/Edit";
import PopoverDialogButton from "../../../components/independent/PopoverDialogButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { defaultTo } from "lodash";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {},
  modalHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

const InputListItem = ({ label, value, onChange, ...props }) => {
  return (
    <ListItem>
      <TextField
        fullWidth
        variant="outlined"
        value={value}
        label={label}
        {...props}
      />
    </ListItem>
  );
};

//DEFAULT
const ModalEditTournamentInfo = ({
  open = false,
  onClose,
  tournament,
  redux_groups = [],
  redux_setGroups,
}) => {
  const classes = useStyles();
  const showAlert = useAlert();
  const history = useHistory();
  const onSubmit = (data) => {
    sendRequest(data);
  };
  const onCloseModal = () => {
    onClose();
  };
  const redirectTo = React.useCallback(
    (path, pre, post) => {
      pre = defaultTo(pre, "");
      path = defaultTo(path, "");
      post = defaultTo(post, "");

      return history.push(pre + path + post);
    },
    [history],
  );

  const handleDeleteTournament = () => {
    postRequest(URLs.api.tournament.delete, { tournamentId: tournament._id })
      .then((res) => {
        //console.log(res);
        if (res.status === 200) {
          showAlert(alerts.success.message, alerts.success.severity);
          redirectTo("/t");
        } else console.error(res);
      })
      .catch((err) => {
        switch (err.status) {
          default:
            showAlert(err.data.error, alerts.error.severity);
        }
      });
  };

  const sendRequest = ({
    name,
    description,
    prize,
    rules,
    game = "",
    group,
    participantSizeLimit,
    isTeamTournament,
  }) => {
    postRequest(URLs.api.tournament.update, {
      name,
      description,
      prize,
      rules,
      game,
      group,
      participantSizeLimit,
      isTeamTournament,
      tournamentId: tournament._id,
    })
      .then((res) => {
        showAlert(alerts.success.message, alerts.success.severity);
      })
      .catch((err) => {
        console.log(err);
        switch (err.status) {
          default:
            showAlert(err.data.error, alerts.error.severity);
        }
      });
  };

  const { register, handleSubmit, control, errors } = useForm();

  React.useEffect(() => {
    getRequest(URLs.api.group.getMy).then((res) => {
      redux_setGroups(res.data);
    });
  }, []);

  if (!(tournament && tournament.name)) return <></>;

  return (
    <Dialog fullWidth open={open} onClose={onCloseModal}>
      <FormControl
        fullWidth
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        className={classes.form}
      >
        <DialogTitle className={classes.modalHeader}>
          <Typography
            variant="h5"
            color="inherit"
            className={classes.modalTitle}
          >
            {tournament.name} Overview
          </Typography>
        </DialogTitle>
        <DialogContent>
          <InputListItem
            required
            label="Name"
            name="name"
            defaultValue={tournament.name}
            inputRef={register({ required: "Name is required." })}
          />
          <InputListItem
            label="Description"
            name="description"
            defaultValue={tournament.description}
            inputRef={register}
          />
          <InputListItem
            label="Prize"
            name="prize"
            defaultValue={tournament.prize}
            inputRef={register}
          />
          <InputListItem
            label="Rules"
            name="rules"
            defaultValue={tournament.rules}
            inputRef={register}
          />
          <ListItem>
            <ReactHookFormSelect
              fullWidth
              variant="outlined"
              className={classes.formControl}
              control={control}
              label="Game"
              name="game"
              defaultValue={tournament.game}
            >
              <MenuItem value={"Quake"}>Quake</MenuItem>
              <MenuItem value={"Half-Life"}>Half-Life</MenuItem>
              <MenuItem value={"Counter-Strike"}>Counter-Strike</MenuItem>
            </ReactHookFormSelect>
            <ReactHookFormSelect
              fullWidth
              variant="outlined"
              className={classes.formControl}
              control={control}
              label="Group"
              name="group"
              defaultValue={tournament.group}
            >
              {redux_groups.map((g, i) => (
                <MenuItem value={g._id}>{g.groupName}</MenuItem>
              ))}
            </ReactHookFormSelect>
          </ListItem>
          <ListItem>
            <TextField
              fullWidth
              required
              variant="outlined"
              label="Maximum Participants"
              name="participantSizeLimit"
              defaultValue={tournament.participantSizeLimit}
              inputRef={register}
            />
            <div style={{ textAlign: "center", width: "200px" }}>
              <Controller
                name="isTeamTournament"
                control={control}
                defaultValue={tournament.isTeamTournament}
                render={(props) => (
                  <Switch
                    onChange={(e) => props.onChange(e.target.checked)}
                    checked={props.value}
                    color="primary"
                    checkedIcon={<GroupIcon />}
                    icon={<PersonIcon style={{ color: "#56a" }} />}
                  />
                )}
              />
              <Typography variant="caption" style={{ display: "block" }}>
                Is Team Tournament
              </Typography>
            </div>
          </ListItem>
        </DialogContent>
        <DialogActions>
          <PopoverDialogButton
            colors="secondary"
            variant="outlined"
            size="small"
            endIcon={<DeleteIcon />}
            onClick={handleDeleteTournament}
            className={classes.button}
          >
            Delete
          </PopoverDialogButton>
          <Button
            color="primary"
            onClick={onCloseModal}
            className={classes.button}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            endIcon={<EditIcon />}
            className={classes.button}
          >
            Update
          </Button>
        </DialogActions>
      </FormControl>
    </Dialog>
  );
};

const mapDispatchToProps = {
  redux_setGroups: setMyGroups,
};

const mapStateToProps = (state) => ({
  redux_groups: state.groups.groups,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalEditTournamentInfo);
