// Lobium-Organize - Initialized at Saturday, July 03, 2021 at 19:39. (Lobium Snippets Prefix: ll)
import React from "react";
import { connect, useSelector } from "react-redux";
import {
  notificationsSelectors,
  fetchMarkNotificationsRead,
} from "../../redux/notificationsSlice";
import { useHistory } from "react-router";
import { formatDateString } from "../../logic/utils";
import GroupIcon from "@material-ui/icons/Group";
import SendIcon from "@material-ui/icons/Send";
import LineStyleIcon from "@material-ui/icons/LineStyle";
import Paper from "@material-ui/core/Paper";
import PageHeader from "../../components/independent/PageHeader";
import { CircularProgress, Container } from "@material-ui/core";

import {
  makeStyles,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  ListItemIcon,
} from "@material-ui/core";
import { useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const CategoryIconSwitch = ({ category, ...restArgs }) => {
  switch (category) {
    case "Team":
      return <GroupIcon fontSize="large" {...restArgs} />;
    case "Group":
      return <LineStyleIcon fontSize="large" {...restArgs} />;
    default:
      return <SendIcon color="disabled" fontSize="large" {...restArgs} />;
  }
};

let notificationsReadFlag = false;

const Notifications = ({
  dispatchFetchMarkNotificationsRead,
  notifications_redux,
}) => {
  const classes = useStyles();
  const notifications = useSelector(notificationsSelectors.selectAll);

  const [nState, set_nState] = React.useState([]);
  const unreadNotifications = useSelector(notificationsSelectors.selectUnread);
  const history = useHistory();
  const redirectTo = React.useCallback(
    (path = "") => history.push(path),
    [history],
  );

  const handleNotificationClick = (notificationCategory) => {
    handleNotificationsClose();
    switch (notificationCategory) {
      case "Team":
        redirectTo("/teams");
        return;
      case "Group":
        redirectTo("/groups");
        return;
      default:
        return;
    }
  };
  const handleNotificationsClose = () => {
    if (unreadNotifications.length > 0) dispatchFetchMarkNotificationsRead();
  };

  useEffect(() => {
    if (notifications.length > 0) {
      if (!notificationsReadFlag) {
        const nClone = [...notifications];
        notificationsReadFlag = true;
        dispatchFetchMarkNotificationsRead().then(() => {
          set_nState(nClone);
        });
      } else {
        notificationsReadFlag = false;
      }
    }
  }, [dispatchFetchMarkNotificationsRead, notifications]);

  useEffect(() => {
    return () => {
      notificationsReadFlag = false;
    };
  }, []);

  return (
    <React.Fragment className={classes.root}>
      <PageHeader title="Notifications" />
      <Container disableGutters>
        <Paper>
          <List>
            {nState.length > 0 ? (
              nState.map((notif, i) => (
                <>
                  <ListItem
                    button
                    onClick={() => handleNotificationClick(notif.category)}
                  >
                    <ListItemIcon>
                      <CategoryIconSwitch
                        category={notif.category}
                        color={!notif.read ? "primary" : ""}
                      />
                    </ListItemIcon>
                    <ListItemText
                      secondaryTypographyProps={{ variant: "caption" }}
                      secondary={formatDateString(notif.createdAt)}
                    >
                      <Typography variant="body2">
                        <Typography variant="subtitle2">
                          {notif.category}
                        </Typography>
                        {notif.message}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  {i < nState.length - 1 && <Divider dark variant="middle" />}
                </>
              ))
            ) : (
              <ListItem>
                <ListItemText>
                  {notifications_redux.loading ? (
                    <div style={{ textAlign: "center" }}>
                      <CircularProgress />
                    </div>
                  ) : (
                    <Typography variant="subtitle2" align="center">
                      {notifications_redux.error
                        ? JSON.stringify(notifications_redux.error, null, 2)
                        : "There are no notifications."}
                    </Typography>
                  )}
                </ListItemText>
              </ListItem>
            )}
          </List>
        </Paper>
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  notifications_redux: state.notifications,
});

const mapDispatchToProps = {
  dispatchFetchMarkNotificationsRead: fetchMarkNotificationsRead,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
