import React from "react";
import { connect, useSelector } from "react-redux";
import { Avatar, makeStyles } from "@material-ui/core";

import ClassicTable from "../../../components/independent/ClassicTable";

import { useAlert, alerts } from "../../../context/AlertContext";
import ModalViewTeam from "./ModalViewTeam";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { publicTeamsSelectors, teamsSelectors } from "../../../redux/actions";
import GradeIcon from "@material-ui/icons/Grade";

const useStyles = makeStyles((theme) => ({
  avatar: {
    borderRadius: 3,
  },
  actionsContainer: {
    "& > *": {
      margin: theme.spacing(0, 1),
    },
  },
}));

const columns = [
  { id: "avatar", label: "" },
  { id: "name", label: "Name", minWidth: 200 },
  { id: "size", label: "Members", align: "center" },
  {
    id: "captain",
    label: "",
    align: "right",
    format: (value) =>
      value?.captain === value?.userId ? (
        <GradeIcon style={{ verticalAlign: "middle" }} />
      ) : (
        ""
      ),
  },
  {
    id: "actions",
    label: "View",
    maxWidth: 75,
    align: "right",
  },
];

const createData = (
  avatar,
  name,
  size,
  { captain, userId },
  id,
  actions,
  index,
) => {
  return {
    avatar,
    name,
    size,
    captain: { captain, userId },
    id,
    actions,
    index,
  };
};

const TeamsTable = ({
  size = "medium",
  userId,
  teamIds = [],
  myTeams = false,
  loading = false,
}) => {
  const classes = useStyles();

  const showAlert = useAlert();
  const teams = useSelector(
    myTeams ? teamsSelectors.selectAll : publicTeamsSelectors.selectAll,
  );
  const [teamsRows, set_teamsRows] = React.useState([]);
  const [selectedTeam, set_selectedTeam] = React.useState(false);
  const [isModalOpen, set_isModalOpen] = React.useState(false);

  const onRowsClick = ({ id }) => {
    set_selectedTeam(teams.find((o) => o._id === id));
    set_isModalOpen(true);
  };

  React.useEffect(() => {
    if (Array.isArray(teams)) {
      const filteredTeams = myTeams
        ? teams
        : teams.filter((t) => teamIds.includes(t._id));

      const rows = filteredTeams.map((team, index) =>
        createData(
          <Avatar variant="rounded" alt={team.name} src={team.avatar} />,
          team.name,
          team.members.length,
          { captain: team.captain, userId: userId },
          team._id,
          <ChevronRightIcon />,
          index,
        ),
      );

      set_teamsRows(rows);
    }
  }, [teams, userId]);

  return (
    <>
      <ClassicTable
        loading={loading}
        size={size}
        rows={teamsRows}
        columns={columns}
        rowsOnClick={onRowsClick}
      />

      <ModalViewTeam
        team={selectedTeam}
        open={isModalOpen}
        onClose={() => set_isModalOpen(false)}
      />
    </>
  );
};

const mapDispatchToProps = {
  //
};

const mapStateToProps = (state) => ({
  //
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamsTable);
