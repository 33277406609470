import { Button, Container } from "@material-ui/core";
import AddBoxIcon from "@material-ui/icons/AddBox";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { indexes } from "..";
import PageHeader from "../../components/independent/PageHeader";
import PaperCard from "../../components/independent/PaperCard";
import { useAlert } from "../../context/AlertContext";
import { useSidebarIndex } from "../../context/SidebarIndexContext";
import { fetchMyGroupInvitations, fetchMyGroups } from "../../redux/actions";
import ViewGroupList from "./components/ViewGroupList";
import ViewInvitations from "./components/ViewInvitations";

const UserGroupList = ({
  dispatchFetchMyGroups,
  dispatchFetchMyGroupInvitations,
}) => {
  const showAlert = useAlert();
  const history = useHistory();
  const [sidebarIndex, set_sidebarIndex] = useSidebarIndex();

  set_sidebarIndex(indexes.groups);

  const redirectTo = React.useCallback(
    (path = "") => history.push(path),
    [history],
  );

  React.useEffect(() => {
    dispatchFetchMyGroups().catch((err) => {
      console.error("Err: ", err);
    });
  }, [dispatchFetchMyGroups]);

  React.useEffect(() => {
    dispatchFetchMyGroupInvitations().catch((err) => {
      console.error("Err: ", err);
    });
  }, [dispatchFetchMyGroupInvitations]);

  const { t } = useTranslation();

  return (
    <section>
      <PageHeader title={t("groups.userGroupList.title")} />
      <Container disableGutters>
        <ViewInvitations />
        <br />
        <br />
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<AddBoxIcon />}
          onClick={() => redirectTo("/groups/create")}
        >
          Create a Group
        </Button>
        <br />
        <br />
        <PaperCard>
          <ViewGroupList />
        </PaperCard>
      </Container>
    </section>
  );
};

const mapStateToProps = (state) => ({
  //
});

const mapDispatchToProps = {
  dispatchFetchMyGroups: fetchMyGroups,
  dispatchFetchMyGroupInvitations: fetchMyGroupInvitations,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserGroupList);
