import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import LineStyleIcon from "@material-ui/icons/LineStyle";
import ReorderIcon from "@material-ui/icons/Reorder";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import GroupIcon from "@material-ui/icons/Group";
import SportsEsportsIcon from "@material-ui/icons/SportsEsports";
import BuildIcon from "@material-ui/icons/Build";
import { NavLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { useSidebarIndex } from "../../context/SidebarIndexContext";
import { indexes } from "../../pages";
import { URLs } from "../../logic/constants";
import { Tooltip } from "@material-ui/core";

const iconWidth = "42px";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  list: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  middle: {
    flex: 1,
  },
  bottom: {},
}));

//TODO make listitemtexts multi paragraph for wrapping
const typographyProps = {
  noWrap: true,
  variant: "overline",
};

const LRouteItem = (props) => {
  const handleListItemClick = () => {
    if (props.selectedIndex !== undefined) {
      props.setSelectedIndex(props.index);
    }
  };

  return (
    <Link
      component={NavLink}
      color="textPrimary"
      underline="none"
      to={props.to}
      {...props}
    >
      <ListItem
        disabled={props.disabled}
        button
        selected={
          /* If indexes are undefined do not use selected items, else check if they are selected */
          props.index !== undefined && props.selectedIndex !== undefined
            ? props.selectedIndex === props.index
            : false
        }
        onClick={handleListItemClick}
      >
        <ListItemIcon style={{ minWidth: iconWidth }}>
          {props.icon}
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={typographyProps}
          primary={props.children}
        />
      </ListItem>
    </Link>
  );
};

const LLinkItem = (props) => {
  const handleListItemClick = () => {
    if (props.selectedIndex !== undefined) {
      props.setSelectedIndex(props.index);
    }
  };

  return (
    <Link color="textPrimary" underline="none" href={props.href} {...props}>
      <ListItem
        button
        disabled={props.disabled}
        selected={
          /* If indexes are undefined do not use selected items, else check if they are selected */
          props.index !== undefined && props.selectedIndex !== undefined
            ? props.selectedIndex === props.index
            : false
        }
        onClick={handleListItemClick}
      >
        <ListItemIcon style={{ minWidth: iconWidth }}>
          {props.icon}
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={typographyProps}
          primary={props.children}
        />
      </ListItem>
    </Link>
  );
};

// Otherwise children grabs the onHover event
const WrappedTooltip = ({ children, ...props }) => {
  return (
    <Tooltip arrow placement="right" {...props}>
      <div>{children}</div>
    </Tooltip>
  );
};

const NavMenuList = ({ disableTooltips = false }) => {
  const classes = useStyles();

  const [selectedIndex, setSelectedIndex] = useSidebarIndex();

  const passSelectionProps = {
    selectedIndex: selectedIndex,
    setSelectedIndex: setSelectedIndex,
  };

  return (
    <nav className={classes.root}>
      <List className={classes.list}>
        {/*
          <LRouteItem
            {...passSelectionProps}
            icon={<HomeIcon />}
            to="/"
            index={indexes.home}
          >
            Home
          </LRouteItem>

          <Divider className={classes.divider} />
          */}
        <WrappedTooltip title="Play" disableHoverListener={disableTooltips}>
          <LRouteItem
            {...passSelectionProps}
            icon={<SportsEsportsIcon />}
            to="/play"
            index={indexes.play}
          >
            Play
          </LRouteItem>
        </WrappedTooltip>

        <Divider className={classes.divider} />

        <WrappedTooltip title="Teams" disableHoverListener={disableTooltips}>
          <LRouteItem
            {...passSelectionProps}
            icon={<GroupIcon />}
            to="/teams"
            index={indexes.teams}
          >
            Teams
          </LRouteItem>
        </WrappedTooltip>

        <Divider className={classes.divider} />

        <WrappedTooltip
          title="Tournaments"
          disableHoverListener={disableTooltips}
        >
          <LRouteItem
            {...passSelectionProps}
            icon={<ReorderIcon />}
            to="/tournaments"
            index={indexes.tournaments}
          >
            Tournaments
          </LRouteItem>
        </WrappedTooltip>
        <Divider className={classes.divider} />

        <WrappedTooltip title="Groups" disableHoverListener={disableTooltips}>
          <LRouteItem
            {...passSelectionProps}
            icon={<LineStyleIcon />}
            to="/groups"
            index={indexes.groups}
          >
            Groups
          </LRouteItem>
        </WrappedTooltip>
        <Divider className={classes.divider} />

        <WrappedTooltip title="Profile" disableHoverListener={disableTooltips}>
          <LRouteItem
            {...passSelectionProps}
            icon={<AccountBoxIcon />}
            to="/profile"
            index={indexes.profile}
          >
            Profile
          </LRouteItem>
        </WrappedTooltip>
        <Divider className={classes.divider} />

        {/** Below will stick to the bottom */}
        <div className={classes.middle} />

        <Divider className={classes.divider} style={{ marginTop: -1 }} />
        {process.env.REACT_APP_DEVELOPMENT_MODE === "true" && (
          <>
            <WrappedTooltip title="_" disableHoverListener={disableTooltips}>
              <LRouteItem
                {...passSelectionProps}
                icon={<BuildIcon />}
                index={indexes._}
                to="/_"
                onClick={() => {
                  /** Have to use different techniques because of the Hash Router */
                  if (window.location.pathname === "/_")
                    window.location.assign("/_#");
                }}
              >
                _
              </LRouteItem>
            </WrappedTooltip>
            <Divider className={classes.divider} />
          </>
        )}
        {/*
          <LRouteItem
            {...passSelectionProps}
            icon={<SettingsIcon />}
            disabled={true}
            index={indexes.settings}
          >
            Settings
          </LRouteItem>
*/}

        <WrappedTooltip title="Logout" disableHoverListener={disableTooltips}>
          <LLinkItem
            onClick={(e) => {
              if (!window.confirm("Are you sure?")) e.preventDefault();
            }}
            {...passSelectionProps}
            icon={<ExitToAppIcon />}
            href={URLs.auth.logout}
          >
            Logout
          </LLinkItem>
        </WrappedTooltip>
      </List>
    </nav>
  );
};

export default NavMenuList;
