import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { Avatar, Grid, Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    background: theme.palette.background.default,
    height: 40,
  },
  content: {
    // flex: 2,
    marginRight: theme.spacing(1),
    textAlign: "left",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  cover: {
    flex: 1,
    textAlign: "right",
  },
  container: {},
  name: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontSize: theme.typography.h6.fontSize,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  cornerText: {
    background: theme.palette.primary.main,
    color: theme.palette.background.default,
    fontWeight: 700,
    marginRight: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  avatar: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
}));

export default function TeamCard({ teamInfo, index }) {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Card square className={classes.root}>
      <Grid
        xs={12}
        container
        className={classes.container}
        justify="space-between"
        alignItems="stretch"
      >
        <Grid xs={2} item className={classes.cornerText}>
          {index + 1}
        </Grid>
        {/* <CardContent className={classes.content}> */}
        <Grid item xs={7} className={classes.content}>
          <Typography component="h5" variant="body1" className={classes.name}>
            {teamInfo.name}
          </Typography>
        </Grid>
        {/* </CardContent> */}
        <Grid xs item className={classes.avatar}>
          <CardMedia
            variant="square"
            className={classes.cover}
            image={teamInfo.avatar}
            title={"Participant" + teamInfo.name}
          />
        </Grid>
      </Grid>
    </Card>
  );
}
