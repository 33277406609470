import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { defaultTo } from "lodash";
import { Avatar } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  hide: {
    display: "none",
  },
  cardMedia: {
    //filter: "blur(1px)",
    height: "100%",
    width: "100%",
  },
});

const ImageMediaCard = ({
  alt = "",
  image = "",
  title = "",
  hideCardContent = false,
  square = false,
}) => {
  const classes = useStyles();

  return (
    <Card square={square} variant="outlined" className={classes.root}>
      <div>
        <Avatar
          variant="square"
          src={image}
          alt={alt}
          className={classes.cardMedia}
        >
          {" "}
        </Avatar>
        <CardContent className={hideCardContent && classes.hide}>
          <Typography gutterBottom variant="h5" component="h3">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {alt}
          </Typography>
        </CardContent>
      </div>
    </Card>
  );
};

export default ImageMediaCard;
