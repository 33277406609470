import React from "react";

import { makeStyles } from "@material-ui/core/styles";

import { useSidebarIndex } from "../context/SidebarIndexContext";
import { indexes } from ".";
import { Button, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import KeyboardTab from "@material-ui/icons/KeyboardTab";
import { URLs } from "../logic/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "70vh",
    "& > div": {
      margin: theme.spacing(2),
    },
  },
}));

export default function HomepageContent() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [sidebarIndex, set_sidebarIndex] = useSidebarIndex();
  set_sidebarIndex(indexes.home);

  return (
    <article className={classes.root}>
      <div>
        <Typography variant="h2" data-testid="welcome-text">
          {t("homepage.welcome")}
        </Typography>
      </div>
      <div>
        <Button
          variant="outlined"
          size="large"
          color="primary"
          endIcon={<KeyboardTab />}
          href={URLs.auth.authenticate}
          data-testid="welcome-login-button"
        >
          {t("homepage.login")}
        </Button>
      </div>
    </article>
  );
}
