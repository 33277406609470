import Axios from "axios";

export const getRetryAmount = 3;
export const getTimeoutTime = 5000;
export const postTimeoutTime = getTimeoutTime;

export const timeoutPromise = (timeout, promise, url = "") => {
  return new Promise((resolve, reject) => {
    const t = setTimeout(() => {
      reject({ data: new Error(`Timed out after ${timeout} ms! ${url}`) });
    }, timeout);

    promise()
      .then((value) => {
        clearTimeout(t);
        resolve(value);
      })
      .catch((error) => {
        clearTimeout(t);
        reject(error);
      });
  });
};

export const retryWithInterval = (interval, promise, tries, url = "") => {
  return new Promise((resolve, reject) => {
    let currentTries = 1;

    promise()
      .then((value) => {
        clearInterval(t);
        resolve(value);
      })
      .catch((error) => {
        clearInterval(t);
        reject(error);
      });

    const t = setInterval(() => {
      if (tries > currentTries) {
        currentTries++;
        console.log(
          `Retrying - Request tries: ${currentTries}/${tries} ${url}`,
        );
        promise()
          .then((value) => {
            clearInterval(t);
            resolve(value);
          })
          .catch((error) => {
            clearInterval(t);
            reject(error);
          });
      } else
        reject({
          data: new Error(
            `Failed after ${tries} tries in ${interval} ms interval! ${url}`,
          ),
        });
    }, interval);
  });
};

export const getRequest = async (
  url,
  params = {},
  config = { withCredentials: true },
) => {
  const _config = {
    ...config,
    //for query
    params: {
      ...params,
    },
  };

  const request = () =>
    Axios.get(url, _config)
      .then((response) => {
        //console.log("Headers: ", response.headers);
        return response;
      })
      .catch((err) => {
        console.error("Get Request Error: ", err.response);
        throw err.response;
      });

  return retryWithInterval(getTimeoutTime, request, getRetryAmount, url);
};

export const postRequest = async (
  url,
  params = {},
  config = { withCredentials: true },
) => {
  //console.log("_config: ", _config);
  const request = () =>
    Axios.post(url, params, config)
      .then((response) => {
        //console.log("Headers: ", response.headers);
        return response;
      })
      .catch((err) => {
        console.error("Post Request Error: ", err.response);
        throw err.response;
      });

  return timeoutPromise(postTimeoutTime, request, url);
};
