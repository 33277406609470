import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import * as pages from "./pages";
import PageBase from "./components/layout/PageBase";
import { alertSeverity, useAlert } from "./context/AlertContext";
import { connect } from "react-redux";
import { initializeSocket, getSocket } from "./logic/socket";
import TestingOverlay from "./components/layout/TestingOverlay";
import HIDDEN_ROUTER from "./pages/_/HIDDEN_ROUTER";
import {
  fetchUser,
  upsertOneNotification,
  fetchMyNotifications,
  fetchMyGroupInvitations,
  fetchMyTeamInvitations,
  fetchMyGroups,
} from "./redux/actions";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import * as locales from "@material-ui/core/locale";
import { useTranslation } from "react-i18next";
import ScrollToTop from "./components/dependent/ScrollToTop";

const mapI18toMui = {
  "en-US": "enUs",
  "tr-TR": "trTR",
};

const Main = ({
  dispatchLogin,
  dispatchUpsertOneNotification,
  dispatchFetchMyNotifications,
  dispatchFetchMyTeamInvitations,
  dispatchFetchMyGroupInvitations,
  dispatchFetchMyGroups,
  userId_redux,
  isLoggedIn_redux,
  userLoading_redux,
}) => {
  const showAlert = useAlert();

  const { i18n } = useTranslation();

  const [locale, setLocale] = React.useState("");

  React.useEffect(() => {
    setLocale(mapI18toMui[i18n.language]);
  }, [i18n.language]);

  React.useEffect(() => dispatchLogin(), []);

  React.useEffect(() => {
    if (userId_redux) {
      initializeSocket(userId_redux);
      dispatchFetchMyNotifications();

      const interval = setInterval(() => {
        const socket = getSocket();
        if (socket) {
          clearInterval(interval);

          socket.on("Notification", (data) => {
            dispatchUpsertOneNotification(data);

            switch (data.category) {
              case "Team":
                dispatchFetchMyTeamInvitations();
                break;
              case "Group":
                dispatchFetchMyGroupInvitations();
                dispatchFetchMyGroups();
                break;
              default:
            }
            showAlert(data.message, alertSeverity.info);
          });
        }
      }, 5000);
    }
  }, [userId_redux]);

  return (
    <ThemeProvider
      theme={(outerTheme) => createMuiTheme(outerTheme, locales[locale])}
    >
      <div className="App">
        {process.env.REACT_APP_TESTING_MODE === "true" && <TestingOverlay />}
        <BrowserRouter>
          <ScrollToTop>
            {userLoading_redux ? (
              <pages.Loading />
            ) : (
              <PageBase>
                {isLoggedIn_redux ? (
                  <Switch>
                    <Route
                      exact
                      path="/"
                      render={() => <Redirect to="/play" />}
                    />

                    <Route
                      exact
                      path="/teams"
                      render={() => <pages.UserTeamList />}
                    />

                    <Route
                      exact
                      path={["/tournaments", "/t"]}
                      render={() => <pages.Tournaments />}
                    />

                    <Route
                      exact
                      path={"/play"}
                      render={() => <pages.PublicTournamentList />}
                    />

                    <Route
                      exact
                      path={["/groups", "/g"]}
                      render={() => <pages.UserGroupList />}
                    />

                    <Route
                      exact
                      path="/profile"
                      render={() => <pages.Profile />}
                    />
                    <Route
                      exact
                      path="/groups/:id/edit"
                      render={() => <pages.ManageGroup />}
                    />
                    <Route
                      exact
                      path="/t/:id"
                      render={() => <pages.ViewTournament />}
                    />

                    <Route
                      exact
                      path="/t/:id/edit"
                      render={() => <pages.EditTournament />}
                    />

                    <Route
                      exact
                      path="/t/:id/edit/details"
                      render={() => <pages.EditTournamentDetails />}
                    />

                    <Route
                      exact
                      path="/tournaments/create"
                      render={() => <pages.CreateTournament />}
                    />

                    <Route
                      exact
                      path="/groups/create"
                      render={() => <pages.CreateGroup />}
                    />

                    <Route
                      exact
                      path="/t/:id/edit/stage/:stageId"
                      render={() => <pages.StageCreator />}
                    />

                    <Route
                      exact
                      path="/t/:id/view/stage/:stageId"
                      render={() => <pages.StageViewer />}
                    />

                    <Route
                      exact
                      path="/profile/:id"
                      render={() => <pages.PublicProfile />}
                    />

                    <Route
                      exact
                      path="/post-login"
                      render={() => <pages.PostLogin />}
                    />

                    <Route
                      exact
                      path="/notifications"
                      render={() => <pages.Notification />}
                    />

                    {process.env.REACT_APP_DEVELOPMENT_MODE === "true" && (
                      <Route exact path="/_" component={HIDDEN_ROUTER} />
                    )}

                    <Route>404 Not Found!</Route>
                  </Switch>
                ) : (
                  <Switch>
                    <Route path="/" render={() => <pages.Homepage />} />
                    <Redirect to="/" />
                  </Switch>
                )}
              </PageBase>
            )}
          </ScrollToTop>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
};

const mapDispatchToProps = {
  dispatchLogin: fetchUser,
  dispatchUpsertOneNotification: upsertOneNotification,
  dispatchFetchMyNotifications: fetchMyNotifications,
  dispatchFetchMyTeamInvitations: fetchMyTeamInvitations,
  dispatchFetchMyGroupInvitations: fetchMyGroupInvitations,
  dispatchFetchMyGroups: fetchMyGroups,
};

const mapStateToProps = (store) => ({
  isLoggedIn_redux: store.profile.isLoggedIn,
  userId_redux: store.profile._id,
  userLoading_redux: store.profile.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
