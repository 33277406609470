import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
const useStyles = makeStyles((theme) => ({
  icon: {
    display: "inline-block",
    transitionDuration: "250ms",
    margin: theme.spacing(0, 0.25),
    opacity: "0.4",
    padding: "0",
    "&:hover": {
      cursor: "help",
      opacity: "1",
      color: theme.palette.primary.dark,
    },
    "& > *": {
      verticalAlign: "text-top",
    },
  },
}));

export default function TooltipIcon({
  title,
  icon = <HelpOutlineIcon fontSize={"small"} />,
}) {
  const classes = useStyles();
  return (
    <Tooltip
      placement="right"
      title={title}
      arrow
      leaveDelay={500}
      PopperProps={{
        modifiers: {
          flip: {
            enabled: true,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: "scrollParent",
          },
        },
      }}
    >
      <div className={classes.icon}>{icon}</div>
    </Tooltip>
  );
}
