import socketIOClient from "socket.io-client";
import { URLs } from "./constants";

const ENDPOINT = URLs.apiMainHost;

let socket;
export const initializeSocket = (userId) => {
  socket = socketIOClient(ENDPOINT, {
    withCredentials: true,
    query: {
      user: userId,
    },
    secure: true,
  });
};

export const getSocket = () => {
  if (socket) {
    return socket;
  } else {
    return undefined;
  }
};
