// Lobium-Organize - Initialized at Monday, July 26, 2021 at 12:23. (Lobium Snippets Prefix: ll)
import React from "react";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import Turkish from "../../assets/flags/Turkey.svg";
import English from "../../assets/flags/USA.svg";

export const styles = (theme) => {
  return {
    root: {},
  };
};

const lngs = {
  "en-US": { nativeName: "English", flag: English, short: "EN" },
  "tr-TR": { nativeName: "Turkish", flag: Turkish, short: "TR" },
};

const Flag = ({ lng }) => {
  return (
    <ListItemIcon style={{ minWidth: "25px", verticalAlign: "middle" }}>
      <img
        src={lngs[lng].flag}
        alt={lngs[lng].nativeName}
        style={{
          width: "20px",
          height: "13px",
          objectFit: "cover",
          borderRadius: "2px",
        }}
      />
    </ListItemIcon>
  );
};
// [Violation] 'click' handler took ~500ms
// This occurs only in development mode so far
const LanguageSelector = React.forwardRef((props, ref) => {
  const {
    classes,
    className,
    component: Component = FormControl,
    variant = "outlined",
    label = "Language",
    ...other
  } = props;
  const { i18n } = useTranslation();

  const changeLanguage = ({ target }) => {
    i18n.changeLanguage(target.value);
  };

  return (
    <Component
      className={clsx(
        classes.root,
        {
          /**Conditional Styles Here*/
        },
        className,
      )}
      ref={ref}
      variant={variant}
      {...other}
    >
      <InputLabel id="lobium-language-selector-label">{label}</InputLabel>
      <Select
        labelId="lobium-language-selector-label"
        id="lobium-language-selector"
        label={label}
        defaultValue={i18n.language}
        onChange={changeLanguage}
      >
        {Object.keys(lngs).map((lng) => (
          <MenuItem key={lng} value={lng}>
            <Flag lng={lng} />
            {lngs[lng].short}
          </MenuItem>
        ))}
      </Select>
    </Component>
  );
});

export default withStyles(styles, { name: "LobiumLanguageSelector" })(
  LanguageSelector,
);
