// Lobium-Organize - Initialized at Monday, July 26, 2021 at 12:28. (Lobium Snippets Prefix: ll)
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PAGE_BASE from "../components/PAGE_BASE";
import PAPER from "../components/PAPER";
import LanguageSelector from "../../../components/dependent/LanguageSelector";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const LANGUAGE_SELECTOR = ({ children }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <PAGE_BASE className={classes.root}>
      <PAPER>
        <LanguageSelector />
      </PAPER>
      <PAPER>
        <Typography>
          {t("tournaments.publicTournamentList.description")}
        </Typography>
      </PAPER>
    </PAGE_BASE>
  );
};

export default LANGUAGE_SELECTOR;
