// Lobium-Organize - Initialized at Tuesday, July 13, 2021 at 15:30. (Lobium Snippets Prefix: ll)
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import {
  InputAdornment,
  Paper,
  TextField,
  withStyles,
} from "@material-ui/core";
import { getRequest } from "../../logic/requests";
import { URLs } from "../../logic/constants";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const ElevatedPaper = withStyles((theme) => ({
  root: {
    marginTop: "0",
    border: "1px solid " + theme.palette.action.selected,
    borderRadius: `0px 0px ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
    backgroundColor: theme.palette.background.default,
  },
}))(
  React.forwardRef((props, ref) => (
    <Paper ref={ref} variant="elevation" elevation={4} {...props} />
  )),
);

const UserSearch = ({
  children,
  onSelected = () => {},
  onResultsArrive = () => {},
  onLoading = (isLoading) => {},
  minCharsNeeded = 2,
  label = "Search User",
  textFieldProps,
  searchOption = "username",
  ...rest
}) => {
  const classes = useStyles();

  const [loading, set_loading] = React.useState(false);
  const [searchResults, set_searchResults] = React.useState([]);

  const searchHandler = (_, value, eventReason) => {
    if (value.length >= minCharsNeeded && eventReason === "input") {
      set_loading(true);
      onLoading(true);
      getRequest(URLs.api.user.search, { username: value }).then(({ data }) => {
        set_searchResults(data);
        onResultsArrive(data);
        set_loading(false);
        onLoading(false);
      });
    }
  };

  const selectionHandler = (_, value) => {
    onSelected(value);
  };

  const RenderInput = (params) => {
    return (
      <>
        <InputAdornment style={{ position: "relative", top: 30, left: 7 }}>
          <SearchIcon />
        </InputAdornment>
        <TextField
          fullWidth
          variant="filled"
          label={label}
          disabled={params.disabled}
          id={params.id}
          size={params.size}
          InputProps={{
            ...params.InputProps,
          }}
          inputProps={{
            ...params.inputProps,
            style: { marginLeft: 28 },
          }}
          InputLabelProps={{
            ...params.inputLabelProps,
            style: { marginLeft: 28 },
          }}
        >
          {children}
        </TextField>
      </>
    );
  };

  return (
    <Autocomplete
      className={classes.root}
      options={searchResults}
      getOptionLabel={(option) => option[searchOption]}
      onChange={selectionHandler}
      onInputChange={searchHandler}
      loading={loading}
      renderInput={RenderInput}
      PaperComponent={ElevatedPaper}
      {...rest}
    />
  );
};

export default UserSearch;
