import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";

import { Fade, TableFooter, Typography, withStyles } from "@material-ui/core";
import { LinearProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(3, 0, 2, 0),
    borderRadius: theme.shape.borderRadius,
  },
  clickable: {
    "&:hover": {
      cursor: "pointer",
    },
    "&:active": {
      backgroundColor: theme.palette.primary.main + "!important",
    },
  },
  tableFooter: {
    backgroundColor: theme.palette.background.default,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  body: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    backgroundImage: `url(/images/diagonal-lines-15.png)`,
    backgroundRepeat: "repeat",
  },
  footer: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    backgroundImage: `url(/images/diagonal-lines-15.png)`,
    backgroundRepeat: "repeat",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#242D31",
    },
  },
  head: {
    borderRadius: theme.shape.borderRadius,
  },
}))(TableRow);

export default function ClassicTable({
  rowsPerPage = 10,
  rows = [],
  columns = [],
  rowsOnClick,
  size = "medium",
  emptyText = "There is no data.",
  loading = false,
  error = "",
  onPageChange = () => {},
}) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPageState, setRowsPerPageState] = React.useState(rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    onPageChange(newPage, rowsPerPageState);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPageState(+event.target.value);
    setPage(0);
  };

  return (
    <TableContainer
      component={Paper}
      elevation={4}
      className={classes.container}
    >
      <Table size={size} stickyHeader aria-label="table">
        <TableHead className={classes.tableHead}>
          <StyledTableRow>
            {columns.map((column, i) => (
              <StyledTableCell
                key={column + i}
                align={column.align}
                style={{ minWidth: column.minWidth }}
              >
                <Typography
                  key={column + i}
                  variant="button"
                  style={{ textTransform: "capitalize" }}
                >
                  {column.label}
                </Typography>
              </StyledTableCell>
            ))}
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {loading ? (
            <StyledTableRow>
              <StyledTableCell colSpan={columns.length} align="center">
                <Fade
                  in={loading}
                  style={{
                    transitionDelay: loading ? "150ms" : "0ms",
                  }}
                  unmountOnExit
                >
                  <LinearProgress />
                </Fade>
              </StyledTableCell>
            </StyledTableRow>
          ) : error ? (
            <StyledTableRow>
              <StyledTableCell colSpan={columns.length} align="center">
                <Typography>{error}</Typography>
              </StyledTableCell>
            </StyledTableRow>
          ) : rows?.length > 0 ? (
            rows
              .slice(
                page * rowsPerPageState,
                page * rowsPerPageState + rowsPerPageState,
              )
              .map((row, i) => {
                if (row)
                  return (
                    <StyledTableRow
                      hover
                      onClick={() => rowsOnClick && rowsOnClick(row)}
                      //TODO better undef checking
                      className={rowsOnClick && classes.clickable}
                      tabIndex={-1}
                      key={row.code + i}
                    >
                      {columns.map((column) => {
                        let value = row[column.id];
                        return (
                          <StyledTableCell
                            key={column.code + i}
                            align={column.align}
                          >
                            {column.format ? column.format(value) : value}
                          </StyledTableCell>
                        );
                      })}
                    </StyledTableRow>
                  );
              })
          ) : (
            <StyledTableRow>
              <StyledTableCell colSpan={columns.length} align="center">
                <Typography>{emptyText}</Typography>
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
        <TableFooter>
          <StyledTableRow>
            {rows?.length >= rowsPerPageState ? (
              <TablePagination
                labelRowsPerPage="Rows:"
                rowsPerPageOptions={[10, 25, 100]}
                count={rows.length}
                rowsPerPage={rowsPerPageState}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            ) : (
              <StyledTableCell
                colSpan={columns.length}
                size="small"
                align="center"
              >
                <Typography variant="button">
                  {size !== "small" && rows?.length > 5 && "End"}
                </Typography>
              </StyledTableCell>
            )}
          </StyledTableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

/* Example columns prop.
const columns = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "code", label: "ISO\u00a0Code", minWidth: 100 },
  {
    id: "population",
    label: "Population",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "size",
    label: "Size\u00a0(km\u00b2)",
    minWidth: 170,
    align: "right",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "density",
    label: "Density",
    minWidth: 170,
    align: "right",
    format: (value) => value.toFixed(2),
  },
];

Example rows prop:
function createData(name, code, population, size) {
  const density = population / size;
  return { name, code, population, size, density };
}

const rows = [
  createData("India", "IN", 1324171354, 3287263),
  createData("China", "CN", 1403500365, 9596961),
];
*/
