import React, { useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";

import { getRequest } from "../../logic/requests";
import { URLs } from "../../logic/constants";
import { Bracket, Seed, SeedItem, SeedTeam } from "react-brackets";
import { Typography } from "@material-ui/core";
import ModalViewMatch from "../../components/FIXME/ModalViewMatch";
import { connect, useSelector } from "react-redux";
import { fetchOneGroup, groupsSelectors } from "../../redux/groupsSlice";
import {
  fetchOneTournament,
  tournamentsSelectors,
} from "../../redux/tournamentsSlice";
import { groupConstants, hasPermission } from "../../logic/utils";

//TODO Show if only logged in
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
    fontFamily: theme.typography.h6.fontFamily,
    fontVariant: theme.typography.h6.fontVariant,
    fontSize: theme.typography.h6.fontSize,
  },
  seed: {},
  seedItem: {
    boxShadow: theme.shadows[5],
  },
  seedTeamHome: {
    color: theme.palette.text.primary,
    background: "#242D31",
  },
  seedTeamAway: {
    color: theme.palette.text.primary,
    background: "#1F262A",
  },
  seedWinner: {
    color: theme.palette.text.primary,
    background: "#3F4E55",
  },
  seedLoser: {
    color: theme.palette.text.secondary,
    background: "#1F262A",
  },
  matchNo: {
    textAlign: "left",
    width: "100%",
    margin: 0,
    padding: 0,
    lineHeight: theme.typography.caption.lineHeight,
  },
}));

// It will decide if TBD, null or team name will be shown
// if team id is correct
const shownNameDecider = (id, matchCode) => {
  if (id === "TBD") return "Winner of " + matchCode;
  else if (id === "Bye") return "BYE";
  else return false;
};

const ViewStage = ({
  dispatchFetchOneGroup,
  dispatchFetchOneTournament,
  myId,
  stageId,
}) => {
  const classes = useStyles();
  const [rounds, setRounds] = React.useState([]);
  const [modalData, setModalData] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const params = useParams();

  const tournament = useSelector((state) =>
    tournamentsSelectors.selectById(state, params.id),
  );
  const group = useSelector((state) =>
    groupsSelectors.selectById(state, tournament?.group),
  );
  const permission = useSelector((state) =>
    groupsSelectors.selectMemberPermission(group?.userList, myId),
  );
  useEffect(() => {
    dispatchFetchOneTournament(params.id).then((r) =>
      dispatchFetchOneGroup(r.payload.group),
    );
  }, []);

  const handleOnClick = (seed) => {
    const updatePerm = hasPermission(
      groupConstants.permissions.match.update,
      permission,
    );
    if (seed.isClickable && updatePerm) {
      setOpen(true);
      setModalData(seed);
    }
  };

  const CustomSeed = (seed, breakpoint, roundIndex) => {
    return (
      <Seed mobileBreakpoint={breakpoint} className={classes.seed}>
        <Typography className={classes.matchNo} variant="overline">
          {seed.date}
        </Typography>
        <SeedItem
          className={classes.seedItem}
          onClick={() => handleOnClick(seed)}
        >
          <SeedTeam
            className={
              seed.result == 1 ? classes.seedWinner : classes.seedTeamHome
            }
          >
            <Typography variant="caption">
              {seed.teams[0]?.name || "NO TEAM "}
            </Typography>
            <Typography
              variant="button"
              color={seed.result < 0 ? "textSecondary" : "textPrimary"}
            >
              {seed.result < 0
                ? "TBD"
                : seed.resultType === 4
                ? "W"
                : seed.finalScore[0]}
            </Typography>
          </SeedTeam>
          <SeedTeam
            className={
              seed.result == 2 ? classes.seedWinner : classes.seedTeamAway
            }
          >
            <Typography variant="caption">
              {seed.teams[1]?.name || "NO TEAM "}
            </Typography>
            <Typography
              variant="button"
              color={seed.result < 0 ? "textSecondary" : "textPrimary"}
            >
              {seed.result < 0
                ? "TBD"
                : seed.resultType === 4
                ? "L"
                : seed.finalScore[1]}
            </Typography>
          </SeedTeam>
        </SeedItem>
        <Typography className={classes.matchNo} variant="overline">
          <br />
        </Typography>
      </Seed>
    );
  };

  const getStage = () => {
    getRequest(URLs.api.stage.get, {
      stageId: stageId,
    })
      .then(({ data }) => {
        if (data.participants[Object.keys(data.participants)[0]]?.username) {
          //temp determine if teams
          const parts = {};

          for (const id in data.participants) {
            parts[id] = {
              members: [
                {
                  name: data.participants[id].username,
                  avatar:
                    "https://cdn.discordapp.com/avatars/" +
                    data.participants[id].discordId +
                    "/" +
                    data.participants[id].avatar,
                  _id: id,
                },
              ],
              name: data.participants[id].username,
              avatar:
                "https://cdn.discordapp.com/avatars/" +
                data.participants[id].discordId +
                "/" +
                data.participants[id].avatar,
              captain: id,
            };
          }

          prepareBrackets({ ...data, participants: parts });
        } else {
          prepareBrackets(data);
        }
      })
      .catch((err) => console.log(err));
  };

  const modalOnClose = () => {
    setOpen(false);
    getStage();
  };

  const prepareBrackets = (data) => {
    let size = data.matches.length + 1;
    let rounds = 1;
    const matches = [];
    let i;
    //console.log(data.matches[0]);
    // First round
    while (size > 1) {
      matches.push({ title: "Round " + rounds, seeds: [] });
      for (i = 0; i < size / 2; i++) {
        let match = data.matches.pop();
        if (i % 2) {
          matches[rounds - 1].seeds.push({
            id: match._id,
            finalScore: match.finalScore,
            resultType: match.resultType,
            result: match.result,
            isClickable:
              match.participants[1].id !== "Bye" &&
              match.participants[0].id !== "TBD" &&
              match.participants[1].id !== "TBD",
            date: `${rounds}.${i + 1}`,
            teams: [
              {
                name:
                  shownNameDecider(
                    match.participants[0].id,
                    `${rounds - 1}.${i * 2 + 1}`,
                  ) || data.participants[match.participants[0].id].name,
              },
              {
                name:
                  shownNameDecider(
                    match.participants[1].id,
                    `${rounds - 1}.${i * 2 + 2}`,
                  ) || data.participants[match.participants[1].id].name,
              },
            ],
          });
        } else {
          matches[rounds - 1].seeds.unshift({
            id: match._id,
            finalScore: match.finalScore,
            resultType: match.resultType,
            result: match.result,
            date: `${rounds}.${i + 1}`,
            isClickable:
              match.participants[1].id !== "Bye" &&
              match.participants[0].id !== "TBD" &&
              match.participants[1].id !== "TBD",
            teams: [
              {
                name:
                  shownNameDecider(
                    match.participants[0].id,
                    `${rounds - 1}.${i * 2 + 1}`,
                  ) || data.participants[match.participants[0].id].name,
              },
              {
                name:
                  shownNameDecider(
                    match.participants[1].id,
                    `${rounds - 1}.${i * 2 + 2}`,
                  ) || data.participants[match.participants[1].id].name,
              },
            ],
          });
        }
      }
      size /= 2;
      rounds++;
    }
    for (let index = 0; index < matches.length; index++) {
      let element = matches[index];
      element.seeds.sort((a, b) => (a.date > b.date ? 1 : -1));
    }
    setRounds(matches);
  };

  React.useEffect(() => {
    getStage();
  }, [stageId]);

  return (
    <section className={classes.root}>
      {open && (
        <ModalViewMatch onClose={modalOnClose} open={open} data={modalData} />
      )}
      {rounds.length === 0 ? (
        <Typography variant="h6">Stage is not initialized yet.</Typography>
      ) : (
        <Bracket
          rounds={rounds}
          renderSeedComponent={CustomSeed}
          className={classes.bracket}
        />
      )}
      {/* <Button
        onClick={(e) => {
          e.preventDefault();
          window.location.href =
            "http://localhost:3001/t/" +
            params.id +
            "/edit/stage/" +
            stageId;
        }}
      >
        Update Bracket
      </Button> */}
    </section>
  );
};

const mapDispatchToProps = {
  dispatchFetchOneTournament: fetchOneTournament,
  dispatchFetchOneGroup: fetchOneGroup,
};

const mapStateToProps = (state) => ({
  myId: state.profile._id,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewStage);
