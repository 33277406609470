import React from "react";
import { formatDateString } from "../../logic/utils";
import IconButton from "@material-ui/core/IconButton";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import {
  useTheme,
  useMediaQuery,
  Badge,
  makeStyles,
  Popover,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  ListItemIcon,
} from "@material-ui/core";
import { connect, useSelector } from "react-redux";
import {
  fetchMarkNotificationsRead,
  notificationsSelectors,
} from "../../redux/actions";

import GroupIcon from "@material-ui/icons/Group";
import SendIcon from "@material-ui/icons/Send";
import LineStyleIcon from "@material-ui/icons/LineStyle";
import { useHistory } from "react-router";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

const maxPopoverNotificationAmount = 4;

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const CategoryIconSwitch = ({ category, ...restArgs }) => {
  switch (category) {
    case "Team":
      return <GroupIcon fontSize="large" {...restArgs} />;
    case "Group":
      return <LineStyleIcon fontSize="large" {...restArgs} />;
    default:
      return <SendIcon color="disabled" fontSize="large" {...restArgs} />;
  }
};

export const Notifications = ({
  socket,
  fontSize = "medium",
  dispatchFetchMarkNotificationsRead,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const notifications = useSelector(notificationsSelectors.selectAll);
  //const readNotifications = useSelector(notificationsSelectors.selectRead);
  const unreadNotifications = useSelector(notificationsSelectors.selectUnread);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const redirectTo = React.useCallback(
    (path = "") => history.push(path),
    [history]
  );

  const handleNotificationClick = (notificationCategory) => {
    handleNotificationsClose();

    switch (notificationCategory) {
      case "Team":
        redirectTo("/teams");
        return;
      case "Group":
        redirectTo("/groups");
        return;
      default:
        return;
    }
  };

  const handleBellClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationsClose = () => {
    setAnchorEl(null);
    if (unreadNotifications.length > 0) dispatchFetchMarkNotificationsRead(); //FIXME mark as read only shown ones, bell will show only max amount
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <IconButton
        onClick={
          true /**isMobile */
            ? () => redirectTo("/notifications")
            : handleBellClick
        }
      >
        <Badge badgeContent={unreadNotifications.length} color="primary">
          {unreadNotifications.length > 0 ? (
            <NotificationsActiveIcon fontSize={fontSize} />
          ) : (
            <NotificationsIcon fontSize={fontSize} />
          )}
        </Badge>
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleNotificationsClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List style={{ maxHeight: "35vh", overflow: "auto" }} disablePadding>
          {notifications.length > 0 ? (
            notifications.slice(0, maxPopoverNotificationAmount).map((n, i) => (
              <React.Fragment key={n + i}>
                <ListItem
                  key={n + i}
                  button
                  onClick={() => handleNotificationClick(n.category)}
                >
                  <ListItemIcon key={n + i}>
                    <CategoryIconSwitch
                      key={n + i}
                      category={n.category}
                      color={!n.read ? "primary" : ""}
                    />
                  </ListItemIcon>
                  <ListItemText
                    key={n + i}
                    secondaryTypographyProps={{ variant: "caption" }}
                    secondary={formatDateString(n.createdAt)}
                  >
                    <Typography variant="body2" key={n + i}>
                      <Typography variant="subtitle2" key={n + i}>
                        {n.category}
                      </Typography>
                      {n.message}
                    </Typography>
                  </ListItemText>
                </ListItem>
                {i < notifications.length - 1 && <Divider variant="middle" />}
                {i === maxPopoverNotificationAmount - 1 && (
                  <ListItem
                    key={n + i}
                    button
                    style={{ justifyContent: "center" }}
                    onClick={() => {
                      redirectTo("/notifications");
                      setAnchorEl(null);
                    }}
                  >
                    {unreadNotifications.length >
                    maxPopoverNotificationAmount ? (
                      <div>
                        <Badge
                          badgeContent={`+${
                            unreadNotifications.length -
                            maxPopoverNotificationAmount
                          }`}
                          color="primary"
                        />
                      </div>
                    ) : (
                      <MoreHorizIcon fontSize={fontSize} />
                    )}
                  </ListItem>
                )}
              </React.Fragment>
            ))
          ) : (
            <ListItem button>
              <ListItemText>There are no notifications.</ListItemText>
            </ListItem>
          )}
        </List>
      </Popover>
    </div>
  );
};

const mapDispatchToProps = {
  dispatchFetchMarkNotificationsRead: fetchMarkNotificationsRead,
};

const mapStateToProps = (store) => ({
  //
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
