import React from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Popover,
  Typography,
} from "@material-ui/core";

export default function PopoverDialogButton({
  children,
  contentText,
  onClick,
  colors = "primary",
  cancelButtonProps,
  confirmButtonProps,
  ...buttonProps
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="contained"
        color={colors}
        onClick={handleClick}
        {...buttonProps}
      >
        {children ? children : "Button"}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{ textAlign: "center" }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <DialogContent>
          <Typography>{contentText ? contentText : "Are you sure?"}</Typography>
        </DialogContent>
        <DialogActions>
          <Button color={colors} onClick={handleClose} {...cancelButtonProps}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color={colors}
            onClick={() => {
              onClick();
              handleClose();
            }}
            {...confirmButtonProps}
          >
            Confirm
          </Button>
        </DialogActions>
      </Popover>
    </div>
  );
}
