import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from "@reduxjs/toolkit";
import { getRequest } from "../../logic/requests";
import { URLs } from "../../logic/constants";

export const fetchPublicTournaments = createAsyncThunk(
  "publicTournaments/fetchPublicTournaments",
  async ({ upcoming = 0, count = 20, skip = 0, sort = 1 }) =>
    await getRequest(URLs.api.tournamentList.get, {
      upcoming,
      count,
      skip,
      sort,
    })
      .then((response) => {
        if (upcoming)
          return { ...response.data, upcomingCount: response.data.count };
        else return { ...response.data, pastCount: response.data.count };
      })
      .catch((err) => {
        console.error("Public Tournament List " + err);
        return {};
      }),
);
const publicTournamentsAdapter = createEntityAdapter({
  selectId: (tournament) => tournament._id,
  sortComparer: (a, b) => b.startDate.localeCompare(a.startDate),
});

const publicTournamentsSlice = createSlice({
  name: "publicTournaments",
  initialState: publicTournamentsAdapter.getInitialState({
    loading: false,
    error: null,
    pastCount: 0,
    upcomingCount: 0,
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPublicTournaments.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchPublicTournaments.fulfilled, (state, action) => {
        publicTournamentsAdapter.upsertMany(state, action.payload.tournaments);
        if (action.payload.pastCount)
          state.pastCount = action.payload.pastCount;
        if (action.payload.upcomingCount)
          state.upcomingCount = action.payload.upcomingCount;
        state.loading = false;
        state.error = null;
        return state;
      })
      .addCase(fetchPublicTournaments.rejected, (state, action) => {
        state.loading = false;
        state.error = `Couldn't get public tournaments!`;
        console.error(state.error);
        throw new Error(state.error);
      });
  },
});

export const {} = publicTournamentsSlice.actions;

const defaultSelectors = publicTournamentsAdapter.getSelectors(
  (state) => state.publicTournaments,
);

export const publicTournamentsSelectors = {
  ...defaultSelectors,
  selectPasts: createSelector(defaultSelectors.selectAll, (publicTournaments) =>
    publicTournaments.filter((t) => new Date(t.startDate) <= new Date()),
  ),
  selectUpcoming: createSelector(
    defaultSelectors.selectAll,
    (publicTournaments) =>
      publicTournaments.filter((t) => new Date(t.startDate) > new Date()),
  ),
};

export default publicTournamentsSlice.reducer;
