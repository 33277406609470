import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getRequest } from "../logic/requests";
import { URLs } from "../logic/constants";

export const fetchUser = createAsyncThunk(
  "user/fetchUser",
  async () =>
    await getRequest(URLs.api.user.getMy, { withCredentials: true })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.error("Login " + err);
        return {};
      }),
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    isLoggedIn: false,
    _id: null,
    username: null,
    email: null,
    avatar: null,
    discordId: null,
    discriminator: null,
    connections: [],
    apiKeys: [],
    myTournaments: [],
    teams: [],
    teamInvitationsReceived: [],
    groups: [],
    groupInvitationsReceived: [],
    createdTournaments: [],
    loading: false,
    error: null,
  },
  reducers: {
    setTeamIds(state, action) {
      state.teams = action;
    },
    addTeamIds(state, action) {
      state.teams.push(action);
    },
    setTeamInvitationIds(state, action) {
      state.teamInvitationsReceived = action;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state, action) => {
        state.isLoggedIn = false;
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state = action.payload;
        state.isLoggedIn = state._id !== null && state._id !== undefined;
        state.loading = false;
        state.error = null;
        return state;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.loading = false;
        state.error = `Couldn't get user!`;
        state.isLoggedIn = false;
        throw new Error(state.error);
      });
  },
});

export const {
  setTeamIds,
  addTeamIds,
  setTeamInvitationIds,
} = userSlice.actions;
export default userSlice.reducer;
