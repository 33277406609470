// Lobium-Organize - Initialized at Wednesday, July 14, 2021 at 14:23. (Lobium Snippets Prefix: ll)
import React from "react";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import { Divider, Paper, Typography } from "@material-ui/core";

export const styles = (theme) => {
  return {
    root: {
      height: "100%",
      padding: theme.spacing(2),
    },
    divider: {
      padding: theme.spacing(0.5),
    },
    title: {
      margin: theme.spacing(2),
    },
  };
};

const PaperCard = React.forwardRef((props, ref) => {
  const {
    classes,
    className,
    component: Component = Paper,
    children,
    title,
    titleVariant = "h4",
    TypographyProps,
    ...other
  } = props;

  return (
    <Component className={clsx(classes.root, className)} ref={ref} {...other}>
      {title && (
        <>
          <Typography
            variant={titleVariant}
            className={classes.title}
            {...TypographyProps}
          >
            {title}
          </Typography>
          <Divider className={classes.divider} />
        </>
      )}
      {children}
    </Component>
  );
});

export default withStyles(styles, { name: "LobiumPaperCard" })(PaperCard);
