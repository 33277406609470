// Lobium-Organize - Initialized at Tuesday, July 13, 2021 at 21:29. (Lobium Snippets Prefix: ll)
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: { textAlign: "left" },
}));

const JSON_RENDERER = ({ json, children }) => {
  const classes = useStyles();

  return (
    <pre className={classes.root}>
      {JSON.stringify(json || children, null, 2)}
    </pre>
  );
};

export default JSON_RENDERER;
