// Lobium-Organize / publicProfiles - Initialized at Saturday, July 03, 2021 at 16:18. (Lobium Snippets Prefix: ll)
//
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { getRequest } from "../../logic/requests";
import { URLs } from "../../logic/constants";

export const fetchPublicProfile = createAsyncThunk(
  "publicProfiles/fetchPublicProfile",
  async (userId, { rejectWithValue }) =>
    await getRequest(URLs.api.user.get, { userId }, { withCredentials: true })
      .then((response) => {
        return response.data;
      })
      .catch(({ data }) => {
        return rejectWithValue(data);
      }),
);

const publicProfilesAdapter = createEntityAdapter({
  selectId: (object) => object._id,
});

const publicProfilesSlice = createSlice({
  name: "publicProfiles",
  initialState: publicProfilesAdapter.getInitialState({
    loading: false,
    error: null,
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPublicProfile.fulfilled, (state, action) => {
        publicProfilesAdapter.upsertOne(state, action.payload);
      })
      .addMatcher(
        (action) => action.type.startsWith("publicProfiles/"),
        (state, action) => {
          if (action.type.endsWith("/fulfilled")) {
            state.loading = false;
            state.error = null;
          } else if (action.type.endsWith("/pending")) {
            state.loading = true;
            state.error = null;
          } else if (action.type.endsWith("/rejected")) {
            state.loading = false;
            state.error = action.payload;
            throw action.payload;
          }
        },
      );
  },
});

export const {} = publicProfilesSlice.actions;

export const publicProfilesSelectors = publicProfilesAdapter.getSelectors(
  (state) => state.publicProfiles,
);

export default publicProfilesSlice.reducer;
