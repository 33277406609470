import { Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import React from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ClassicTable from "../../../components/independent/ClassicTable";
import { roleDecider } from "../../../logic/utils";
import { groupsSelectors } from "../../../redux/actions";
import ModalManageGroup from "./ModalManageGroup";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const columns = [
  { id: "avatar", label: "" },
  { id: "name", label: "Group Name", minWidth: 200 },
  { id: "role", label: "Role", align: "center" },
  { id: "size", label: "Member Count", align: "center" },
  // { id: "id", label: "Team ID" },
  {
    id: "actions",
    label: "Actions",
    minWidth: 100,
    align: "right",
  },
];

const createData = (avatar, name, role, size, actions, index, id, userList) => {
  return { avatar, name, role, size, actions, index, id, userList };
};

const ViewGroupList = ({
  size = "medium",
  loading = false,
  userId_redux,
  isLoading_redux,
  error_redux,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const redirectTo = React.useCallback(
    (path = "") => history.push(path),
    [history],
  );
  const groups = useSelector(groupsSelectors.selectAll);

  const [openModal, setOpenModal] = React.useState(false);
  const [groupsRows, set_groupsRows] = React.useState([]);
  const [selectedGroup, set_selectedGroup] = React.useState({ name: "" });

  // const itemClickHandler = (group) => {
  //   setOpenModal(true);
  //   set_selectedGroup(group);
  // };

  React.useEffect(() => {
    set_groupsRows(
      groups.map(
        (group, index) =>
          group?.groupName &&
          createData(
            <Avatar
              variant="rounded"
              alt={group.groupName}
              src={group.groupAvatar}
            >
              {group.groupName ? group.groupName[0] : "?"}
            </Avatar>,
            group.groupName,
            roleDecider(
              groupsSelectors.selectMemberPermission(
                group.userList,
                userId_redux,
              ),
            ),
            group.userList?.length,
            <ChevronRightIcon />,
            index,
            group._id,
            group.userList,
          ),
      ),
    );
  }, [groups]);

  return (
    <>
      <ClassicTable
        loading={isLoading_redux}
        error={error_redux}
        rows={groupsRows}
        columns={columns}
        size={size}
        rowsOnClick={(params) => redirectTo("/groups/" + params.id + "/edit")}
      />
      {selectedGroup.role && (
        <ModalManageGroup
          open={openModal}
          setOpen={setOpenModal}
          group={selectedGroup}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  userId_redux: state.profile._id,
  error_redux: state.groups.error,
  isLoading_redux: state.groups.loading,
  invitorGroups: state.groups.invitorGroups,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ViewGroupList);
