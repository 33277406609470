import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    background: "gray",
    width: "100vw",
    height: "100vh",
    margin: 0,
  },
}));

//Not used currently
const PostLogin = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <p>Post Login</p>
    </div>
  );
};

const mapDispatchToProps = {};

export default connect(null, mapDispatchToProps)(PostLogin);
