import {
  Dialog,
  Button,
  Grid,
  Typography,
  makeStyles,
  DialogContent,
  TextField,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { URLs } from "../../logic/constants";
import { getRequest, postRequest } from "../../logic/requests";
import ImageMediaCard from "../independent/ImageMediaCard";

const logoUri = "/images/LA-Icon-Light.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '70%',
  },
  button: {
    margin: theme.spacing(3, 0, 0, 0),
  },
  input: {
    ...theme.typography.h2,
  },
}));

const ModalViewMatch = (props) => {
  const classes = useStyles();
  const [match, setMatch] = useState({
    finalScore: ["NA", "NA"],
  });
  const [teams, setTeams] = useState([]);
  const [visible, setVisible] = useState(false);
  const [scoreH, setScoreH] = useState(0);
  const [scoreA, setScoreA] = useState(0);
  const [input, setInput] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const buttonHandler = () => {
    if (!input) {
      setInput(true);
    } else {
      postRequest(URLs.api.match.setMatchFinalScore, {
        matchId: props.data.id,
        score: [scoreH, scoreA],
      }).then((r) => props.onClose());
    }
  };

  useEffect(() => {
    getRequest(URLs.api.match.get, { matchId: props.data.id }).then((res) => {
      setMatch(res.data.match);
      setTeams(res.data.teams);
      setVisible(true);
    });
  }, []);

  const handleScoreH = (e) => {
    let num = Number.parseInt(e.nativeEvent.target.value);
    if (!isNaN(num)) {
      Math.abs(num);
      setScoreH(Math.abs(num));
    }
  };

  const handleScoreA = (e) => {
    let num = Number.parseInt(e.nativeEvent.target.value);
    if (!isNaN(num)) {
      Math.abs(num);
      setScoreA(Math.abs(num));
    }
  };

  return (
    <Dialog
      fullWidth
      fullScreen={isMobile}
      className={classes.root}
      open={props.open}
      onClose={props.onClose}
    >
      <DialogContent>
        <Grid container direction="column">
          <Grid container>
            <Grid item xs={4}>
              {visible && (
                <ImageMediaCard
                  image={
                    teams[0]?.avatar === "empty" ? logoUri : teams[0]?.avatar
                  }
                  alt={"Home"}
                  title={props.data.teams[0].name}
                />
              )}
            </Grid>
            <Grid container xs={4} justify="center" alignItems="center">
              {input ? (
                <>
                  <Grid item xs={4}>
                    <TextField
                      className={classes.input}
                      value={scoreH}
                      inputProps={{ min: 0, style: { textAlign: "center" } }}
                      InputProps={{
                        className: classes.input,
                      }}
                      onChange={handleScoreH}
                    />
                  </Grid>
                  <Grid item container justify="center" xs={4}>
                    <Typography variant="h2">|</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      className={classes.input}
                      value={scoreA}
                      inputProps={{ min: 0, style: { textAlign: "center" } }}
                      InputProps={{
                        className: classes.input,
                      }}
                      onChange={handleScoreA}
                    />
                  </Grid>
                </>
              ) : (
                <Typography variant="h2">
                  {visible ? props.data.finalScore[0] : "?"} |{" "}
                  {visible ? props.data.finalScore[1] : "?"}
                </Typography>
              )}
            </Grid>
            <Grid item xs={4}>
              {visible && (
                <ImageMediaCard
                  image={
                    teams[1]?.avatar === "empty" ? logoUri : teams[1]?.avatar
                  }
                  alt={"Away"}
                  title={props.data.teams[1].name}
                />
              )}
            </Grid>
          </Grid>
          <Button
            disabled={scoreA === scoreH && input}
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={buttonHandler}
          >
            {!input ? "Set Score" : "Confirm"}
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ModalViewMatch;
