import downscale from "downscale";

export const resizeImage = ({ file, maxSize }) => {
  return new Promise((resolve, reject) => {
    if (!file.type.match(/image.*/)) {
      reject(new Error("The file is not an image!"));
      return;
    }
    downscale(file, maxSize, maxSize).then((dataURL) => {
      resolve(dataURL);
    });
  });
};

export const formatDateString = (dateString) => {
  const weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  let dateObject = new Date(dateString);
  let formattedDate =
    weekDays[dateObject.getDay()] +
    ", " +
    dateObject.getDate() +
    "-" +
    dateObject.toLocaleString("en-EN", { month: "short" }) +
    "-" +
    dateObject.getFullYear() +
    " " +
    dateObject.getHours().toString().padStart(2, "0") +
    ":" +
    dateObject.getMinutes().toString().padStart(2, "0") +
    " " +
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  return formattedDate;
};

// this is temp obviously
export const roleDecider = (decimalPermission) => {
  if (decimalPermission === 0) return "Guest";
  if (decimalPermission & groupConstants.aliases.groupAll) {
    if (
      (decimalPermission &
        (groupConstants.aliases.groupAll ||
          groupConstants.aliases.tournamentAll ||
          groupConstants.aliases.matchAll)) ===
      (groupConstants.aliases.groupAll ||
        groupConstants.aliases.tournamentAll ||
        groupConstants.aliases.matchAll)
    )
      return "Admin";

    return "Admin -";
  }
  if (decimalPermission & groupConstants.aliases.tournamentAll) {
    if (
      (decimalPermission &
        (groupConstants.aliases.tournamentAll ||
          groupConstants.aliases.matchAll)) ===
      (groupConstants.aliases.tournamentAll || groupConstants.aliases.matchAll)
    )
      return "Head Referee";
    return "Head Referee -";
  }
  if (decimalPermission & groupConstants.aliases.matchAll) {
    if (
      (decimalPermission & groupConstants.aliases.matchAll) ===
      groupConstants.aliases.matchAll
    )
      return "Referee";
    return "Referee -";
  }

  return "-";
};

export const groupConstants = {
  groupTypes: {
    default: 0,
    userCreated: 1,
  },
  aliases: {
    owner: 0b111111111111,
    groupAll: 0b1111,
    tournamentAll: 0b11110000,
    matchAll: 0b111100000000,
  },
  permissions: {
    group: {
      create: 0b0001,
      read: 0b0010,
      update: 0b0100,
      delete: 0b1000,
    },
    tournament: {
      create: 0b00010000,
      read: 0b00100000,
      update: 0b01000000,
      delete: 0b10000000,
    },
    match: {
      create: 0b000100000000,
      read: 0b001000000000,
      update: 0b010000000000,
      delete: 0b100000000000,
    },
  },
};

export const hasPermission = (alias, permission) =>
  (alias & permission) === alias;
