import React from "react";
import { connect, useSelector } from "react-redux";
import { Avatar, makeStyles } from "@material-ui/core";

import ClassicTable from "../../../components/independent/ClassicTable";

import { useAlert, alerts } from "../../../context/AlertContext";
import ModalViewTeam from "./ModalViewTeam";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { teamsSelectors } from "../../../redux/actions";
import GradeIcon from "@material-ui/icons/Grade";

const useStyles = makeStyles((theme) => ({
  avatar: {
    borderRadius: 3,
  },
  actionsContainer: {
    "& > *": {
      margin: theme.spacing(0, 1),
    },
  },
}));

const columns = [
  { id: "avatar", label: "" },
  { id: "name", label: "Team Name", minWidth: 200 },
  { id: "size", label: "Member Count", align: "center" },
  {
    id: "captain",
    label: "",
    align: "right",
    format: (value) =>
      value?.captain === value?.userId ? (
        <GradeIcon style={{ verticalAlign: "middle" }} />
      ) : (
        ""
      ),
  },
  {
    id: "actions",
    label: "View",
    maxWidth: 75,
    align: "right",
  },
];

const createData = (
  avatar,
  name,
  size,
  { captain, userId },
  id,
  actions,
  index,
) => {
  return {
    avatar,
    name,
    size,
    captain: { captain, userId },
    id,
    actions,
    index,
  };
};

const ViewTeamList = ({ size = "medium", redux_userId, isLoading_redux }) => {
  const classes = useStyles();

  const showAlert = useAlert();
  const teams = useSelector(teamsSelectors.selectAll);

  const [teamsRows, set_teamsRows] = React.useState([]);
  const [selectedTeam, set_selectedTeam] = React.useState(false);
  const [isModalOpen, set_isModalOpen] = React.useState(false);

  const onRowsClick = (row) => {
    set_selectedTeam(teams[row.index]);
    set_isModalOpen(true);
  };

  React.useEffect(() => {
    if (teams)
      set_teamsRows(
        teams.map((team, index) =>
          createData(
            <Avatar variant="rounded" alt={team.name} src={team.avatar} />,
            team.name,
            team.members.length,
            { captain: team.captain, userId: redux_userId },
            team._id,
            <ChevronRightIcon />,
            index,
          ),
        ),
      );
  }, [teams]);

  return (
    <>
      <ClassicTable
        loading={isLoading_redux}
        size={size}
        rows={teamsRows}
        columns={columns}
        rowsOnClick={onRowsClick}
      />

      <ModalViewTeam
        id={selectedTeam._id}
        open={isModalOpen}
        onClose={() => set_isModalOpen(false)}
      />
    </>
  );
};

const mapDispatchToProps = {
  //
};

const mapStateToProps = (state) => ({
  redux_userId: state.profile._id,
  isLoading_redux: state.teams.loading,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewTeamList);
