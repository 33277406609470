import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { getRequest } from "../logic/requests";
import { URLs } from "../logic/constants";

export const fetchMyTeamInvitations = createAsyncThunk(
  "teamInvitations/fetchMyTeamInvitations",
  async () =>
    await getRequest(URLs.api.team.getMyInvitations, { withCredentials: true })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.error("My Team Invitations " + err);
        return {};
      }),
);

const teamInvitationsAdapter = createEntityAdapter({
  selectId: (team) => team._id,
});

const teamInvitationsSlice = createSlice({
  name: "teamInvitations",
  initialState: teamInvitationsAdapter.getInitialState({
    status: "pending",
  }),
  reducers: {
    setMyTeamInvitations: teamInvitationsAdapter.setAll,
    addOneMyTeamInvitation: teamInvitationsAdapter.addOne,
    removeOneMyTeamInvitation: teamInvitationsAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyTeamInvitations.pending, (state, action) => {
        state.status = "pending";
      })
      .addCase(fetchMyTeamInvitations.fulfilled, (state, action) => {
        teamInvitationsAdapter.setAll(state, action.payload);
        state.status = "success";
      })
      .addCase(fetchMyTeamInvitations.rejected, (state, action) => {
        state.status = "failed";
        console.error(`Couldn't get my team invitations!`);
      });
  },
});

export const {
  setMyTeamInvitations,
  addOneMyTeamInvitation,
  removeOneMyTeamInvitation,
} = teamInvitationsSlice.actions;

export const teamInvitationsSelectors = teamInvitationsAdapter.getSelectors(
  (state) => state.teamInvitations,
);

export default teamInvitationsSlice.reducer;
