import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Footer from "./Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
  },
  content: {
    padding: theme.spacing(3),
    minHeight: "70vh",
  },
  contentSpacing: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(2),
  },
}));

export default function ContentContainer(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.contentSpacing} />
      <div className={classes.content}>
        {props.children}
        <div className={classes.contentSpacing} />
      </div>
      <Footer />
    </div>
  );
}
