import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Link as RouterLink } from "react-router-dom";
import Notifications from "../dependent/Notifications";
import KeyboardTab from "@material-ui/icons/KeyboardTab";
import { connect } from "react-redux";
import { URLs } from "../../logic/constants";
import DateFnsAdapter from "@date-io/date-fns";
import {
  Button,
  ButtonBase,
  Hidden,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import NavMenu from "./NavMenu";
import { useTranslation } from "react-i18next";

const dateFns = new DateFnsAdapter();

const useStyles = makeStyles((theme) => ({
  root: {
    //flexGrow: 1,
    zIndex: theme.zIndex.drawer + 1,
    marginTop: theme.spacing(-5),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    textTransform: "capitalize",
  },
  center: {
    flexGrow: 1,
  },
  link: {
    display: "flex",
  },
  icon: {
    display: "flex",
    height: 43,
    padding: "7px",
    marginLeft: -10,
  },
  bottomAppBar: {
    top: "auto",
    bottom: 0,
  },
  contentSpacing: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(2),
  },
  iconButton: {
    background: "#f00",
    padding: theme.spacing(2),
  },
}));

const lngs = {
  en: { nativeName: "English" },
  tr: { nativeName: "Türkçe" },
};

const Navbar = ({ userId_redux, isLoggedIn_redux }) => {
  const classes = useStyles();
  const [currentDate, set_currentDate] = React.useState(new Date());

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  React.useEffect(() => {
    const interval = setInterval(() => set_currentDate(new Date()), 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const { t } = useTranslation();

  return (
    <section className={classes.root}>
      <AppBar
        position="fixed"
        variant="elevation"
        color="inherit"
        className={clsx({ [classes.bottomAppBar]: isMobile })}
      >
        <Toolbar variant="dense">
          <Hidden xsDown>
            <RouterLink to="/">
              <img
                className={classes.icon}
                src="/images/LA-Logo-Light.svg"
                alt="Lobium Alpha"
              />
            </RouterLink>
          </Hidden>
          <Hidden xsDown>
            <div className={classes.center}>
              <Typography variant="overline" className={classes.title}>
                {`${dateFns.format(currentDate, "p")} - ${
                  Intl.DateTimeFormat().resolvedOptions().timeZone
                } (${dateFns.format(currentDate, "z")})`}
              </Typography>
            </div>
          </Hidden>
          {userId_redux && <Notifications />}

          <Hidden smUp>
            <div style={{ flexGrow: 1 }}>
              <ButtonBase centerRipple>
                <RouterLink to="/">
                  <img
                    className={classes.icon}
                    src="/images/LA-Icon-Light.svg"
                    alt="Lobium Alpha"
                  />
                </RouterLink>
              </ButtonBase>
            </div>
          </Hidden>

          <Hidden smUp>
            {isLoggedIn_redux && <NavMenu className={classes.iconButton} />}
          </Hidden>
          {!isLoggedIn_redux && (
            <div>
              <Button
                id="loginPageButton"
                variant="contained"
                color="primary"
                endIcon={<KeyboardTab />}
                href={URLs.auth.authenticate}
                data-testid="login-button"
              >
                {t("components.navbar.login")}
              </Button>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </section>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn_redux: state.profile.isLoggedIn,
  userId_redux: state.profile._id,
});

export default connect(mapStateToProps)(Navbar);
