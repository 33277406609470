import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MailIcon from "@material-ui/icons/Mail";
import { FaTwitter, FaDiscord, FaTwitch, FaInstagram } from "react-icons/fa";
import { Container, Hidden } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../dependent/LanguageSelector";

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.default,
  },
  logo: {
    height: 100,
  },
  iconPaper: {
    padding: theme.spacing(0.25),
    alignItems: "center",
    display: "flex",
    backgroundColor: "#FFFAFA",
  },
  control: {
    padding: theme.spacing(2),
  },
  contentSpacing: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(3),
  },
  divider: {
    padding: theme.spacing(0.4),
    margin: theme.spacing(4, 10),
  },
}));

const b = {
  xs: 12,
  sm: 12,
  md: 2,
  lg: 3,
  xl: 3,
};

const LListItemLink = (props) => {
  return <ListItem button component="a" {...props} />;
};

const RenderSwitch = (type) => {
  const iconStyles = { color: "#FFFAFA" }; // if needed in future
  switch (type) {
    case "twitch":
      return <FaTwitch style={iconStyles} />;
    case "twitter":
      return <FaTwitter style={iconStyles} />;
    case "discord":
      return <FaDiscord style={iconStyles} />;
    case "instagram":
      return <FaInstagram style={iconStyles} />;
    case "mail":
      return <MailIcon style={iconStyles} />;
    default:
      return null;
  }
};

const LGridIcon = ({ type, url }) => (
  <Grid item xs="2" sm="1" md="12">
    <IconButton target={"_blank"} href={url}>
      {RenderSwitch(type)}
    </IconButton>
  </Grid>
);
const Footer = () => {
  const classes = useStyles();
  const socialMedia = [
    { type: "discord", url: "https://discord.gg/UDAGsgz" },
    { type: "instagram", url: "https://www.instagram.com/LobiumOfficial/" },
    { type: "twitter", url: "https://twitter.com/LobiumOfficial" },
    { type: "twitch", url: "https://www.twitch.tv/LobiumTV" },
    { type: "mail", url: "mailto:contact@lobium.com" },
  ];

  const { t } = useTranslation();

  return (
    <section className={classes.root}>
      <Divider variant="inset" />

      <div className={classes.contentSpacing} />
      <Container disableGutters>
        <Grid
          container
          spacing={0}
          direction="row"
          justify="space-around"
          alignItems="stretch"
        >
          <Grid {...b} xs={12} sm={12} md={3} container item spacing={4}>
            <Grid xs={12} item>
              <img
                className={classes.logo}
                src="/images/LA-TwoLine-Light.svg"
                alt="Lobium"
              />
            </Grid>
            <Grid xs={12} item style={{ marginBottom: "20px" }}>
              <LanguageSelector />
            </Grid>
          </Grid>

          <Grid {...b} item>
            <Typography variant="h5" component="h5">
              {t("components.footer.upcoming")}
            </Typography>
            <List>
              <LListItemLink href="https://lobby.host/">
                <ListItemText>Lobby.Host</ListItemText>
              </LListItemLink>
              <LListItemLink href="/upcoming/profiles">
                <ListItemText>{t("components.footer.profile")}</ListItemText>
              </LListItemLink>
            </List>
          </Grid>

          <Grid {...b} item>
            <Typography variant="h5" component="h5">
              {t("components.footer.explore")}
            </Typography>
            <List>
              <LListItemLink href="/">
                <ListItemText>{t("components.footer.home")}</ListItemText>
              </LListItemLink>
              <LListItemLink href="/assets">
                <ListItemText>{t("components.footer.assets")}</ListItemText>
              </LListItemLink>
              <LListItemLink href="/tournaments">
                <ListItemText>
                  {t("components.footer.tournaments")}
                </ListItemText>
              </LListItemLink>
              <LListItemLink href="https://www.exploit.studio/">
                <ListItemText>Exploit Studio</ListItemText>
              </LListItemLink>
            </List>
          </Grid>
          <Grid {...b} item>
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="baseline"
            >
              {socialMedia.map((media, i) => (
                <LGridIcon url={media.url} type={media.type} />
              ))}
            </Grid>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />
        <Typography variant="subtitle2">
          <i>{t("components.footer.part1")}</i>
          <br />
          <br />
          <i>{t("components.footer.part2")}</i>
          <br />
          <br />

          <i>{t("components.footer.contact")}</i>
          <br />
          <b>{t("components.footer.contactEmail")}</b>
        </Typography>
        <br />
        <Typography variant="subtitle2">
          {t("components.footer.part3")}
        </Typography>
        <br />
        <Typography variant="subtitle2">
          {t("components.footer.copyright")}
        </Typography>
        <br />
        <br />
        <Hidden smUp>
          <div className={classes.contentSpacing} />
        </Hidden>
      </Container>
    </section>
  );
};

export default Footer;
