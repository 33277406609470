import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { getRequest, postRequest } from "../logic/requests";
import { URLs } from "../logic/constants";

export const fetchMyTeams = createAsyncThunk(
  "teams/fetchMyTeams",
  async (_, { rejectWithValue }) =>
    await getRequest(URLs.api.team.getMy, { withCredentials: true })
      .then((response) => {
        return response.data;
      })
      .catch(({ data }) => {
        return rejectWithValue(data);
      }),
);

export const fetchOneTeam = createAsyncThunk(
  "teams/fetchOneTeam",
  async ({ teamId }, { rejectWithValue }) =>
    await getRequest(URLs.api.team.get, { teamId }, { withCredentials: true })
      .then((response) => {
        return response.data;
      })
      .catch(({ data }) => {
        return rejectWithValue(data);
      }),
);

export const fetchCaptainTeams = createAsyncThunk(
  "teams/fetchCaptainTeams",
  async (_, { rejectWithValue }) =>
    await getRequest(URLs.api.user.getCaptainTeams, { withCredentials: true })
      .then((response) => {
        return response.data;
      })
      .catch(({ data }) => {
        return rejectWithValue(data);
      }),
);

export const fetchCreateTeam = createAsyncThunk(
  "teams/fetchCreateTeam",
  async ({ teamName, teamAvatar }, { rejectWithValue }) =>
    postRequest(
      URLs.api.team.create,
      { teamName, teamAvatar },
      { withCredentials: true },
    )
      .then((res) => {
        return res.data;
      })
      .catch(({ data }) => {
        return rejectWithValue(data);
      }),
);

export const leaveTeam = createAsyncThunk(
  "teams/leaveTeam",
  async (teamId, { rejectWithValue }) =>
    await postRequest(
      URLs.api.team.leave,
      {
        teamId,
      },
      { withCredentials: true },
    )
      .then((res) => {
        return teamId;
      })
      .catch(({ data }) => {
        return rejectWithValue(data);
      }),
);

const teamsAdapter = createEntityAdapter({
  selectId: (team) => team._id,
});

const teamsSlice = createSlice({
  name: "teams",
  initialState: teamsAdapter.getInitialState({
    loading: false,
    error: null,
  }),
  reducers: {
    setMyTeams: teamsAdapter.setAll,
    upsertOneMyTeam: teamsAdapter.upsertOne,
    addMyTeams: teamsAdapter.addMany,
    addOneMyTeam: teamsAdapter.addOne,
    removeOneMyTeam: teamsAdapter.removeOne,

    setTeamMembers: () => {
      console.log(`TODO setTeamMembers`);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyTeams.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMyTeams.fulfilled, (state, action) => {
        teamsAdapter.setAll(state, action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchMyTeams.rejected, (state, action) => {
        state.loading = false;
        state.error = `Couldn't get my teams!`;
        throw action.payload;
      })
      .addCase(fetchCreateTeam.fulfilled, (state, action) => {
        teamsAdapter.addOne(state, action.payload);
      })
      .addCase(leaveTeam.fulfilled, (state, action) => {
        teamsAdapter.removeOne(state, action.payload);
      })
      .addCase(fetchOneTeam.fulfilled, (state, action) => {
        teamsAdapter.upsertOne(state, action.payload);
      })
      .addCase(fetchCaptainTeams.fulfilled, (state, action) => {
        teamsAdapter.upsertMany(state, action.payload);
      })
      .addMatcher(
        (action) =>
          action.type.startsWith("teams/") && action.type.endsWith("/rejected"),
        (state, action) => {
          throw action.payload;
        },
      );
  },
});

export const {
  setMyTeams,
  addMyTeams,
  setTeamMembers,
  removeOneMyTeam,
  upsertOneMyTeam,
  addOneMyTeam,
} = teamsSlice.actions;

export const teamsSelectors = teamsAdapter.getSelectors((state) => state.teams);
export default teamsSlice.reducer;
