import DateFnsAdapter from "@date-io/date-fns";
import {
  Button,
  Container,
  Divider,
  Grid,
  LinearProgress,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GroupIcon from "@material-ui/icons/Group";
import PersonIcon from "@material-ui/icons/Person";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import MarkdownIt from "markdown-it";
import React from "react";
import { connect, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { StageViewer } from "..";
import ImageMediaCard from "../../components/independent/ImageMediaCard";
import PageHeader from "../../components/independent/PageHeader";
import { alerts, useAlert } from "../../context/AlertContext";
import { URLs } from "../../logic/constants";
import { getRequest, postRequest } from "../../logic/requests";
import {
  fetchCaptainTeams,
  fetchOneGroup,
  fetchOneTeam,
  fetchOneTournament,
  tournamentsSelectors,
} from "../../redux/actions";
import ModalParticipants from "./components/ModalParticipants";

const dateFns = new DateFnsAdapter();

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    padding: theme.spacing(2),
    height: "100%",
    margin: theme.spacing(2, 0),
  },
  divider: {
    margin: theme.spacing(0, 0, 2, 2),
    padding: theme.spacing(0.2),
  },
  details: {
    margin: theme.spacing(2, 0),
  },
  markdown: {
    overflowY: "auto",
    "& td, & th": {
      padding: theme.spacing(1),
    },
    "& th": {
      borderBottom: "1px solid " + theme.palette.action.disabled,
    },
    "& table": {
      border: "1px solid",
      borderColor: theme.palette.action.disabled,
      borderSpacing: 0,
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const statusses = ["Did not started yet", "Ongoing", "Ended", "Removed"];

const mdParser = new MarkdownIt({ linkify: true });

const ViewTournament = ({
  dispatchFetchOneTournament,
  redux_userProfile,
  dispatchFetchOneTeam,
  dispatchFetchCaptainTeams,
}) => {
  const classes = useStyles();
  const showAlert = useAlert();

  const [isParticipantsModalOpen, set_isParticipantsModalOpen] =
    React.useState(false);
  const [stageTab, setStageTab] = React.useState(0);
  const [captainTeams, setCaptainTeams] = React.useState({});
  const [chosenTeam, setChosenTeam] = React.useState("empty");
  const [inputValue, setInputValue] = React.useState("");
  const [organizer, set_organizer] = React.useState({
    name: "",
    avatar: "?",
  });

  const { id: tournamentId } = useParams();
  const tournament = useSelector((state) =>
    tournamentsSelectors.selectById(state, tournamentId),
  );

  React.useEffect(() => {
    dispatchFetchOneTournament(tournamentId).then(({ payload }) => {
      getRequest(URLs.api.group.get, { groupId: payload.group })
        .then(({ data }) => {
          if (data.category == 1) {
            set_organizer({
              name: data.groupName,
              avatar: data.groupAvatar,
            });
          } else {
            getRequest(URLs.api.user.get, { userId: payload.creator }).then(
              ({ data: d }) => {
                set_organizer({
                  name: d.username,
                  avatar:
                    "https://cdn.discordapp.com/avatars/" +
                    d.discordId +
                    "/" +
                    d.avatar,
                });
              },
            );
          }
        })
        .catch((err) => {
          console.error(err.data);

          getRequest(URLs.api.user.get, { userId: payload.creator }).then(
            ({ data: d }) => {
              set_organizer({
                name: d.username,
                avatar:
                  "https://cdn.discordapp.com/avatars/" +
                  d.discordId +
                  "/" +
                  d.avatar,
              });
            },
          );
        });
    });
    dispatchFetchCaptainTeams()
      .then(({ payload }) => {
        setCaptainTeams(payload);
      })
      .catch((err) => {
        showAlert(err.data.error, alerts.error.severity);
      });
  }, []);

  const handleOnCloseParticipants = () => {
    set_isParticipantsModalOpen(false);
  };

  const handleRegister = () => {
    if (!tournament.isTeamTournament) {
      postRequest(URLs.api.tournament.joinPlayertournament, {
        tournamentId: tournament._id,
      })
        .then((res) => {
          dispatchFetchOneTournament(tournamentId);

          showAlert(
            "You have successfully joined the tournament!",
            alerts.success.severity,
          );
        })
        .catch((err) => {
          showAlert(err.data.error, alerts.error.severity);
        });
    } else {
      postRequest(URLs.api.tournament.joinTeamTournament, {
        tournamentId: tournament._id,
        contenderId: chosenTeam._id,
      })
        .then((res) => {
          dispatchFetchOneTournament(tournamentId);

          showAlert(
            "You have successfully joined the tournament",
            alerts.success.severity,
          );
        })
        .catch((err) => {
          showAlert(err.data.error, alerts.error.severity);
        });
    }
  };

  //shouldve used getparticipants endpoint, not to be fixed
  const checkIfRegistered = React.useMemo(
    () => () => {
      if (true) return false;
      if (tournament)
        if (!tournament.isTeamTournament) {
          // if player tournament
          const participantId = tournament.participants.find((p) => {
            if (p == redux_userProfile._id) {
              return p;
            }
          });
          return participantId; // user either is registered or not
        } else {
          const participantId = tournament.participants.find((p) => {
            if (p == chosenTeam._id) {
              return p;
            }
          });
          return participantId;
        }
    },
    [tournament, chosenTeam],
  );

  const handleUnregister = () => {
    if (!tournament.isTeamTournament) {
      postRequest(URLs.api.tournament.leavePlayerTournament, {
        tournamentId: tournament._id,
      })
        .then((res) => {
          dispatchFetchOneTournament(tournamentId);
          showAlert(
            "Successfully left the tournament!",
            alerts.success.severity,
          );
        })
        .then((res) => {
          showAlert(
            "Successfully left the tournament!",
            alerts.success.severity,
          );
        })
        .catch((err) => showAlert(err.data.error, alerts.error.severity));
    } else {
      postRequest(URLs.api.tournament.leaveTeamTournament, {
        tournamentId: tournament._id,
        teamId: chosenTeam._id,
      })
        .then((res) => {
          dispatchFetchOneTournament(tournamentId);
          showAlert(
            "Successfully unregistered the team",
            alerts.success.severity,
          );
        })
        .then((res) => {
          showAlert(
            "Successfully unregistered the team",
            alerts.success.severity,
          );
        })
        .catch((err) => {
          showAlert(err.data.error, alerts.error.severity);
        });
    }
  };

  const handleChange = (event, newValue) => {
    setStageTab(newValue);
  };

  if (!tournament) return <LinearProgress />;

  return (
    <section className={classes.root}>
      <PageHeader title={tournament.name} subtitle={tournament.game} />
      <Container disableGutters>
        <Grid container spacing={2} justify="space-around" alignItems="stretch">
          <Grid item xs={12} md={12}>
            <Paper variant="outlined" className={classes.paper}>
              <Typography variant="h6" align="left">
                Stages
              </Typography>
              <Divider className={classes.divider} variant="inset" />
              <Tabs
                value={stageTab}
                onChange={handleChange}
                indicatorColor="primary"
                aria-label="simple tabs example"
              >
                {tournament?.stages.map((stage) => (
                  <Tab label={stage.name} />
                ))}
              </Tabs>
              <StageViewer stageId={tournament?.stages[stageTab]?._id} />
            </Paper>
          </Grid>
          {tournament.description && (
            <Grid item xs={12} md={8}>
              <Paper variant="outlined" className={classes.paper}>
                <Typography variant="h6" align="left">
                  Description
                </Typography>
                <Divider className={classes.divider} variant="inset" />
                <Typography variant="body1" align="left">
                  <div
                    className={classes.markdown}
                    dangerouslySetInnerHTML={{
                      __html: mdParser.render(tournament.description),
                    }}
                  />
                </Typography>
              </Paper>
            </Grid>
          )}
          <Grid item xs>
            <Paper variant="outlined" className={classes.paper}>
              <Typography variant="h5" align="left">
                Details
              </Typography>
              <Divider className={classes.divider} variant="inset" />
              <div className={classes.details}>
                {organizer.name ? (
                  <div
                    style={{
                      width: "60%",
                      minWidth: "96px",
                      maxWidth: "192px",
                      margin: "auto",
                    }}
                  >
                    <ImageMediaCard
                      image={organizer.avatar}
                      alt={"The Organizer"}
                      title={organizer.name}
                    />
                  </div>
                ) : (
                  <div>
                    <Skeleton variant="rect" height={200} />
                    <br />
                    <Skeleton variant="rect" height={56} />
                  </div>
                )}
                <br />
                <Typography variant="body2" align="left">
                  <b>Start Date: </b>
                  {`${dateFns.format(
                    new Date(tournament.startDate),
                    "PPPPp (z)",
                  )}`}
                  <br />
                  <b>Slots: </b>
                  {`${tournament.currentParticipantSize}/${tournament.participantSizeLimit} (${tournament.participants?.length} Signed up) `}
                  {tournament.isTeamTournament ? (
                    <GroupIcon
                      color="action"
                      fontSize="small"
                      style={{ verticalAlign: "middle" }}
                    />
                  ) : (
                    <PersonIcon
                      color="action"
                      fontSize="small"
                      style={{ verticalAlign: "middle" }}
                    />
                  )}
                  {/* 
                  <br />
                  <b>Status: </b>
                  {statusses[tournament.status - 1]}
                  */}
                  <br />
                  {tournament.isTeamTournament ? (
                    <>
                      <b>Minimum Team Size: </b> {tournament.minimumTeamSize}
                    </>
                  ) : (
                    "No team required."
                  )}
                </Typography>
              </div>
              {tournament.isTeamTournament && (
                <Autocomplete
                  fullWidth
                  style={{ marginBottom: "10px" }}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setChosenTeam(newValue);
                    } else {
                      setChosenTeam("empty");
                    }
                  }}
                  getOptionLabel={(option) => option.name}
                  inputValue={inputValue}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                  }}
                  options={captainTeams}
                  renderInput={(params) => (
                    <TextField {...params} label="Teams" variant="outlined" />
                  )}
                />
              )}
              <Button
                fullWidth
                style={{ marginBottom: "10px" }}
                size="small"
                color="primary"
                variant="outlined"
                onClick={() => set_isParticipantsModalOpen(true)}
              >
                View Participants
              </Button>
              {!checkIfRegistered() ? (
                <Button
                  fullWidth
                  style={{ marginBottom: "10px" }}
                  color="primary"
                  variant="contained"
                  onClick={handleRegister}
                  disabled={chosenTeam === "empty"}
                >
                  Register
                </Button>
              ) : (
                <Button
                  fullWidth
                  style={{ marginBottom: "10px" }}
                  color="secondary"
                  variant="contained"
                  onClick={handleUnregister}
                  disabled={chosenTeam === "empty"}
                >
                  Unregister
                </Button>
              )}
            </Paper>
          </Grid>
          {tournament.prize && (
            <Grid item xs={12} md={4}>
              <Paper variant="outlined" className={classes.paper}>
                <Typography variant="h5" align="left">
                  Prize
                </Typography>
                <Divider className={classes.divider} variant="inset" />
                <Typography variant="body1" align="left">
                  <div
                    className={classes.markdown}
                    dangerouslySetInnerHTML={{
                      __html: mdParser.render(tournament.prize),
                    }}
                  />
                </Typography>
              </Paper>
            </Grid>
          )}
          {tournament.rules && (
            <Grid item xs={12} md={8}>
              <Paper variant="outlined" className={classes.paper}>
                <Typography variant="h5" align="left">
                  Rules
                </Typography>
                <Divider className={classes.divider} variant="inset" />
                <Typography variant="body2" align="left">
                  <div
                    className={classes.markdown}
                    dangerouslySetInnerHTML={{
                      __html: mdParser.render(tournament.rules),
                    }}
                  />
                </Typography>
              </Paper>
            </Grid>
          )}
        </Grid>
        <br />
        <br />
      </Container>
      <ModalParticipants
        tournament={tournament}
        open={isParticipantsModalOpen}
        onClose={handleOnCloseParticipants}
      />
    </section>
  );
};

const mapDispatchToProps = {
  dispatchFetchOneTournament: fetchOneTournament,
  dispatchFetchOneGroup: fetchOneGroup,
  dispatchFetchOneTeam: fetchOneTeam,
  dispatchFetchCaptainTeams: fetchCaptainTeams,
};

const mapStateToProps = (state) => ({ redux_userProfile: state.profile });

export default connect(mapStateToProps, mapDispatchToProps)(ViewTournament);
