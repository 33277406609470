import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import NavMenuList from "./NavMenuList";
import { Drawer, IconButton, Button } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    //marginLeft: theme.spacing(1),
  },
}));

const NavMenu = ({ children, fontSize = "medium" }) => {
  const classes = useStyles();
  const [isDrawerOpen, set_isDrawerOpen] = React.useState(false);

  return (
    <div className={classes.root}>
      <IconButton onClick={() => set_isDrawerOpen(true)}>
        <MenuIcon fontSize={fontSize} />
      </IconButton>
      <Drawer
        anchor={"bottom"}
        open={isDrawerOpen}
        onClose={() => set_isDrawerOpen(false)}
      >
        <NavMenuList disableTooltips />

        <Button
          onClick={() => set_isDrawerOpen(false)}
          variant="text"
          color="initial"
          size="large"
        >
          <ExpandMoreIcon fontSize={fontSize} />
        </Button>
      </Drawer>
    </div>
  );
};

export default NavMenu;
