import { createMuiTheme } from "@material-ui/core/styles";
import { enUS } from "@material-ui/core/locale";

const theme = createMuiTheme(
  {
    palette: {
      type: "dark",
      primary: {
        light: "#b4ffff",
        main: "#7df9ff",
        dark: "#42c6cc",
        contrastText: "#2a3439",
      },
      secondary: {
        light: "#ffff55",
        main: "#ffe302",
        dark: "#c7b100",
        contrastText: "#2a3439",
      },
      info: {
        light: "#b4ffff",
        main: "#2A3439", //"#7df9ff",
        dark: "#42c6cc",
        contrastText: "#7df9ff",
      },
      text: {
        primary: "#F8F4FFFF",
        secondary: "#F8F4FFDD",
        disabled: "#F8F4FFAB",
        hint: "#F8F4FFAB",
        icon: "#F8F4FFAB",
      },
      divider: "#1A202399",
      background: {
        paper: "#2A3439",
        default: "#1F262A",
      },
      action: {
        active: "#F8F4FFAB",
        selected: "#55697330",
        hover: "#7df9ff20",
      },
    },
    typography: {
      fontFamily: [
        "Open Sans",
        "Quantico",
        "Roboto",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),
      h1: {
        fontWeight: 700,
        fontFamily: "Quantico",
      },
      h2: {
        fontWeight: 700,
        fontFamily: "Quantico",
      },
      h3: {
        fontWeight: 700,
        fontFamily: "Quantico",
      },
      h4: {
        fontWeight: 500,
        fontFamily: "Quantico",
      },
      h5: {
        fontWeight: 500,
        fontFamily: "Quantico",
      },
      h6: {
        fontWeight: 400,
        fontFamily: "Quantico",
      },
      body1: {
        fontWeight: 300,
        fontFamily: "Open Sans",
      },
      body2: {
        fontWeight: 300,
        fontFamily: "Open Sans",
      },
      subtitle1: {
        fontWeight: 300,
        opacity: 0.9,
        fontFamily: "Open Sans",
      },
      subtitle2: {
        fontWeight: 400,
        opacity: 0.8,
        fontFamily: "Open Sans",
      },
      button: {
        fontWeight: 700,
        fontFamily: "Quantico",
      },
      overline: {
        fontFamily: "Quantico",
        fontSize: "0.8rem",
      },
      caption: {
        fontFamily: "Open Sans",
      },
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          body: {
            backgroundImage: `url(/images/diagonal-lines-15.png)`,
            backgroundRepeat: "repeat",
          },
        },
      },
    },
    props: {
      MuiPaper: {
        variant: "outlined",
      },
      MuiButton: {
        color: "primary",
        variant: "contained",
      },
    },
  },
  enUS, //TODO localize: https://material-ui.com/guides/localization/
);

export default theme;
