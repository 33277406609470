const API_HOST_URL =
  process.env.REACT_APP_BACKEND_HOST || "http://localhost:3000";

const routes = {
  api: "/api",
  auth: "/auth",
  group: "/api/group",
  stage: "/api/stage",
  migrate: "/api/migrate",
  tournament: "/api/tournament",
  matches: "/api/matches",
  team: "/api/team",
  user: "/api/user",
};

const logics = {
  api: {
    tournament: {
      get: "/getSpecificTournament",
      create: "/createTournament",
      update: "/updateTournament",
      delete: "/deleteTournament",
      getParticipants: "/getParticipants",
      joinPlayerTournament: "/registerToTournament",
      leavePlayerTournament: "/unregisterFromTournament",
      joinTeamTournament: "/registerToTournament",
      leaveTeamTournament: "/unregisterFromTournament",
    },
    match: {
      get: "/getMatch",
      setMatchFinalScore: "/setMatchFinalScore",
    },
    migrate: {
      migrateTournament: "/migrateTournament",
    },
    tournamentList: {
      get: "/getTournaments",
      getUserCreated: "/getUserTournaments",
      getMy: "/getMyTournaments",
    },
    user: {
      get: "/getSpecificUser",
      getMy: "/getUser",
      search: "/userSearch",
      getCaptainTeams: "/getCaptainTeams",
    },
    stage: {
      create: "/createStage",
      getStagesOfTournament: "/getStagesOfTournament",
      delete: "/delete",
      updateMatches: "/updateMatches",
      get: "/get",
    },
    group: {
      get: "/getGroup",
      create: "/createGroup",
      invite: "/inviteToGroup",
      update: "/update",
      accept: "/acceptGroupInvitation",
      reject: "/rejectGroupInvitation",
      delete: "/deleteGroup",
      getMy: "/getMyGroups",
      getMyInvitations: "/getMyGroupInvitations",
      move: "/moveTournamentToGroup",
      changePerm: "/changeGroupPermission",
      leave: "/leaveGroup",
      getMembersOf: "/getMembersOfGroup",
      movableTournaments: "/movableTournaments",
      getTournamentsInGroup: "/getTournamentsInGroup",
      kickMember: "/kickFromGroup",
    },
    team: {
      get: "/getTeam",
      getMy: "/getMyTeams",
      update: "/updateTeam",
      delete: "/deleteTeam",
      leave: "/leaveTeam",
      kickMember: "/kickMember",
      create: "/createTeam",
      invite: "/inviteToTeam",
      getMyInvitations: "/getMyInvitations",
      acceptInvitation: "/acceptInvitation",
      rejectInvitation: "/rejectInvitation",
      getMembers: "/getTeamMembers",
    },
    notifications: {
      get: "/getNotifications",
      readAllNotifications: "/readAllNotifications",
      readSpecificNotifications: "/readSpecificNotifications",
    },
  },
  auth: {
    authenticate: "/",
    redirect: "/redirect",
    logout: "/logout",
  },
};

export const URLs = {
  apiMainHost: API_HOST_URL,
  api: {
    tournament: {
      get: API_HOST_URL + routes.tournament + logics.api.tournament.get,
      create: API_HOST_URL + routes.tournament + logics.api.tournament.create,
      update: API_HOST_URL + routes.tournament + logics.api.tournament.update,
      delete: API_HOST_URL + routes.tournament + logics.api.tournament.delete,
      joinTeamTournament:
        API_HOST_URL +
        routes.tournament +
        logics.api.tournament.joinTeamTournament,
      leaveTeamTournament:
        API_HOST_URL +
        routes.tournament +
        logics.api.tournament.leaveTeamTournament,
      joinPlayertournament:
        API_HOST_URL +
        routes.tournament +
        logics.api.tournament.joinPlayerTournament,
      leavePlayerTournament:
        API_HOST_URL +
        routes.tournament +
        logics.api.tournament.leavePlayerTournament,
      getParticipants:
        API_HOST_URL +
        routes.tournament +
        logics.api.tournament.getParticipants,
    },
    migrate: {
      migrate:
        API_HOST_URL + routes.migrate + logics.api.migrate.migrateTournament,
    },
    match: {
      get: API_HOST_URL + routes.matches + logics.api.match.get,
      setMatchFinalScore:
        API_HOST_URL + routes.matches + logics.api.match.setMatchFinalScore,
    },
    tournamentList: {
      get: API_HOST_URL + routes.tournament + logics.api.tournamentList.get,
      getUserCreated:
        API_HOST_URL +
        routes.tournament +
        logics.api.tournamentList.getUserCreated,
      getMy: API_HOST_URL + routes.tournament + logics.api.tournamentList.getMy,
    },
    user: {
      get: API_HOST_URL + routes.user + logics.api.user.get,
      getMy: API_HOST_URL + routes.user + logics.api.user.getMy,
      search: API_HOST_URL + routes.user + logics.api.user.search,
      getCaptainTeams:
        API_HOST_URL + routes.user + logics.api.user.getCaptainTeams,
    },
    stage: {
      create: API_HOST_URL + routes.stage + logics.api.stage.create,
      getStagesOfTournament:
        API_HOST_URL + routes.stage + logics.api.stage.getStagesOfTournament,
      updateMatches:
        API_HOST_URL + routes.stage + logics.api.stage.updateMatches,
      get: API_HOST_URL + routes.stage + logics.api.stage.get,
      delete: API_HOST_URL + routes.stage + logics.api.stage.delete,
    },
    team: {
      get: API_HOST_URL + routes.team + logics.api.team.get,
      getMy: API_HOST_URL + routes.user + logics.api.team.getMy,
      update: API_HOST_URL + routes.team + logics.api.team.update,
      delete: API_HOST_URL + routes.team + logics.api.team.delete,
      leave: API_HOST_URL + routes.team + logics.api.team.leave,
      kickMember: API_HOST_URL + routes.team + logics.api.team.kickMember,
      create: API_HOST_URL + routes.team + logics.api.team.create,
      invite: API_HOST_URL + routes.team + logics.api.team.invite,
      getMyInvitations:
        API_HOST_URL + routes.user + logics.api.team.getMyInvitations,
      acceptInvitation:
        API_HOST_URL + routes.team + logics.api.team.acceptInvitation,
      rejectInvitation:
        API_HOST_URL + routes.team + logics.api.team.rejectInvitation,
      joinTournament:
        API_HOST_URL + routes.team + logics.api.team.joinTournament,
      leaveTournament:
        API_HOST_URL + routes.team + logics.api.team.leaveTournament,
      getMembers: API_HOST_URL + routes.team + logics.api.team.getMembers,
    },
    group: {
      get: API_HOST_URL + routes.group + logics.api.group.get,
      create: API_HOST_URL + routes.group + logics.api.group.create,
      invite: API_HOST_URL + routes.group + logics.api.group.invite,
      accept: API_HOST_URL + routes.group + logics.api.group.accept,
      update: API_HOST_URL + routes.group + logics.api.group.update,
      reject: API_HOST_URL + routes.group + logics.api.group.reject,
      delete: API_HOST_URL + routes.group + logics.api.group.delete,
      getMy: API_HOST_URL + routes.user + logics.api.group.getMy,
      getMyInvitations:
        API_HOST_URL + routes.user + logics.api.group.getMyInvitations,
      move: API_HOST_URL + routes.group + logics.api.group.move,
      changePerm: API_HOST_URL + routes.group + logics.api.group.changePerm,
      leave: API_HOST_URL + routes.group + logics.api.group.leave,
      getMembersOf: API_HOST_URL + routes.group + logics.api.group.getMembersOf,
      movableTournaments:
        API_HOST_URL + routes.group + logics.api.group.movableTournaments,
      getTournamentsInGroup:
        API_HOST_URL + routes.group + logics.api.group.getTournamentsInGroup,
      kickMember: API_HOST_URL + routes.group + logics.api.group.kickMember,
    },
    notifications: {
      get: API_HOST_URL + routes.user + logics.api.notifications.get,
      readAllNotifications:
        API_HOST_URL +
        routes.user +
        logics.api.notifications.readAllNotifications,
      readSpecificNotifications:
        API_HOST_URL +
        routes.user +
        logics.api.notifications.readSpecificNotifications,
    },
  },
  auth: {
    authenticate: API_HOST_URL + routes.auth + logics.auth.authenticate,
    redirect: API_HOST_URL + routes.auth + logics.auth.redirect,
    logout: API_HOST_URL + routes.auth + logics.auth.logout,
  },
};
