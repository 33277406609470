import React from "react";
import {
  Container,
  Divider,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    marginTop: theme.spacing(1),
  },
  subtitle: {
    margin: theme.spacing(1, 0, 2, 0),
  },
  paper: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(0.75, 0),
    padding: theme.spacing(0.5, 0),
  },
}));

const PageHeader = ({ title = "", subtitle = "" }) => {
  const classes = useStyles();

  return (
    <Container disableGutters>
      <Paper variant="outlined" className={classes.paper}>
        <Typography variant="h4" component="h1" className={classes.title}>
          {title}
        </Typography>
        <Typography
          variant="subtitle2"
          component="h2"
          className={classes.subtitle}
        >
          {subtitle}
        </Typography>
        <Divider className={classes.divider} />
      </Paper>
    </Container>
  );
};

export default PageHeader;
