import React from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  progress: {
    marginTop: "25vh",
  },
}));

const Loading = () => {
  const classes = useStyles();

  return (
    <CircularProgress
      data-testid="loading"
      className={classes.progress}
      size={120}
      thickness={0.75}
    />
  );
};

export default Loading;
