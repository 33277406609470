import React from "react";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  MenuItem,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import { getRequest, postRequest } from "../../../logic/requests";
import { URLs } from "../../../logic/constants";
import { useAlert, alerts } from "../../../context/AlertContext";
import { connect } from "react-redux";
import { Skeleton } from "@material-ui/lab";
import { defaultTo, times } from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {},
  modalHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  avatar: {
    display: "inline-block",
    verticalAlign: "middle",
    margin: theme.spacing(1),
    marginLeft: 0,
  },
  modalTitle: {
    display: "inline-block",
  },
  divider: {
    margin: theme.spacing(0.25, 2),
  },
}));

//DEFAULT
const ModalParticipants = ({
  open = false,
  onClose,
  tournament = { participants: [] },
}) => {
  const classes = useStyles();
  const showAlert = useAlert();
  const [participants, set_participants] = React.useState([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const onCloseModal = (selectedTeamId = false) => {
    onClose(selectedTeamId);
  };

  React.useEffect(() => {
    if (tournament._id)
      getRequest(URLs.api.tournament.getParticipants, {
        tournamentId: tournament._id,
      })
        .then((res) => {
          if (res.status === 200 && Array.isArray(res.data)) {
            set_participants(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
          showAlert(err.data.error, alerts.error.severity);
        });
  }, [tournament.participants, tournament._id]);

  if (!(tournament && tournament.name)) return <></>;

  return (
    <Dialog fullWidth fullScreen={isMobile} open={open} onClose={onCloseModal}>
      {/* Start Header --- Tournament Participants  */}
      <DialogTitle className={classes.modalHeader}>
        <Typography variant="h5" color="inherit" className={classes.modalTitle}>
          <div className={classes.avatar}>
            <Avatar
              variant="rounded"
              alt={tournament.name}
              //src={tournament.game.avatar}
            >
              {tournament.game[0] && tournament.game[0]}
            </Avatar>
          </div>
          {tournament.name} Participants
        </Typography>
      </DialogTitle>
      {/* End Header --- Tournament Participants  */}
      <DialogContent>
        <List dense>
          {participants.length > 0 ? (
            participants.map((p, index) => {
              return (
                <>
                  <ListItem key={p._id}>
                    <ListItemAvatar>
                      <Avatar
                        variant="rounded"
                        alt={`Avatar n°${index + 1}`}
                        src={
                          tournament.isTeamTournament
                            ? p.avatar
                            : "https://cdn.discordapp.com/avatars/" +
                              p.discordId +
                              "/" +
                              p.avatar
                        }
                      />
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        tournament.isTeamTournament ? p.name : p.username
                      }
                      primaryTypographyProps={{
                        display: "inline",
                      }}
                    />
                    <Typography variant="button">{index + 1}</Typography>
                  </ListItem>
                  <Divider variant="middle" className={classes.divider} />
                </>
              );
            })
          ) : (
            <Typography variant="subtitle2" align="center">
              There are no participants.
            </Typography>
          )}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseModal} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ModalParticipants);
