import PageHeader from "../../../components/independent/PageHeader";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Paper } from "@material-ui/core";
import ClassicTable from "../../../components/independent/ClassicTable";
import ImageMediaCard from "../../../components/independent/ImageMediaCard";
import PopoverDialogButton from "../../../components/independent/PopoverDialogButton";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import { TextField } from "@material-ui/core";
//Breadcrumbs
import { emphasize, withStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import HomeIcon from "@material-ui/icons/Home";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  paper: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  paperDiv: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

//breadcrumbs
const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.grey[300],
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

const THEME_PAGE = () => {
  const classes = useStyles();

  //for checkboxes
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  //for tables
  const columns = [
    { id: "name", label: "Team Name", minWidth: 200 },
    { id: "code", label: "code", minWidth: 200 },
    { id: "population", label: "population", minWidth: 200 },
    { id: "size", label: "size", minWidth: 200 },
    { id: "density", label: "density", minWidth: 200 },
  ];

  function createData(name, code, population, size) {
    const density = population / size;
    return { name, code, population, size, density };
  }

  const rows = [
    createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
    createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
    createData("Eclair", 262, 16.0, 24, 6.0),
    createData("Cupcake", 305, 3.7, 67, 4.3),
    createData("Gingerbread", 356, 16.0, 49, 3.9),
  ];

  return (
    <section className={classes.root}>
      <PageHeader title="Page Header" />
      Sample Text
      <Paper variant="outlined" className={classes.paper}>
        <div className={classes.paperDiv}>
          <Button variant="contained">Default</Button>
          <Button variant="contained" color="primary">
            Primary
          </Button>
          <Button variant="contained" color="secondary">
            Secondary
          </Button>
          <Button variant="contained" disabled>
            Disabled
          </Button>
          <Button variant="contained" color="primary" href="#contained-buttons">
            Link
          </Button>
          <Button variant="contained" color="primary" disableElevation>
            Disable elevation
          </Button>
        </div>
        <div className={classes.paperDiv}>
          <Button variant="outlined">Default</Button>
          <Button variant="outlined" color="primary">
            Primary
          </Button>
          <Button variant="outlined" color="secondary">
            Secondary
          </Button>
          <Button variant="outlined" disabled>
            Disabled
          </Button>
          <Button variant="outlined" color="primary" href="#outlined-buttons">
            Link
          </Button>
        </div>
        <div className={classes.paperDiv}>
          <Button>Default</Button>
          <Button color="primary">Primary</Button>
          <Button color="secondary">Secondary</Button>
          <Button disabled>Disabled</Button>
          <Button href="#text-buttons" color="primary">
            Link
          </Button>
        </div>
        <div className={classes.paperDiv}>
          <Button variant="contained" size="small" color="primary">
            Small
          </Button>
          <Button variant="contained" size="medium" color="primary">
            Medium
          </Button>
          <Button variant="contained" size="large" color="primary">
            Large
          </Button>
        </div>
      </Paper>
      <Paper variant="outlined" className={classes.paper}>
        <ClassicTable rows={rows} columns={columns} />
      </Paper>
      <Paper variant="outlined" className={classes.paper}>
        <ImageMediaCard
          image={"/logo192.png"} //can also use variables which are assigned to images
          title="Image Media Card"
          alt="Image Media Card"
        ></ImageMediaCard>
      </Paper>
      <Paper variant="outlined" className={classes.paper}>
        <PopoverDialogButton
          onClick={() => {}}
          children="Pop-over Dialog Button"
          contentText="Content Text"
        ></PopoverDialogButton>
        <PopoverDialogButton
          onClick={() => {}}
          children="Pop-over Dialog Button"
          contentText="Content Text"
          colors="secondary"
        ></PopoverDialogButton>
      </Paper>
      <Paper variant="outlined" className={classes.paper}>
        <div className={classes.paperDiv}>
          <Checkbox
            checked={checked}
            onChange={handleChange}
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          <Checkbox
            defaultChecked
            color="primary"
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
          <Checkbox inputProps={{ "aria-label": "uncontrolled-checkbox" }} />
          <Checkbox
            disabled
            inputProps={{ "aria-label": "disabled checkbox" }}
          />
          <Checkbox
            disabled
            checked
            inputProps={{ "aria-label": "disabled checked checkbox" }}
          />
          <Checkbox
            defaultChecked
            indeterminate
            inputProps={{ "aria-label": "indeterminate checkbox" }}
          />
          <Checkbox
            defaultChecked
            color="default"
            inputProps={{ "aria-label": "checkbox with default color" }}
          />
          <Checkbox
            defaultChecked
            size="small"
            inputProps={{ "aria-label": "checkbox with small size" }}
          />
        </div>
      </Paper>
      <Paper variant="outlined" className={classes.paper}>
        <Typography variant="h3">
          Typography h3: Lorem ipsum dolor sit amet.
        </Typography>
        <Typography variant="h4">
          Typography h4: Lorem ipsum dolor sit amet.
        </Typography>
        <Typography variant="h5">
          Typography h5: Lorem ipsum dolor sit amet.
        </Typography>
        <Typography variant="h6">
          Typography h6: Lorem ipsum dolor sit amet.
        </Typography>
        <Typography variant="body1">
          Typography body1: Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
          amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.
        </Typography>
        <Typography variant="body2">
          Typography body2: Lorem ipsum dolor sit amet. Lorem ipsum dolor sit
          amet. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.
        </Typography>
        <Typography variant="subtitle1">
          Typography subtitle1: Lorem ipsum dolor sit amet.
        </Typography>
        <Typography variant="subtitle2">
          Typography subtitle2: Lorem ipsum dolor sit amet.
        </Typography>
        <Typography variant="overline">
          Typography overline: Lorem ipsum dolor sit amet.
        </Typography>
        <br />
        <Typography variant="button">
          Typography button: Lorem ipsum dolor sit amet.
        </Typography>
        <br />
        <Typography variant="caption">
          Typography caption: Lorem ipsum dolor sit amet.
        </Typography>
      </Paper>
      <Paper variant="outlined" className={classes.paper}>
        <form className={classes.paperDiv} noValidate autoComplete="off">
          <TextField id="standard-basic" label="Standard" />
          <TextField id="filled-basic" label="Filled" variant="filled" />
          <TextField id="outlined-basic" label="Outlined" variant="outlined" />
        </form>
      </Paper>
      <Paper variant="outlined" className={classes.paper}>
        <Breadcrumbs aria-label="breadcrumb">
          <StyledBreadcrumb
            component="a"
            href="#"
            label="Home"
            icon={<HomeIcon fontSize="small" />}
            onClick={handleClick}
          />
          <StyledBreadcrumb
            component="a"
            href="#"
            label="Catalog"
            onClick={handleClick}
          />
          <StyledBreadcrumb
            label="Accessories"
            deleteIcon={<ExpandMoreIcon />}
            onClick={handleClick}
            onDelete={handleClick}
          />
        </Breadcrumbs>
      </Paper>
    </section>
  );
};

export default THEME_PAGE;
