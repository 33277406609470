import {
  Avatar,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
} from "@material-ui/core";
import React from "react";
import { connect, useSelector } from "react-redux";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { isEmpty } from "lodash";
import {
  setMyGroups,
  groupInvitationsSelectors,
  acceptGroupInvitation,
  rejectGroupInvitation,
} from "../../../redux/actions";

const useStyles = makeStyles((theme) => ({
  actionsContainer: {
    "& > *": {
      margin: theme.spacing(0),
    },
  },
}));

const ViewInvitations = ({
  dispatchAcceptGroupInvitation,
  dispatchRejectGroupInvitation,
}) => {
  const classes = useStyles();

  const groupInvitations = useSelector(groupInvitationsSelectors.selectAll);

  return (
    <Paper variant="outlined">
      <List>
        {isEmpty(groupInvitations)
          ? "No invitations received."
          : groupInvitations.map((inv) => {
              return (
                <ListItem>
                  <ListItemAvatar>
                    <Avatar variant="rounded" src={inv.groupAvatar}>
                      {inv.groupName[0].toUpperCase()}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={inv.groupName}
                    // secondary={"has invited you to join their team!"}
                  />
                  <ListItemSecondaryAction className={classes.actionsContainer}>
                    <Button
                      variant="contained"
                      color="primary"
                      edge="end"
                      aria-label="accept"
                      onClick={() => dispatchAcceptGroupInvitation(inv.id)}
                    >
                      <CheckIcon />
                    </Button>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => dispatchRejectGroupInvitation(inv.id)}
                    >
                      <ClearIcon color="secondary" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
      </List>
    </Paper>
  );
};

const mapDispatchToProps = {
  dispatchSetMyGroups: setMyGroups,
  dispatchAcceptGroupInvitation: acceptGroupInvitation,
  dispatchRejectGroupInvitation: rejectGroupInvitation,
};

const mapStateToProps = (state) => ({
  //
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewInvitations);
