import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { getRequest, postRequest } from "../logic/requests";
import { URLs } from "../logic/constants";

export const fetchMyGroups = createAsyncThunk(
  "groups/fetchMyGroups",
  async (_, { rejectWithValue }) =>
    await getRequest(URLs.api.group.getMy, { withCredentials: true })
      .then((response) => {
        const groups = response.data;

        for (let i = 0; i < groups.length; i++) {
          const ids = [];
          const entities = {};

          for (let j = 0; j < groups[i].userList.length; j++) {
            ids.push(groups[i].userList[j].userId);
            entities[groups[i].userList[j].userId] = groups[i].userList[j];
          }

          groups[i].members = { ids, entities };
          //delete groups[i]["userList"];
        }
        return groups;
      })
      .catch(({ data }) => {
        return rejectWithValue(data);
      }),
);

export const createNewGroup = createAsyncThunk(
  "groups/createNewGroup",
  async (params, { rejectWithValue }) =>
    await postRequest(URLs.api.group.create, {
      ...params,
      withCredentials: true,
    })
      .then((res) => {
        return res.data;
      })
      .catch(({ data }) => {
        return rejectWithValue(data);
      }),
);

export const fetchOneGroup = createAsyncThunk(
  "groups/fetchOneGroup",
  async (groupId, { rejectWithValue }) =>
    await getRequest(URLs.api.group.get, { groupId }, { withCredentials: true })
      .then(({ data }) => {
        return data;
      })
      .catch(({ data }) => {
        return rejectWithValue(data);
      }),
);

const groupsAdapter = createEntityAdapter({
  selectId: (group) => group._id,
});

const groupsSlice = createSlice({
  name: "groups",
  initialState: groupsAdapter.getInitialState({
    loading: false,
    error: null,
  }),
  reducers: {
    setMyGroups: groupsAdapter.setAll,
    upsertOneMyGroup: groupsAdapter.upsertOne,
    addMyGroups: groupsAdapter.addMany,
    addOneMyGroup: groupsAdapter.addOne,
    removeOneMyGroup: groupsAdapter.removeOne,
    setGroupMembers: () => {
      console.log(`TODO setGroupMembers`);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMyGroups.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchMyGroups.fulfilled, (state, action) => {
        groupsAdapter.setAll(state, action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchMyGroups.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        throw new Error(state.error);
      })
      .addCase(createNewGroup.fulfilled, (state, action) => {
        groupsAdapter.addOne(state, action.payload);
      })
      .addCase(fetchOneGroup.fulfilled, (state, action) => {
        groupsAdapter.upsertOne(state, action.payload);
      })
      .addMatcher(
        (action) =>
          action.type.startsWith("groups/") &&
          action.type.endsWith("/rejected"),
        (state, action) => {
          throw action.payload;
        },
      );
  },
});

export const {
  setMyGroups,
  addMyGroups,
  setGroupMembers,
  removeOneMyGroup,
  upsertOneMyGroups,
  addOneMyGroup,
} = groupsSlice.actions;

export const groupsSelectors = {
  ...groupsAdapter.getSelectors((state) => state.groups),
  selectMemberPermission: (userList, memberId) => {
    if (Array.isArray(userList))
      for (let m of userList) {
        if (m.userId === memberId) return m.permission;
      }
    return null;
  },
};

export default groupsSlice.reducer;
