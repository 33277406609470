import React from "react";

import {
  makeStyles,
  ThemeProvider as DatePickerThemeProvier,
} from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import TooltipIcon from "../../components/independent/TooltipIcon";
import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Switch,
  Stepper,
  Step,
  StepLabel,
  Container,
  CircularProgress,
  Tooltip,
  createMuiTheme,
} from "@material-ui/core";
import { useAlert, alerts, alertSeverity } from "../../context/AlertContext";
import SwipeableViews from "react-swipeable-views";
import { useSidebarIndex } from "../../context/SidebarIndexContext";
import { indexes } from "../";
import PageHeader from "../../components/independent/PageHeader";
import { Controller, useForm } from "react-hook-form";
import MarkdownIt from "markdown-it";
import MdEditor from "react-markdown-editor-lite";
import "react-markdown-editor-lite/lib/index.css";
import ReactHookFormSelect from "../../components/independent/ReactHookFormSelect";
import { connect, useSelector } from "react-redux";
import {
  groupsSelectors,
  fetchMyGroups,
  fetchCreateTournament,
} from "../../redux/actions";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import AddBoxIcon from "@material-ui/icons/AddBox";
import CallMergeIcon from "@material-ui/icons/CallMerge";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ModalMigrateTournament from "./components/ModalMigrateTournament";
const useStyles = makeStyles((theme) => ({
  root: { userSelect: "none" },

  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  form: {
    input: {
      padding: 15,
    },
  },
  description: {
    margin: theme.spacing(1, 0, 0, 0),
    textAlign: "left",
  },
  divider: {
    margin: theme.spacing(0, 0, 2, 2),
    padding: theme.spacing(0.2),
  },
  swipeable: {
    cursor: "grab",
    "&:active": {
      cursor: "grabbing",
    },
  },
  swipeableContainer: {
    minHeight: "50vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  stepper: {
    padding: theme.spacing(1),
  },
  step: {
    "&:hover": { cursor: "pointer" },
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  loadingWrapper: {
    position: "relative",
  },
  buttonContainer: {
    position: "sticky",
    bottom: "0px",
  },
}));

//TODO
//FIXME

const datePickerTheme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      light: "#b4ffff",
      main: "#7df9ff",
      dark: "#42c6cc",
      contrastText: "#2a3439",
    },
    secondary: {
      light: "#ffff55",
      main: "#ffe302",
      dark: "#c7b100",
      contrastText: "#2a3439",
    },
    info: {
      light: "#b4ffff",
      main: "#2A3439", //"#7df9ff",
      dark: "#42c6cc",
      contrastText: "#7df9ff",
    },
    text: {
      primary: "#F8F4FFFF",
      secondary: "#F8F4FFDD",
      disabled: "#F8F4FFAB",
      hint: "#F8F4FFAB",
      icon: "#F8F4FFAB",
    },
    divider: "#1A202399",
    background: {
      paper: "#2A3439",
      default: "#1F262A",
    },
    action: {
      active: "#F8F4FFAB",
      selected: "#55697330",
      hover: "#7df9ff20",
    },
  },
  typography: {
    fontFamily: [
      "Open Sans",
      "Quantico",
      "Roboto",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    h1: {
      fontWeight: 700,
      fontFamily: "Quantico",
    },
    h2: {
      fontWeight: 700,
      fontFamily: "Quantico",
    },
    h3: {
      fontWeight: 700,
      fontSize: 36,
      fontFamily: "Quantico",
    },
    h4: {
      fontWeight: 500,
      fontFamily: "Quantico",
    },
    h5: {
      fontWeight: 500,
      fontFamily: "Quantico",
    },
    h6: {
      fontWeight: 400,
      fontFamily: "Quantico",
    },
    body1: {
      fontWeight: 300,
      fontFamily: "Open Sans",
    },
    body2: {
      fontWeight: 300,
      fontFamily: "Open Sans",
    },
    subtitle1: {
      fontWeight: 300,
      opacity: 0.9,
      fontFamily: "Open Sans",
    },
    subtitle2: {
      fontWeight: 400,
      opacity: 0.8,
      fontFamily: "Open Sans",
    },
    button: {
      fontWeight: 700,
      fontFamily: "Quantico",
    },
    overline: {
      fontFamily: "Quantico",
      fontSize: "0.8rem",
    },
    caption: {
      fontFamily: "Open Sans",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundImage: `url(/images/diagonal-lines-15.png)`,
          backgroundRepeat: "repeat",
        },
      },
    },
  },
});

const getSteps = () => ["Name the tournament", "Enter the details", "Explain"];

const mdParser = new MarkdownIt(/* Markdown-it options */);

//https://date-fns.org/v2.21.1/docs/format
export const CreateTournament = ({
  user_redux,
  dispatchFetchMyGroups,
  dispatchFetchCreateTournament,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const showAlert = useAlert();
  const { register, handleSubmit, control, errors } = useForm({});
  const [sidebarIndex, set_sidebarIndex] = useSidebarIndex();
  const groups = useSelector(groupsSelectors.selectAll);
  const [isTeamsEnabled, set_isTeamsEnabled] = React.useState(true);
  const [swipeableIndex, set_swipeableIndex] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [isMigrateModalOpen, set_isMigrateModalOpen] = React.useState(false);
  const [isLoading, set_isLoading] = React.useState(false);

  const steps = getSteps();

  const redirectTo = React.useCallback(
    (path = "") => history.push(path),
    [history],
  );

  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 1);
  currentDate.setHours(19, 0, 0, 0);

  set_sidebarIndex(indexes.tournaments);

  React.useEffect(() => {
    dispatchFetchMyGroups();
  }, []);

  React.useEffect(() => {
    if (errors.name) {
      set_swipeableIndex(0);
    } else if (
      errors.game ||
      errors.minimumTeamSize ||
      errors.participantSizeLimit ||
      errors.group ||
      errors.startDate
    ) {
      set_swipeableIndex(1);
    }
  }, [errors]);

  const onSubmit = async (data) => {
    set_isLoading(() => true);
    const tournament = {
      ...data,
      startDate: new Date(data.startDate).toISOString(),
    };
    //console.log(tournament);

    await dispatchFetchCreateTournament(tournament)
      .then(({ payload }) => {
        showAlert(
          `Tournament "${payload.name}" has been created.`,
          alertSeverity.success,
        );

        redirectTo(`/t/${payload._id}/edit`);
      })
      .catch((err) => {
        showAlert(err.data.error, alertSeverity.error);
      });

    set_isLoading(() => false);
  };

  const handleIsTeamSwitch = (_event, value) => {
    set_isTeamsEnabled(() => value);
  };

  const isStepOptional = (step) => {
    return step === 2;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const textFieldProps = {
    fullWidth: true,
    variant: "outlined",
  };

  return (
    <section className={classes.root}>
      <PageHeader title="Create A Tournament" />
      <Container disableGutters className={classes.swipeableContainer}>
        <Paper variant="outlined" className={classes.paper}>
          <Stepper activeStep={swipeableIndex} className={classes.stepper}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                );
              }
              stepProps.completed = false;
              return (
                <Step
                  key={label}
                  {...stepProps}
                  onClick={() => set_swipeableIndex(index)}
                >
                  <StepLabel className={classes.step} {...labelProps}>
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Paper>
        <SwipeableViews
          //className={classes.swipeable}
          resistance
          hysteresis={0.4}
          index={swipeableIndex}
          onChangeIndex={set_swipeableIndex}
        >
          <Container disableGutters className={classes.swipeableContainer}>
            {/* NAME */}
            <Paper variant="outlined" className={classes.paper}>
              <Typography
                styles={{ backgroundColor: "red" }}
                variant="h6"
                className={classes.description}
              >
                Name*
                <TooltipIcon title="The name that will be displayed to the users." />
              </Typography>
              <Divider className={classes.divider} variant="inset" />
              <TextField
                {...textFieldProps}
                required
                autoFocus
                name="name"
                label="Enter the tournament's name."
                inputRef={register({
                  required: "Please enter the tournaments name.",
                })}
              />
            </Paper>
            <Paper variant="outlined" className={classes.paper}>
              <Typography variant="h6" className={classes.description}>
                Or Migrate a Tournament
                <TooltipIcon title="Given the url, imports tournament configurations from Challonge." />
              </Typography>
              <Divider className={classes.divider} variant="inset" />
              <Button
                onClick={() => set_isMigrateModalOpen(true)}
                variant="contained"
                color="primary"
                size="large"
                endIcon={<CallMergeIcon />}
              >
                Migrate
              </Button>
            </Paper>
          </Container>

          <Container disableGutters className={classes.swipeableContainer}>
            <Paper variant="outlined" className={classes.paper}>
              <Grid container spacing={2}>
                {/* GAME */}
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" className={classes.description}>
                    Game*
                    <TooltipIcon title="The name of the game that will be played in the tournament." />
                  </Typography>
                  <Divider className={classes.divider} />
                  <TextField
                    {...textFieldProps}
                    required
                    name="game"
                    label="Enter the game."
                    inputRef={register({ required: "Please enter the game." })}
                  />
                </Grid>
                {/* PLAYERS PER TEAM */}
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" className={classes.description}>
                    Players Per Team*
                    <TooltipIcon title="How many players can be present in a team." />
                  </Typography>
                  <Divider className={classes.divider} />
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <TextField
                        {...textFieldProps}
                        required={isTeamsEnabled}
                        disabled={!isTeamsEnabled}
                        type="number"
                        name="minimumTeamSize"
                        label="Enter players per team"
                        defaultValue={5}
                        inputRef={register({
                          required: "Please enter the game.",
                        })}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Tooltip
                        title="Sets the type of tournament to team or single player based."
                        arrow
                        PopperProps={{
                          modifiers: {
                            flip: {
                              enabled: true,
                            },
                            preventOverflow: {
                              enabled: true,
                              boundariesElement: "scrollParent",
                            },
                          },
                        }}
                      >
                        <FormControlLabel
                          inputRef={register}
                          checked={isTeamsEnabled}
                          name="isTeamTournament"
                          onChange={handleIsTeamSwitch}
                          control={<Switch color="primary" />}
                          label={
                            <Typography variant="button">Teams </Typography>
                          }
                          labelPlacement="top"
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                {/* MAXIMUM PARTICIPANTS*/}
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" className={classes.description}>
                    {"Maximum " +
                      (isTeamsEnabled ? "Teams" : "Players") +
                      " Limit*"}
                    <TooltipIcon title="The maximum amount of teams or players that can participate in the tournament." />
                  </Typography>
                  <Divider className={classes.divider} />
                  <TextField
                    {...textFieldProps}
                    required
                    type="number"
                    name="participantSizeLimit"
                    label={
                      "Enter maximum " +
                      (isTeamsEnabled ? "teams" : "players") +
                      " amount"
                    }
                    defaultValue={16}
                    inputRef={register({
                      required: "Please enter maximum participants.",
                    })}
                  />
                </Grid>
                {/* GROUP */}
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" className={classes.description}>
                    Group*
                    <TooltipIcon title="The group of the tournament, where group members can manage the tournament if given enough permissions." />
                  </Typography>
                  <Divider className={classes.divider} />
                  <ReactHookFormSelect
                    fullWidth
                    required
                    variant="outlined"
                    className={classes.formControl}
                    control={control}
                    defaultValue={user_redux.defaultGroupId}
                    label="Choose the group of the tournament"
                    name="group"
                  >
                    {groups.map((g, i) => (
                      <MenuItem value={g._id}>{g.groupName}</MenuItem>
                    ))}
                  </ReactHookFormSelect>
                </Grid>
                {/* STARTING DATE */}
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.description}>
                    Start Date*
                    <TooltipIcon title="Sets the starting date of the tournament." />
                  </Typography>
                  <Divider className={classes.divider} />
                  <DatePickerThemeProvier theme={datePickerTheme}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Controller
                        name="startDate"
                        control={control}
                        defaultValue={currentDate}
                        as={
                          <DateTimePicker
                            style={{ width: "100%" }}
                            required
                            disablePast
                            inputVariant="outlined"
                            variant="inline"
                            format="iiii, dd MMMM yyyy - hh:mm a (O)"
                            id="date-picker-inline"
                            label="Pick the starting date"
                            PopoverProps={{
                              anchorOrigin: {
                                vertical: "top",
                                horizontal: "center",
                              },
                              transformOrigin: {
                                vertical: "bottom",
                                horizontal: "center",
                              },
                            }}
                            KeyboardButtonProps={{
                              "aria-label": "change time",
                            }}
                          />
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </DatePickerThemeProvier>
                </Grid>
              </Grid>
            </Paper>
          </Container>
          <Container disableGutters className={classes.swipeableContainer}>
            <Paper variant="outlined" className={classes.paper}>
              <Grid container spacing={2}>
                {/* DESCRIPTION */}
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.description}>
                    Description
                    <TooltipIcon title="Description that informs the players about the tournament." />
                  </Typography>
                  <Divider className={classes.divider} />
                  <Controller
                    name="description"
                    defaultValue={""}
                    control={control}
                    render={({ onChange, ref }) => (
                      <MdEditor
                        //ref={ref}
                        onChange={({ text = "", html }) => onChange(text)}
                        style={{ height: "30vh", minHeight: "128px" }}
                        placeholder={`Enter markdown text here...`}
                        renderHTML={(text) => mdParser.render(text)}
                      />
                    )}
                  />
                </Grid>
                {/* RULES */}
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.description}>
                    Rules
                    <TooltipIcon title="General rule sets for the competitors to obey, or get disqualified ;)" />
                  </Typography>
                  <Divider className={classes.divider} />
                  <TextField
                    {...textFieldProps}
                    multiline
                    name="rules"
                    label="Enter the tournament's rules."
                    inputRef={register}
                    rows={6}
                  />
                </Grid>
                {/* PRIZE */}
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.description}>
                    Prize
                    <TooltipIcon title="The prize that will be shown, higher prizes attract more players and viewers ;)" />
                  </Typography>
                  <Divider className={classes.divider} />
                  <TextField
                    {...textFieldProps}
                    multiline
                    name="prize"
                    label="Enter the tournament's prizes."
                    inputRef={register}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </SwipeableViews>
        <Container
          maxWidth="xs"
          disableGutters
          className={classes.buttonContainer}
        >
          <Paper elevation={3} className={classes.paper}>
            {swipeableIndex !== steps.length - 1 ? (
              <div className={classes.loadingWrapper}>
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                  endIcon={<NavigateNextIcon />}
                  onClick={() =>
                    swipeableIndex !== steps.length - 1 &&
                    set_swipeableIndex((s) => s + 1)
                  }
                >
                  Next Step
                </Button>
              </div>
            ) : (
              <div className={classes.loadingWrapper}>
                <Button
                  fullWidth
                  size="large"
                  variant="contained"
                  color="primary"
                  endIcon={<AddBoxIcon />}
                  disabled={isLoading}
                  onClick={handleSubmit(onSubmit)}
                >
                  Create
                </Button>

                {isLoading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            )}
          </Paper>
        </Container>
      </Container>
      {/* MODALS */}

      <ModalMigrateTournament
        open={isMigrateModalOpen}
        onClose={() => set_isMigrateModalOpen(false)}
        showAlert={showAlert}
      />
    </section>
  );
};

const mapDispatchToProps = {
  dispatchFetchMyGroups: fetchMyGroups,
  dispatchFetchCreateTournament: fetchCreateTournament,
};

const mapStateToProps = (state) => ({
  user_redux: state.profile,
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTournament);
