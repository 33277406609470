import {
  Button,
  Container,
  ButtonBase,
  LinearProgress,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import PeopleIcon from "@material-ui/icons/People";
import { defaultTo } from "lodash";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { indexes } from "..";
import PageHeader from "../../components/independent/PageHeader";
import { useAlert } from "../../context/AlertContext";
import { useSidebarIndex } from "../../context/SidebarIndexContext";
import { URLs } from "../../logic/constants";
import { getRequest, postRequest } from "../../logic/requests";
import { setMyTournaments } from "../../redux/actions";
import ModalAddStage from "./components/ModalAddStage";
import ModalEditTournamentInfo from "./components/ModalEditTournamentInfo";
import ModalParticipants from "./components/ModalParticipants";

//TODO Update this page to our standards
const useStyles = makeStyles((theme) => ({
  root: {},
  complexButtonContainer: {},
  stageItem: {
    backgroundColor: theme.palette.background.paper + "88",
  },
}));

const useComplexButtonStyles = makeStyles((theme) => ({
  image: {
    position: "relative",
    height: 200,
    [theme.breakpoints.down("xs")]: {
      width: "100% !important", // Overrides inline-style
      height: 100,
    },
    "&:hover, &$focusVisible": {
      zIndex: 1,
      "& $imageBackdrop": {
        opacity: 0.15,
      },
      "& $imageMarked": {
        opacity: 0,
      },
      // '& $imageTitle': {
      //   border: '4px solid currentColor',
      // },
    },
  },
  imageButton: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSubtitle: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 36,
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    color: theme.palette.common.white,
  },
  imageSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center 40%",
    filter: "blur(1px)",
  },
  imageBackdrop: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.6,
    transition: theme.transitions.create("opacity"),
  },
  imageTitle: {
    position: "relative",
    padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${
      theme.spacing(1) + 6
    }px`,
    fontSize: 24,
  },
  imageMarked: {
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  },
  form: {
    input: {
      padding: 15,
    },
  },
  container: {
    padding: 15,
  },
  subtitle: {
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 8,
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    color: theme.palette.action.active,
    textTransform: "capitalize",
  },
}));

const columns = [
  { id: "key", label: "Key", maxWidth: 150 },
  { id: "value", label: "Value", align: "center", minWidth: 300 },
];

const createData = (key, value) => {
  return { key, value };
};

const ComplexButton = ({ title, subtitle, width, imageUrl, ...props }) => {
  const classes = useComplexButtonStyles();
  return (
    <Button
      style={{ width, height: "100px" }}
      size="large"
      variant="outlined"
      {...props}
    >
      {title}
      <Typography variant="subtitle2" className={classes.subtitle}>
        {subtitle}
      </Typography>
    </Button>
  );
};

// const ComplexButton = ({ title, subtitle, width, imageUrl, ...props }) => {
//   const classes = useComplexButtonStyles();
//   return (
//     <ButtonBase
//       focusRipple
//       key={title}
//       className={classes.image}
//       focusVisibleClassName={classes.focusVisible}
//       style={{
//         width: width,
//       }}
//       {...props}
//     >
//       <span
//         className={classes.imageSrc}
//         style={{
//           backgroundImage: `url(${imageUrl})`,
//         }}
//       />
//       <Paper className={classes.imageBackdrop} />
//       <span className={classes.imageButton}>
//         <Typography
//           variant="button"
//           color="inherit"
//           className={classes.imageTitle}
//         >
//           {title}
//         </Typography>
//       </span>
//       <span className={classes.imageButton}>
//         <Typography
//           variant="button"
//           color="inherit"
//           className={classes.imageSubtitle}
//         >
//           {subtitle}
//         </Typography>
//       </span>
//     </ButtonBase>
//   );
// };

export const EditTournamentContent = ({
  //redux_tournaments = [],
  redux_setTournaments,
}) => {
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  const showAlert = useAlert();
  const [sidebarIndex, set_sidebarIndex] = useSidebarIndex();
  set_sidebarIndex(indexes.tournaments);

  //States
  // const [name, set_name] = useState("");
  // const [prize, set_prize] = useState("");
  // const [game, set_game] = useState("");
  // const [rules, set_rules] = useState("");
  // const [description, set_description] = useState("");
  // const [startDate, set_startDate] = useState(null);
  // const [endDate, set_endDate] = useState(null);
  const [isAddStageModalOpen, set_isAddStageModalOpen] = useState(false);
  const [stages, set_stages] = useState([]);
  const [isStageLoading, set_isStageLoading] = useState(true);
  //const [isEditStageModalOpen, set_isEditStageModalOpen] = useState(false);
  const [isParticipantsModalOpen, set_isParticipantsModalOpen] =
    useState(false);

  const [isInfoModalOpen, set_isInfoModalOpen] = React.useState(false);
  const [tournament, set_tournament] = React.useState({});

  const tournamentId = defaultTo(params.id, null);

  const handleOnInfoClick = () => {
    set_isInfoModalOpen(true);
  };

  const redirectTo = React.useCallback(
    (path = "") => history.push(path),
    [history],
  );

  // const handleSaveButton = () =>
  //   postRequest(URLs.api.tournament.update, {
  //     name,
  //     prize,
  //     game,
  //     rules,
  //     description,
  //     startDate,
  //     endDate,
  //     tournamentId,
  //   })
  //     .then((res) => console.log(res))
  //     .catch((err) => console.log(err));

  React.useEffect(() => {
    getRequest(URLs.api.tournament.get, { tournamentId: tournamentId }).then(
      (res) => {
        // set_name(res.data.name);
        // set_prize(res.data.prize);
        // set_game(res.data.game);
        // set_rules(res.data.rules);
        // set_description(res.data.description);
        // set_startDate(new Date(res.data.startDate));
        // set_endDate(new Date(res.data.endDate));
        set_tournament(res.data);
      },
    );

    set_isStageLoading(true);
    getRequest(URLs.api.stage.getStagesOfTournament, { tournamentId }).then(
      (res) => {
        set_stages(res.data);
        set_isStageLoading(false);
      },
    );
  }, [tournamentId]);

  React.useEffect(() => {
    getRequest(URLs.api.tournamentList.getMy).then((res) => {
      redux_setTournaments(res.data);
    });
  }, []);

  return (
    <Container disableGutters className={classes.root}>
      <PageHeader title="Edit Tournament" subtitle={tournament.name} />
      <br />
      <Grid container spacing={3}>
        <Grid item xs={12} md={5}>
          <Paper>
            <ComplexButton
              //imageUrl="https://images.hdqwalls.com/download/dark-abstract-black-low-poly-n1-2560x1440.jpg"
              title="Update Details"
              width="100%"
              onClick={() => redirectTo(`/t/${tournamentId}/edit/details`)}
              endIcon={<MoreHorizIcon />}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper>
            <ComplexButton
              //imageUrl="https://images.fabric.com/images/605/605/BJ-534.jpg"
              title="Add Stage"
              width="100%"
              onClick={() => set_isAddStageModalOpen(true)}
              endIcon={<AccountTreeIcon />}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper>
            <ComplexButton
              //imageUrl="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimage.freepik.com%2Ffree-vector%2Fmodern-black-metal-low-poly-backdrop_79145-440.jpg&f=1&nofb=1"
              title="Participants"
              width="100%"
              onClick={() => set_isParticipantsModalOpen(true)}
              endIcon={<PeopleIcon />}
            />
          </Paper>
        </Grid>
      </Grid>
      <PageHeader title="Stages"></PageHeader>
      <Grid container spacing={3}>
        {isStageLoading ? (
          <LinearProgress style={{ width: "97%", margin: "auto" }} />
        ) : stages.length === 0 ? (
          <Grid item xs={12}>
            <Paper variant={"outlined"}>
              <Typography variant="subtitle2" style={{ margin: "15px" }}>
                There are no stages.
              </Typography>
            </Paper>
          </Grid>
        ) : (
          stages.map((stage) => (
            <Grid item xs={12} md={3}>
              <Paper className={classes.stageItem}>
                <ComplexButton
                  //imageUrl="https://images.hdqwalls.com/download/dark-abstract-black-low-poly-n1-2560x1440.jpg"
                  title={stage.name}
                  subtitle={stage.type}
                  width="100%"
                  endIcon={<AccountTreeIcon />}
                  onClick={(e) =>
                    redirectTo("/t/" + params?.id + "/edit/stage/" + stage?._id)
                  }
                />
              </Paper>
            </Grid>
          ))
        )}
      </Grid>
      <ModalEditTournamentInfo
        tournament={tournament}
        open={isInfoModalOpen}
        onClose={() => set_isInfoModalOpen(false)}
        showAlert={showAlert}
      />
      <ModalAddStage
        open={isAddStageModalOpen}
        onClose={() => set_isAddStageModalOpen(false)}
        showAlert={showAlert}
        tournamentId={params.id}
      />
      <ModalParticipants
        tournament={tournament}
        open={isParticipantsModalOpen}
        onClose={() => set_isParticipantsModalOpen(false)}
        showAlert={showAlert}
      />
      {/* <ModalEditStage open={isEditStageModalOpen} onClose={() => set_isEditStageModalOpen(false)} showAlert={showAlert} tournamentId={params.id}/> */}
    </Container>
  );
};
const mapDispatchToProps = {
  redux_setTournaments: setMyTournaments,
};

const mapStateToProps = (state) => ({
  redux_tournaments: state.tournaments.tournaments,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditTournamentContent);
