// Lobium-Organize - Initialized at Tuesday, July 13, 2021 at 13:18. (Lobium Snippets Prefix: ll)
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PAGE_BASE from "../components/PAGE_BASE";
import PAPER from "../components/PAPER";
import UserSearch from "../../../components/dependent/UserSearch";
import JSON_RENDERER from "../components/JSON_RENDERER";
import { Divider, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {},
}));

const SEARCH = ({ children }) => {
  const classes = useStyles();
  const [onResultsArriveOutput, set_onResultsArriveOutput] =
    React.useState("Initial.");
  const [onSelectedOutput, set_onSelectedOutput] = React.useState("Initial.");

  const onResultsArriveHandler = (results) => {
    set_onResultsArriveOutput(results);
  };

  const onSelectedHandler = (selection) => {
    set_onSelectedOutput(selection);
  };

  const onLoadingHandler = (isLoading) => {
    if (isLoading) {
      set_onResultsArriveOutput("Loading...");
      set_onSelectedOutput("Waiting...");
    }
  };

  return (
    <PAGE_BASE className={classes.root}>
      <PAPER style={{ marginBottom: 100 }}>
        <UserSearch
          onResultsArrive={onResultsArriveHandler}
          onSelected={onSelectedHandler}
          onLoading={onLoadingHandler}
        />
      </PAPER>
      <PAPER>
        <Typography variant="h6" gutterBottom>
          onResultsArrive
        </Typography>
        <Divider style={{ padding: 1 }} />
        <JSON_RENDERER json={onResultsArriveOutput} />
      </PAPER>
      <PAPER>
        <Typography variant="h6" gutterBottom>
          onSelected
        </Typography>
        <Divider style={{ padding: 1 }} />
        <JSON_RENDERER json={onSelectedOutput} />
      </PAPER>
    </PAGE_BASE>
  );
};

export default SEARCH;
