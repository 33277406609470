import React from "react";

import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const AlertContext = React.createContext();

export const useAlert = () => React.useContext(AlertContext);

let alertShowCount = 0;

const alertSettings = {
  messages: {
    default: "Alert!",
    success: "Success!",
    error: "Failed!",
    warning: "Warning!",
    info: "Info!",
  },
  severity: {
    success: "success",
    warning: "warning",
    error: "error",
    info: "info",
  },
};

export const alerts = {
  default: {
    message: alertSettings.messages.default,
    severity: alertSettings.severity.warning,
  },
  success: {
    message: alertSettings.messages.success,
    severity: alertSettings.severity.success,
  },
  error: {
    message: alertSettings.messages.error,
    severity: alertSettings.severity.error,
  },
  warning: {
    message: alertSettings.messages.warning,
    severity: alertSettings.severity.warning,
  },
  info: {
    message: alertSettings.messages.info,
    severity: alertSettings.severity.info,
  },
};

export const alertSeverity = {
  default: alertSettings.severity.warning,
  success: alertSettings.severity.success,
  error: alertSettings.severity.error,
  warning: alertSettings.severity.warning,
  info: alertSettings.severity.info,
};

export const showAlertDefault = ({ message, severity } = alerts.default) => {
  console.log(`Alert (${severity}): ${message}`);
};

export const AlertProvider = ({
  children,
  hideDuration = 3000,
  alertCloseHook = () => {},
}) => {
  const [shownAlert, set_shownAlert] = React.useState(alerts.default);
  const [isAlertOpen, set_isAlertOpen] = React.useState(false);

  const handleAlertClose = async (event, reason) => {
    if (reason === "clickaway") return;

    await alertCloseHook();

    set_isAlertOpen(false);
  };

  const showAlert = (
    message = alerts.default.message,
    severity = alerts.default.severity,
  ) => {
    set_shownAlert({ message, severity });
    set_isAlertOpen(true);
    alertShowCount++;
    //console.log("Alert Show Count: ", alertShowCount);
  };

  return (
    <AlertContext.Provider value={showAlert}>
      {children}
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={hideDuration}
        onClose={handleAlertClose}
      >
        <Alert
          variant="filled"
          onClose={handleAlertClose}
          severity={shownAlert.severity}
        >
          {shownAlert.message}
        </Alert>
      </Snackbar>
    </AlertContext.Provider>
  );
};
